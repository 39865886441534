import { ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import React, { useState } from 'react';
import { Entity, EntityImpression } from 'types';

type ComponentProps = {
  entity: Entity;
  selected: boolean;
  setSelectedEntities: React.Dispatch<React.SetStateAction<string[]>>;
};

const EntityListItem: React.FC<ComponentProps> = ({ entity, selected, setSelectedEntities }) => {
  const [expanded, setExpanded] = useState(false);

  const handleEntitySelect = (checked: boolean, uuid: string): void => {
    if (checked) {
      setSelectedEntities((prev) => [...prev, uuid]);
    } else {
      setSelectedEntities((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, uuid: string): void => {
    e.stopPropagation();

    handleEntitySelect(e.target.checked, uuid);
  };

  return (
    <>
      <tr className={` border-t border-gray-200 hover:bg-gray-100 table-row`}>
        <td className="table-wrapper__new-td flex w-full">
          <div className="table-wrapper__new-cell-content w-full pt-2.5 px-2 min-h-8 flex justify-end">
            <input
              id={`checkbox-user-${entity.uuid}`}
              data-testid={`checkbox-${entity.name}`}
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e, entity.uuid);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="pt-0.5 flex items-center justify-center">
              <button
                className="flex justify-end focus:outline-none"
                type="button"
                data-testid={`expand-button-${entity.name}`}
                onClick={(e): void => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              >
                <span
                  className={`focus:outline-none transition-transform duration-300 ${
                    expanded && 'transform rotate-90'
                  }`}
                >
                  {ARROW_CLOSE_ENVELOPE()}
                </span>
              </button>
            </div>
            <div className="flex overflow-hidden">
              <div className="font-bold">
                {entity.name}
                {` (${entity.entity_impressions?.length})`}
              </div>
            </div>
          </div>
        </td>
        {/* <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content text-body">
            <div className="overflow-hidden">
              <div className="p-1">
                {
                  // @ts-ignore
                  entity?.entity_impressions?.toSorted((imp, imp2) =>
                    (imp.score < 1 ? imp.score * 10 : imp.score) >
                    (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                      ? -1
                      : 1
                  )[0].description
                }
              </div>
            </div>
          </div>
        </td> */}

        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content py-2.5 pr-4 text-body">
            <div className="overflow-hidden">
              <div className="">
                <b>
                  {
                    // @ts-ignore
                    entity.entity_impressions?.toSorted(
                      (imp: EntityImpression, imp2: EntityImpression) =>
                        (imp.score < 1 ? imp.score * 10 : imp.score) >
                        (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                          ? -1
                          : 1
                    )[0].score
                  }
                </b>
                {': '}
                {
                  // @ts-ignore
                  entity.entity_impressions?.toSorted(
                    (imp: EntityImpression, imp2: EntityImpression) =>
                      (imp.score < 1 ? imp.score * 10 : imp.score) >
                      (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                        ? -1
                        : 1
                  )[0].justification
                }
              </div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content py-2.5 pr-4 text-body">
            {[...new Set(entity.entity_impressions?.map((imp) => imp.other.market))].join(', ')}
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content py-2.5 pr-4 text-body">
            {[
              ...new Set(
                // @ts-ignore
                entity.entity_impressions?.reduce((acc, imp) => [...acc, ...imp.divisions], [])
              ),
            ].join(', ')}
          </div>
        </td>
      </tr>
      {expanded &&
        entity.entity_impressions
          // @ts-ignore
          ?.toSorted((imp: EntityImpression, imp2: EntityImpression) =>
            (imp.score < 1 ? imp.score * 10 : imp.score) >
            (imp2.score < 1 ? imp2.score * 10 : imp2.score)
              ? -1
              : 1
          )

          .map((impression: EntityImpression) => (
            <tr
              key={impression.uuid}
              className="border-t border-gray-200 bg-litlingo-gray-0.5 table-row h-8"
            >
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td">
                <div className="table-wrapper__new-cell-content text-body">
                  <div className="p-1">
                    <div className="">{impression.name}</div>
                  </div>
                </div>
              </td>

              <td className="table-wrapper__new-td">
                <div className="table-wrapper__new-cell-content text-body">
                  <div className="p-1">
                    <div className="">
                      {impression.score}
                      {': '}
                      {impression.justification}
                    </div>
                  </div>
                </div>
              </td>
              <td className="table-wrapper__new-td">
                <div className="table-wrapper__new-cell-content text-body">
                  <div className="p-1">
                    <div className="">{impression.other.market}</div>
                  </div>
                </div>
              </td>
              <td className="table-wrapper__new-td">
                <div className="table-wrapper__new-cell-content text-body">
                  <div className="p-1">
                    <div className="">{[...new Set(impression.divisions)].join(', ')}</div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
    </>
  );
};

export default EntityListItem;
