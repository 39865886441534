// eslint-disable-next-line import/prefer-default-export
export const keyActions = {
  send_anyways: 'Sent out of policy',
  timeout: 'Timeout',
  show_modal: 'Show modal',
  back_to_edit: 'Back to edit',
  sent_within_policy: 'Sent within policy',
  state_change: 'State change',
  author_notify: 'Author notified',
  o365_teams_tombstone: 'Teams Message Blocked',
};

export const advanceSearchOptions = [
  { title: 'Body', id: 'body' },
  { title: 'Subject', id: 'subject' },
  { title: 'Attachment', id: 'attachment_text' },
  { title: 'UUID', id: 'uuid' },
];
