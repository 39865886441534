import { createRelationship } from 'actions';
import { fetchRelationshipTypes } from 'actions/identifier';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllRelationships, getConfig, getConfigItems, getSelectedNode } from 'selectors/config';
import { getFetchRelationshipTypesLoading, getRelationshipTypes } from 'selectors/identifier';
import { getConfigRule } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import { RELATIONSHIP_BOX_HEIGHT, RELATIONSHIP_SVG_ARROWS_WIDTH } from 'utils/dimensions';
import { v4 as uuidv4 } from 'uuid';
import RenderEditRelationship from './RenderEditRelationship';
import RenderIdentifiers from './RenderIdentifiers';
import RenderRelationshipLines from './RenderRelationshipLines';

type ComponentProps = {
  setAdvancedModal: React.Dispatch<React.SetStateAction<boolean>>;
  advancedModal?: boolean;
};

type RelationshipArrayItem = {
  id: string;
  type: string | null;
  a: {
    name: string | null;
    id: string | null;
  };
  b: {
    name: string | null;
    id: string | null;
  };
};

const RelationshipManager: React.FC<ComponentProps> = (props) => {
  const { setAdvancedModal, advancedModal = false } = props;
  const dispatch = useDispatch();
  const arrayTree = useSelector(getConfigRule);
  const selectedNode = useSelector(getSelectedNode);

  const [configId, setConfigId] = useState<string>('');
  const currentNode = useSelector((state) => getConfig(state, configId));
  const config = useSelector(getConfigItems);
  const relationshipTypes = useSelector(getRelationshipTypes);
  const relationships = useSelector((state) => getAllRelationships(state, configId) || {});
  const relationshipTypesLoading = useSelector(getFetchRelationshipTypesLoading);

  useEffect(() => {
    if (arrayTree && arrayTree[selectedNode as number]) {
      setConfigId(arrayTree[selectedNode as number].id);
    }
  }, [selectedNode, arrayTree]);

  useEffect(() => {
    dispatch(fetchRelationshipTypes());
  }, [dispatch]);

  const annotators =
    currentNode && 'groups' in currentNode
      ? currentNode.groups.map((nodeId) => config[nodeId])
      : [];

  const relationshipsArray: (RelationshipArrayItem | null)[] =
    currentNode && 'relationship' in currentNode
      ? currentNode.relationship.map((key) => {
          const cur = relationships[key];
          if (cur) {
            const annotatorAId = cur.annotation_a ? cur.annotation_a.id : null;
            const annotatorBId = cur.annotation_b ? cur.annotation_b.id : null;

            return {
              id: key,
              type: cur.type || null,
              a: {
                name: annotatorAId && config[annotatorAId] ? config[annotatorAId].name : null,
                id: annotatorAId,
              },
              b: {
                name: annotatorBId && config[annotatorBId] ? config[annotatorBId].name : null,
                id: annotatorBId,
              },
            };
          }
          return null;
        })
      : [];

  const handleAddNew = (): void => {
    const newRelationshipId = uuidv4();
    // setActiveRelationshipId(newRelationshipId);
    // create a relationship with default values
    // relying on the first option being selected by default
    const createRelationshipPayload = {
      configId,
      id: newRelationshipId,
      type: relationshipTypes[0].id,
      annotation_a: {
        id: annotators[0].id,
      },
      annotation_b: {
        id: annotators[0].id,
      },
    };

    dispatch(createRelationship(createRelationshipPayload));
  };

  return (
    <>
      <div className="mt-4 max-h-relationship-modal overflow-y-auto overflow-x-hidden">
        <div className="pr-2 flex flex-row w-full">
          <div className="w-2/5">
            <RenderIdentifiers identifiers={annotators} />
          </div>
          <svg
            className="text-gray-500"
            width={RELATIONSHIP_SVG_ARROWS_WIDTH}
            height={RELATIONSHIP_BOX_HEIGHT * annotators.length}
          >
            {relationshipsArray.map((relationship) => (
              <RenderRelationshipLines
                key={relationship?.id}
                annotators={annotators}
                relationship={relationship}
              />
            ))}
          </svg>
          <div className="w-2/5">
            <RenderIdentifiers identifiers={annotators} />
          </div>
        </div>
        <div className="mt-10">
          {relationshipsArray.map((relationship) => (
            <RenderEditRelationship
              key={relationship?.id}
              relationship={relationship}
              annotators={annotators}
              relationshipTypes={relationshipTypes}
              configId={configId}
            />
          ))}
        </div>
      </div>
      <div className="text-left mt-2 text-blue-700">
        <button
          type="button"
          data-testid="add-another-button"
          onClick={handleAddNew}
          className={`${relationshipTypesLoading ? 'text-litlingo-gray-2 cursor-default' : ''}`}
          disabled={relationshipTypesLoading}
        >
          Add another
        </button>
      </div>
      {!advancedModal && (
        <div className="text-left mt-2 text-blue-700">
          <button
            type="button"
            data-testid="advanced-view-button"
            onClick={(): void => setAdvancedModal(true)}
          >
            Advanced view
          </button>
        </div>
      )}
    </>
  );
};

export default RelationshipManager;
