import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';

type ComponentProps = {
  primaryLabel: string;
  primaryAction: () => void;
  primaryLabelColor?: string;
  primaryId?: string;
  options: { label: string; action: () => void; textColorClass?: string; disabled?: boolean }[];
  dropdownDirection?: 'down' | 'up';
  loading?: boolean;
  buttonStyle?: 'primary' | 'secondary';
};

const SplitButton: React.FC<ComponentProps> = (props) => {
  const {
    primaryLabel,
    primaryAction,
    primaryLabelColor = '',
    primaryId,
    options,
    dropdownDirection = 'down',
    loading = false,
    buttonStyle = 'primary',
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const mainBtnStyle =
    buttonStyle === 'primary' ? 'button--primary border-0' : 'button--secondary border-r-0';
  const separatorStyle =
    buttonStyle === 'primary'
      ? 'bg-litlingo-primary'
      : 'bg-white border-t border-b border-litlingo-gray-3';
  const textColor = buttonStyle === 'primary' ? 'white' : 'litlingo-gray-3';
  const secondaryBtnStyle =
    buttonStyle === 'primary' ? 'button--primary border-0' : 'button--secondary border-l-0';

  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: () => void
  ): void => {
    setIsOpen(false);
    e.stopPropagation();
    action();
  };

  return (
    <div ref={wrapperRef} className="relative h-full w-full flex flex-col items-end">
      <div className="w-full h-full flex flex-row items-center rounded">
        <button
          type="button"
          className={`button ${mainBtnStyle} ${
            options.length === 0 && buttonStyle === 'secondary' ? 'border-r ' : ''
          } h-full flex justify-center py-1.5 px-2 w-full focus:outline-none ${
            options.length > 0 ? 'rounded-r-none' : ''
          }`}
          onClick={primaryAction}
          id={primaryId}
          data-testid="primary-button"
        >
          {loading ? (
            <LoadingIndicator size="5" />
          ) : (
            <span className={`font-bold whitespace-no-wrap ${primaryLabelColor}`}>
              {primaryLabel}
            </span>
          )}
        </button>

        {options.length > 0 && (
          <>
            <div className={`flex items-center h-full w-px ${separatorStyle}`}>
              <div className={`h-4/5 w-px bg-${textColor} `} />
            </div>
            <button
              type="button"
              data-testid="split-button-open"
              className={`button ${secondaryBtnStyle} h-full px-2 rounded-l-none focus:outline-none`}
              onClick={(): void => setIsOpen(!isOpen)}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </>
        )}
      </div>
      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className={`origin-bottom-left absolute bg-white rounded z-50 border border-litlingo-gray-2 ${
            dropdownDirection === 'down'
              ? 'top-full mt-1'
              : 'top-0 -mt-1 transform -translate-y-full'
          }`}
        >
          <div
            className="rounded bg-white shadow-xs overflow-hidden"
            style={{ boxShadow: '3px 1px 8px rgba(0, 0, 0, 0.35)' }}
          >
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((o) => (
                <button
                  key={o.label}
                  type="button"
                  data-testid={o.label}
                  onClick={(e): void => handleOptionClick(e, o.action)}
                  className={`w-full text-body border-litlingo-gray-2 p-2 focus:outline-none ${
                    o.disabled ? 'bg-litlingo-gray-0.5' : 'hover:bg-litlingo-gray-1'
                  }`}
                  disabled={o.disabled}
                >
                  <div className="flex whitespace-no-wrap">
                    <span
                      className={o.textColorClass || `${o.disabled ? 'text-litlingo-gray-3' : ''}`}
                    >
                      {o.label}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SplitButton;
