import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import React from 'react';
import type { TagGroup } from 'types';

interface Props {
  tagGroup: TagGroup;
  selected: boolean;
  setSelectedTagGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

const TagGroupListItem: React.VFC<Props> = ({ tagGroup, selected, setSelectedTagGroups }) => {
  // @ts-ignore
  const { uuid, name, description, tag_values: tags, on_coding_panel: onCodingPanel } = tagGroup;

  const handleTagGroupSelect = (checked: boolean): void => {
    if (checked) {
      setSelectedTagGroups((prev) => [...prev, uuid]);
    } else {
      setSelectedTagGroups((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    handleTagGroupSelect(e.target.checked);
  };

  return (
    <LinkLookup
      key={uuid}
      as="tr"
      className="border-t border-gray-200 hover:bg-gray-100 table-row h-10"
      routeName="tag-group-detail"
      routeParams={{ tagGroupId: uuid }}
    >
      <td className="table-wrapper__new-td flex w-full">
        <div className="w-full pt-2.5 px-2 min-h-8 flex justify-end">
          <input
            id={`checkbox-user-${uuid}`}
            data-testid={`checkbox-${tagGroup.name}`}
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleRowCheckboxChange(e);
            }}
            checked={selected}
          />
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{name}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{tags.length}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{onCodingPanel ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{description}</div>
          </div>
        </div>
      </td>
      <Permissions action="tags.seeTenancy">
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div className="">
                {/* @ts-ignore */}
                {tagGroup.tenant_name === 'human' ? 'External' : 'Internal'}
              </div>
            </div>
          </div>
        </td>
      </Permissions>
    </LinkLookup>
  );
};

export default TagGroupListItem;
