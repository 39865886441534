import { fetchRuleChangelog } from 'actions';
import {
  fetchRuleRevisionChangelog,
  fetchRuleRevisions,
  fetchRuleRevisionsRequest,
} from 'actions/ruleGroup';
import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import {
  fetchChangelogFailure,
  fetchChangelogFulfill,
  fetchChangelogRequest,
  fetchChangelogSuccess,
} from 'reducers/auditLogs';
import {
  fetchRulesAuditLogs,
  fetchRulesAuditLogsFailure,
  fetchRulesAuditLogsSuccess,
} from 'reducers/rulesAuditLogs';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, APIV2, SagaReturn } from 'types';

function* fetchRulesAuditLogsSaga(action: ReturnType<typeof fetchRulesAuditLogs>): SagaReturn {
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.auditLogs)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    ...action.payload,
    include_pii: 'true',
  };

  const response = (yield call([LitLingoClient.resources.rules.extras, 'auditLogs'], {
    params,
  })) as API.Response<API.Rules.AuditLogs>;
  if (response.error != null) {
    yield put(fetchRulesAuditLogsFailure(response.error));
  } else {
    yield put(fetchRulesAuditLogsSuccess(response.data));
  }
}

function* fetchRuleChangelogSaga(action: ReturnType<typeof fetchRuleChangelog>): SagaReturn {
  yield put(fetchChangelogRequest());

  const { version, auditlogUuid, ruleId } = action.payload;

  const response = (yield call([LitLingoClient.resources.rules.extras, 'changelog'], {
    urlParams: { ruleId, version },
  })) as API.Response<API.Customers.Changelog>;

  if (response.error != null) {
    yield put(
      fetchChangelogFailure({ ...response.error, message: response.error.message, auditlogUuid })
    );
  } else {
    yield put(fetchChangelogSuccess({ auditlogUuid, changelog: response.data.payload }));
  }
  yield put(fetchChangelogFulfill());
}

function* fetchRuleRevisionsSaga(action: ReturnType<typeof fetchRuleRevisions>): SagaReturn {
  const { payload } = action;
  yield put(fetchRuleRevisionsRequest());
  const response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'getRevisions'], {
    params: {
      include_count: true,
      rule_uuids: [payload.ruleId],
      order_by: 'created_at',
      order_desc: 'true',
      include_pii: true,
      selectable_fields: ['name', 'uuid', 'rule_group_uuid', 'updated_by.name', 'updated_at'],
    },
  })) as API.Response<APIV2.RuleGroups.GetRevisions>;
  if (response.error != null) {
    yield put(fetchRulesAuditLogsFailure(response.error));
  } else {
    yield put(fetchRulesAuditLogsSuccess(response.data));
  }
}

function* fetchRuleRevisionChangelogSaga(
  action: ReturnType<typeof fetchRuleRevisionChangelog>
): SagaReturn {
  yield put(fetchChangelogRequest());

  const { auditlogUuid, revisionId } = action.payload;

  const response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'changelog'], {
    urlParams: { revisionId },
  })) as API.Response<APIV2.RuleGroups.Changelog>;

  if (response.error != null) {
    yield put(
      fetchChangelogFailure({ ...response.error, message: response.error.message, auditlogUuid })
    );
  } else {
    yield put(fetchChangelogSuccess({ auditlogUuid, changelog: response.data.payload }));
  }
}

function* rulesAuditLogsSaga(): SagaReturn {
  yield takeLatest(fetchRulesAuditLogs, fetchRulesAuditLogsSaga);
  yield takeLatest(fetchRuleChangelog, fetchRuleChangelogSaga);
  yield takeLatest(fetchRuleRevisionChangelog, fetchRuleRevisionChangelogSaga);
  yield takeLatest(fetchRuleRevisions.toString(), fetchRuleRevisionsSaga);
}

export default rulesAuditLogsSaga;
