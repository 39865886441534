import { deleteTagGroup } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchSingleTagGroupLoading, getSelectedTagGroup } from 'selectors/tagGroup';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TagGroupHeader from './TagGroupHeader';
import TagsList from './TagsList';

const TagGroup: React.VFC = () => {
  const dispatch = useDispatch();
  const tagGroup = useSelector(getSelectedTagGroup);

  const [isDeletingTagGroup, setIsDeletingTagGroup] = useState(false);

  const loading = useSelector(getFetchSingleTagGroupLoading);

  const toggleConfirmModal = (): void => setIsDeletingTagGroup(false);

  const handleDeleteClick = (): void => {
    if (tagGroup) {
      dispatch(deleteTagGroup({ id: tagGroup.uuid }));
      toggleConfirmModal();
    }
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (!tagGroup) return null;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />

        <div className="pt-4 pb-10 w-full flex flex-col gap-2 overflow-hidden">
          <TagGroupHeader />
          {loading ? (
            <LoadingIndicator className="m-auto mt-20" size="20" />
          ) : (
            <main className="flex w-full overflow-hidden">
              <TagsList tagGroup={tagGroup} loading={false} />
            </main>
          )}
        </div>
      </div>
      {isDeletingTagGroup && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this tag group?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteClick}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default TagGroup;
