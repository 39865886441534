import { Category, Customer } from '@litlingo/client';
import { deleteCategory, selectCustomerAndRedirect } from 'actions';
import platform from 'constants/platform';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCategoriesList } from 'selectors/categories';
import { getRuleCustomers } from 'selectors/ruleGroup';
import { useSelector } from 'store';

type RouterParams = {
  ruleId: string;
};

type ComponentProps = {
  customer: Customer;
  customerGroup: string;
};

const OutcomesSection: React.FC<ComponentProps> = ({ customer, customerGroup }) => {
  const dispatch = useDispatch();

  const { ruleId } = useParams<RouterParams>();

  const customers = useSelector(getRuleCustomers);
  const categories = useSelector(getCategoriesList);

  const customerCategories = categories
    .filter((c) => c.customer_uuid === customer.uuid)
    .filter((c) => c.rule_head?.name === customerGroup);

  const handleRedirectOutcomes = (): void => {
    const domain = customers.find((c) => c.uuid === customer.uuid)?.domain;
    const rule = customerCategories[0].rule_revision_uuid;

    if (domain && rule) {
      dispatch(
        selectCustomerAndRedirect({
          domain,
          redirect: {
            routeName: 'rule-group-outcome',
            routeParams: {
              ruleId,
            },
            customerDomain: domain,
          },
        })
      );
    }
  };

  const handleDeleteCustomerCategories = (): void => {
    customerCategories.forEach((cat) => {
      dispatch(
        deleteCategory({
          id: cat.uuid,
          redirect: false,
          ruleId: customerCategories[0].rule_revision_uuid,
        })
      );
    });
  };

  return (
    <div className="flex flex-col gap-2 text-body px-4 py-3 bg-litlingo-gray-0.5">
      <ul className="list-disc list-inside pl-1">
        {customerCategories.map((cat) => (
          <li key={cat.uuid}>
            {/* @ts-ignore */}
            <span>{cat.outcome?.name}</span>
            <ul className="pl-8 py-1 list-disc list-inside">
              {Object.entries(platform)
                .filter(([key]) => cat[key as keyof Category])
                .map(([key, value]) => (
                  <li key={key}>{value}</li>
                ))}
            </ul>
          </li>
        ))}
      </ul>

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="underline text-litlingo-alert focus:outline-none"
          onClick={handleDeleteCustomerCategories}
        >
          Remove
        </button>
        <button
          type="button"
          onClick={handleRedirectOutcomes}
          className="button button--secondary self-center h-8"
        >
          <span className="font-bold">Manage Outcomes</span>
        </button>
      </div>
    </div>
  );
};

export default OutcomesSection;
