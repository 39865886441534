import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AnnotatorVersion, API, AuditLog, NormalizedResource, UUID } from 'types';
import { fetchChangelogFailure, fetchChangelogSuccess } from './auditLogs';

type Loading = 'annotators-audit-logs';

export type AnnotatorsAuditLogsState = {
  error: ErrorData | null;
  loading: Loading[];
  count: number;
  items: NormalizedResource<AuditLog>;
  list: UUID[] | null;
};

type AnnotatorsAuditLogsReducer<P = void> = (
  state: AnnotatorsAuditLogsState,
  action: PayloadAction<P>
) => void;

export const initialState: AnnotatorsAuditLogsState = {
  error: null,
  loading: [],
  count: 0,
  items: {},
  list: null,
};

type FetchAnnotatorsAuditLogsPayload = {
  [param: string]: string | string[];
};
const handleFetchAnnotatorsAuditLogs: AnnotatorsAuditLogsReducer<
  FetchAnnotatorsAuditLogsPayload
> = (state) => {
  state.loading.push('annotators-audit-logs');
  state.error = null;
};

type FetchAnnotatorsAuditLogsSuccessPayload = API.WrappedAPIResponse<AnnotatorVersion>;
const handleFetchAnnotatorsAuditLogsSuccess: AnnotatorsAuditLogsReducer<
  FetchAnnotatorsAuditLogsSuccessPayload
> = (state, action) => {
  state.count = action.payload.count;
  state.items = action.payload.records.reduce(
    (curr, log) => ({ ...curr, [log.uuid]: log }),
    state.items
  );
  state.list = action.payload.records.map((log) => log.uuid);
};

type FetchAnnotatorsAuditLogsFailurePayload = ErrorData;
const handleFetchAnnotatorsAuditLogsFailure: AnnotatorsAuditLogsReducer<
  FetchAnnotatorsAuditLogsFailurePayload
> = (state, action) => {
  state.error = action.payload;
};

const handleFetchAnnotatorsAuditLogsFulfill: AnnotatorsAuditLogsReducer = (state) => {
  state.loading = state.loading.filter((l) => l !== 'annotators-audit-logs');
};

export type FetchChangelogSuccessType = {
  auditlogUuid: UUID;
  changelog: string[];
};

const handleFetchChangelogSuccess: AnnotatorsAuditLogsReducer<FetchChangelogSuccessType> = (
  state,
  action
) => {
  const { auditlogUuid, changelog } = action.payload;

  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog };
};

type FetchChangelogFailurePayload = ErrorData & { message: string; auditlogUuid: UUID };
const handleFetchChangelogFailure: AnnotatorsAuditLogsReducer<FetchChangelogFailurePayload> = (
  state,
  action
) => {
  const { message, auditlogUuid } = action.payload;
  state.error = action.payload;
  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog: [message] };
};

const annotatorsauditLogsSlice = createSlice({
  name: 'annotatorsAuditLogs',
  initialState,
  extraReducers: {
    [fetchChangelogSuccess.toString()]: handleFetchChangelogSuccess,
    [fetchChangelogFailure.toString()]: handleFetchChangelogFailure,
  },
  reducers: {
    fetchAnnotatorsAuditLogs: handleFetchAnnotatorsAuditLogs,
    fetchAnnotatorsAuditLogsSuccess: handleFetchAnnotatorsAuditLogsSuccess,
    fetchAnnotatorsAuditLogsFailure: handleFetchAnnotatorsAuditLogsFailure,
    fetchAnnotatorsAuditLogsFulfill: handleFetchAnnotatorsAuditLogsFulfill,
  },
});

const { actions, reducer } = annotatorsauditLogsSlice;

export const {
  fetchAnnotatorsAuditLogs,
  fetchAnnotatorsAuditLogsSuccess,
  fetchAnnotatorsAuditLogsFailure,
  fetchAnnotatorsAuditLogsFulfill,
} = actions;

export default reducer;
