/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { promoteAll } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import { ChangesSummaryMap } from 'constants/models';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getModel, getModelCategoriesChanges, getModelDiffLoading } from 'selectors/models';
import { useSelector } from 'store';
import { printStringArray } from 'utils/strings';

type RouterParams = { ruleId: string; modelId: string };

type ComponentProps = {
  setShowPromoteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModelPromotionModal: React.FC<ComponentProps> = ({ setShowPromoteModal }) => {
  const { modelId } = useParams<RouterParams>();
  const dispatch = useDispatch();

  const model = useSelector(getModel(modelId));
  const changesSummary = useSelector((state) => getModelCategoriesChanges(state, modelId));
  const diffLoading = useSelector(getModelDiffLoading);

  const handlePromoteRules = (): void => {
    dispatch(promoteAll({ modelId }));
    setShowPromoteModal(false);
  };

  const togglePromoteModal = (): void => {
    setShowPromoteModal((b) => !b);
  };

  const parseMinorChanges = (): { ruleNames: string[]; identifierNames: string[] } => {
    const ruleNames = Object.values(changesSummary.minor).map((val) => val.ruleName);
    const identifierNames = Object.values(changesSummary.minor).reduce<string[]>(
      (acc, val) => [...acc, ...val.changes.map((c) => c.name)],
      []
    );

    return { ruleNames, identifierNames };
  };

  const parseMajorChanges = (): string => {
    const majorChanges = Object.values(changesSummary.major).reduce<string>((acc, val) => {
      const ruleChange = `${val.ruleName} - ${printStringArray(
        val.changes.map((c) => ChangesSummaryMap[c.type])
      )}\n`;

      return acc + ruleChange;
    }, '');

    return majorChanges;
  };

  const renderBody = (): JSX.Element => (
    <div className="flex flex-col mb-3 gap-4">
      <h2 className="text-heading-1">Change Summary</h2>

      <div className="flex flex-col gap-4">
        {diffLoading ? (
          <div className="flex justify-center py-6">
            <LoadingIndicator size="20" />
          </div>
        ) : (
          <>
            {Object.keys(changesSummary.minor).length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <p>
                    Model changes with only identifier updates:{' '}
                    {printStringArray(parseMinorChanges().ruleNames)}
                  </p>
                  {/* <p>
                    Identifiers include: {printStringArray(parseMinorChanges().identifierNames)}
                  </p> */}
                </div>
              </div>
            )}
            {Object.keys(changesSummary.major).length > 0 && (
              <div className="flex flex-col gap-2">
                <span className="italic">Significant Changes:</span>
                <span className="whitespace-pre-line">{parseMajorChanges()}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  if (!model) return null;

  return (
    <Modal
      title=" "
      body={renderBody()}
      okButton
      okButtonText="Accept All Changes"
      okButtonOnClick={handlePromoteRules}
      okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
      cancelButtonText="Cancel"
      cancelButtonOnclick={(): void => setShowPromoteModal(false)}
      toggleShowModal={togglePromoteModal}
    />
  );
};

export default ModelPromotionModal;
