import {
  discardIdentifierChanges,
  promoteIdentifier,
  setCompareModeIdentifier,
  setCustomerGroupIdentifier,
} from 'actions/identifier';
import CollapsableCard from 'components/CollapsableCard';
import CustomField from 'components/CustomField';
import LLMChainForm from 'components/LanguageMatcherRedesign/LLMChainForm';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCompareMode,
  getFetchIdentifiersTypesLoading,
  getShouldDisableEdit,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { AnnotatorTypes, NormalizedIdentifier } from 'types';

type ComponentProps = {
  identifier: NormalizedIdentifier;
  types: AnnotatorTypes;
};
const RenderOptionsConfig: React.FC<ComponentProps> = ({ identifier, types }) => {
  const dispatch = useDispatch();

  const loadingTypes = useSelector(getFetchIdentifiersTypesLoading);
  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisableEdit);
  const unsaved = useSelector(unsavedChanges);

  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);

  const type = Object.keys(types).find((k) => k === identifier.kind) || '';

  const toggleModal = (): void => {
    setIsPromoteModalOpen((b) => !b);
  };

  const handleExit = (): void => {
    dispatch(setCompareModeIdentifier(false));
  };

  const handlePromote = (): void => {
    if (identifier?.identifier_head_uuid && identifier.identifier_uuid) {
      dispatch(
        promoteIdentifier({
          identifierHead: identifier.identifier_head_uuid,
          identifierId: identifier.identifier_uuid,
        })
      );
    }
    toggleModal();
    dispatch(setCustomerGroupIdentifier('staging'));
    dispatch(setCompareModeIdentifier(false));
  };

  const renderOptions = (): JSX.Element | JSX.Element[] | null => {
    if (
      !identifier ||
      !identifier.options_config ||
      !Object.keys(identifier.options_config) ||
      loadingTypes ||
      Object.keys(types).length === 0
    ) {
      return null;
    }

    // @ts-ignore
    if (identifier.kind === 'llm_chain') {
      return <LLMChainForm isSaved={false} />;
    }

    return Object.entries(types[identifier.kind].options).map(([key, value]) => {
      if (value == null) {
        throw new Error(`Undefined options for type ${key}`);
      }

      return (
        <CustomField
          key={value.name}
          inputKey={key}
          name={value.name}
          type={value.type as 'str' | 'float' | 'bool'}
          customElement={value.ui_element}
          isList={value.is_list}
          isRequired={value.required}
          compareMode={compareMode}
          shouldDisable={shouldDisable}
        />
      );
    });
  };

  const renderCompareModeButtons = (
    <div className="flex flex-row gap-2">
      <button
        data-testid="save-matchers-button"
        type="button"
        className="button button--secondary h-8 w-22 justify-center text-body"
        onClick={(e): void => {
          handleExit();
          e.stopPropagation();
        }}
      >
        <span className="font-bold">Exit</span>
      </button>
      <button
        data-testid="save-matchers-button"
        type="button"
        className="button button--primary h-8 w-44 justify-center text-body"
        disabled={shouldDisable || unsaved}
        onClick={(e): void => {
          toggleModal();
          e.stopPropagation();
        }}
      >
        <span className="font-bold text-white">Push to Production</span>
      </button>
    </div>
  );

  const renderSaveConfig = (
    <div className="h-8 flex flex-row items-end gap-2">
      {unsaved && (
        <button
          data-testid="save-matchers-button"
          type="button"
          className="button button--secondary h-full w-22 justify-center"
          onClick={(e): void => {
            dispatch(discardIdentifierChanges());
            e.stopPropagation();
          }}
        >
          <span className="font-bold">Discard</span>
        </button>
      )}
      <button
        id="save-identifier-setup-button"
        onClick={(e): void => e.stopPropagation()}
        type="submit"
        className="button button--primary h-8"
      >
        Save
      </button>
    </div>
  );

  const renderCustomActions = (): JSX.Element => {
    if (compareMode) {
      return renderCompareModeButtons;
    }
    return renderSaveConfig;
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>
      <div className="flex flex-col mt-30 mb-8 gap-4">
        <h2 className="text-heading-1">Push Changes?</h2>
      </div>
    </>
  );

  return (
    <>
      <CollapsableCard
        headerTitle={types[type].meta.name || 'Options'}
        customAction={renderCustomActions()}
        containerClasses="h-full w-full border-none flex flex-col overflow-hidden"
        contentContainerClasses="flex h-full overflow-hidden"
        stickyHeader
      >
        <div className="w-full h-full p-4 overflow-auto custom-scrollbar">{renderOptions()}</div>
      </CollapsableCard>
      {isPromoteModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText="Push to Prod"
          okButtonOnClick={handlePromote}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText="Go Back"
          cancelButtonOnclick={(): void => setIsPromoteModalOpen(false)}
          toggleShowModal={toggleModal}
        />
      )}
    </>
  );
};

export default RenderOptionsConfig;
