import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Alert from 'components/Alert';
import CustomerWarning from 'components/CustomerWarning';
import HotKeysHelpModal from 'components/HotKeysHelpModal';
import PrivateRoute from 'components/PrivateRoute';
import { ConnectedRouter } from 'connected-react-router';
import { AlertsProvider } from 'contexts/AlertsContext';
import queryClient from 'hooks/queryClient';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { configure } from 'react-hotkeys';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import { history } from 'store';
import AlertWithHooks from './Alert/AlertWithHooks';

configure({
  /**
   * The HTML tags that React HotKeys should ignore key events from. This only works
   * if you are using the default ignoreEventsCondition function.
   * @type {String[]}
   */
  ignoreTags: ['select'],
});

const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <AlertsProvider>
      <SkeletonTheme color="#D9DBE9" highlightColor="#DFE1EF">
        <DndProvider backend={HTML5Backend}>
          <ConnectedRouter history={history}>
            <Alert />
            <AlertWithHooks />
            <CustomerWarning />
            <HotKeysHelpModal />
            <Switch>
              {routes.map((route) => {
                let component;

                if (route.private) {
                  component = PrivateRoute(route);
                } else if ('component' in route) {
                  component = route.component;
                }

                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={component}
                    exact={route.exact}
                  />
                );
              })}
            </Switch>
          </ConnectedRouter>
        </DndProvider>
      </SkeletonTheme>
    </AlertsProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
