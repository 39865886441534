import { redirectToLitlingoLogin } from 'actions';
import { OKTA_ICON } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

interface SignInWithOktaButtonProps {
  isEmailInputVisible: boolean;
  setIsEmailInputVisible: (visible: boolean) => void;
}

const SignInWithOktaButton: React.FC<SignInWithOktaButtonProps> = ({
  isEmailInputVisible,
  setIsEmailInputVisible,
}) => {
  const dispatch = useDispatch();
  const [loginEmail, setLoginEmail] = useState<string>(
    window.localStorage.getItem('loginEmail') || ''
  );

  const isValidEmail = loginEmail.toLowerCase().endsWith('@tyson.com');

  const handleLoginClick = (): void => {
    setIsEmailInputVisible(true);

    if (isValidEmail) {
      dispatch(redirectToLitlingoLogin({ providerHint: 'okta' }));
    }
  };

  return (
    <div>
      {isEmailInputVisible && (
        <input
          id="login-email"
          name="login-email"
          className="form-input h-full w-full text-litlingo-gray-6 text-body placeholder-italic placeholder-litlingo-gray-4 mb-2"
          placeholder="Enter your email"
          onChange={(event): void => {
            window.localStorage.setItem('loginEmail', event.target.value);
            setLoginEmail(event.target.value);
          }}
          value={loginEmail}
        />
      )}
      <button
        type="button"
        className={`bg-litlingo-background flex justify-center items-center border border-litlingo-line w-full py-2 focus:outline-none${
          !isValidEmail && isEmailInputVisible ? ' bg-gray-300' : ''
        }`}
        onClick={handleLoginClick}
        disabled={isEmailInputVisible && !isValidEmail}
      >
        <span className="mr-2">{OKTA_ICON}</span>
        <span
          className={`text text--medium${
            !isValidEmail && isEmailInputVisible ? ' text-gray-600' : ''
          }`}
        >
          Sign in with <b>Okta</b>
        </span>
      </button>
    </div>
  );
};

export default SignInWithOktaButton;
