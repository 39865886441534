import type { ErrorData, TagGroup } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addTagsToTagGroupFailure,
  addTagsToTagGroupRequest,
  addTagsToTagGroupSuccess,
  deleteTagGroupFailure,
  deleteTagGroupRequest,
  deleteTagGroupSuccess,
  fetchAllTagGroupsFailure,
  fetchAllTagGroupsRequest,
  fetchAllTagGroupsSuccess,
  fetchSingleTagGroupFailure,
  fetchSingleTagGroupRequest,
  fetchSingleTagGroupSuccess,
  fetchTagGroupsFailure,
  fetchTagGroupsRequest,
  fetchTagGroupsSuccess,
  removeTagsFromTagGroupFailure,
  removeTagsFromTagGroupRequest,
  removeTagsFromTagGroupSuccess,
  upsertTagGroupFailure,
  upsertTagGroupRequest,
  upsertTagGroupSuccess,
} from 'actions/tagGroups';
import type { API, ErrorObject, NormalizedResource, Tag, UUID } from 'types';

export type TagGroupsState = {
  error: ErrorData | null;
  loading: string[];
  selectedTagGroup: TagGroup | null;
  items: NormalizedResource<TagGroup>;
  filterItems: NormalizedResource<TagGroup>;
  listIds: UUID[];
  count: number;
};

type TagGroupsReducer<P = void> = (state: TagGroupsState, action: PayloadAction<P>) => void;

const initialState: TagGroupsState = {
  error: null,
  loading: [],
  selectedTagGroup: null,
  items: {},
  filterItems: {},
  listIds: [],
  count: 0,
};

const handleFetchAllTagGroupsRequest: TagGroupsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllTagGroupsRequest.toString()],
});

const handleFetchAllTagGroupsFailure: TagGroupsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllTagGroupsRequest.toString()),
});

const handleFetchAllTagGroupsSuccess: TagGroupsReducer<API.WrappedAPIResponse<TagGroup>> = (
  state,
  { payload }
) => {
  const tagGroups: NormalizedResource<TagGroup> = {};
  const ids: UUID[] = [];

  payload.records.forEach((group) => {
    tagGroups[group.uuid] = group;
    ids.push(group.uuid);
  });

  return {
    ...state,
    items: tagGroups,
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllTagGroupsRequest.toString()),
  };
};

const handleFetchTagGroupsRequest: TagGroupsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTagGroupsRequest.toString()],
});

const handleFetchTagGroupsFailure: TagGroupsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTagGroupsRequest.toString()),
});

const handleFetchTagGroupsSuccess: TagGroupsReducer<API.WrappedAPIResponse<TagGroup>> = (
  state,
  { payload }
) => {
  const tagGroups: NormalizedResource<TagGroup> = {};
  const ids: UUID[] = [];

  payload.records.forEach((group) => {
    tagGroups[group.uuid] = group;
    ids.push(group.uuid);
  });

  return {
    ...state,
    filterItems: tagGroups,
    loading: state.loading.filter((s) => s !== fetchTagGroupsRequest.toString()),
  };
};

const handleFetchSingleTagGroupRequest: TagGroupsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleTagGroupRequest.toString()],
});

const handleFetchSingleTagGroupFailure: TagGroupsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleTagGroupRequest.toString()),
});

const handleFetchSingleTagGroupSuccess: TagGroupsReducer<TagGroup> = (state, { payload }) => ({
  ...state,
  selectedTagGroup: payload,
  loading: state.loading.filter((s) => s !== fetchSingleTagGroupRequest.toString()),
});

const handleUpsertTagGroupRequest: TagGroupsReducer<TagGroup> = (state) => ({
  ...state,
  loading: [...state.loading, upsertTagGroupRequest.toString()],
});

const handleUpsertTagGroupSuccess: TagGroupsReducer<TagGroup> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      ...payload,
    },
  },
  ...(state.selectedTagGroup?.uuid === payload.uuid ? { selectedTagGroup: payload } : {}),
  loading: state.loading.filter((s) => s !== upsertTagGroupRequest.toString()),
});

const handleUpsertTagGroupFailure: TagGroupsReducer<TagGroup> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== upsertTagGroupRequest.toString()),
});

const handleDeleteTagGroupRequest: TagGroupsReducer<string> = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteTagGroupRequest.toString()],
});

const handleDeleteTagGroupSuccess: TagGroupsReducer<string> = (state, { payload: id }) => {
  const items = { ...state.items };
  delete items[id];

  return {
    ...state,
    items,
    loading: state.loading.filter((s) => s !== deleteTagGroupRequest.toString()),
  };
};

const handleDeleteTagGroupFailure: TagGroupsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteTagGroupRequest.toString()),
});

const handleAddTagsToTagGroupRequest: TagGroupsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addTagsToTagGroupRequest.toString()],
});

const handleAddTagsToTagGroupFailure: TagGroupsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addTagsToTagGroupRequest.toString()),
});

const handleAddTagsToTagGroupSuccess: TagGroupsReducer<{ tagGroupId: string; tags: Tag[] }> = (
  state,
  { payload }
) => {
  const item = state.items[payload.tagGroupId] || state.selectedTagGroup;
  const updatedTagGroup = {
    ...item,
    tag_values: [
      ...item.tag_values.map((t) => payload.tags.find((tag) => tag.uuid === t.uuid) || t),
      ...payload.tags.filter((t) => !item.tag_values.find((tag) => tag.uuid === t.uuid)),
    ],
  };

  return {
    ...state,
    items: { ...state.items, [payload.tagGroupId]: updatedTagGroup },
    ...(state.selectedTagGroup?.uuid === payload.tagGroupId
      ? { selectedTagGroup: updatedTagGroup }
      : {}),
    loading: state.loading.filter((s) => s !== addTagsToTagGroupRequest.toString()),
  };
};

const handleRemoveTagsFromTagGroupRequest: TagGroupsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, removeTagsFromTagGroupRequest.toString()],
});

const handleRemoveTagsFromTagGroupFailure: TagGroupsReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== removeTagsFromTagGroupRequest.toString()),
});

const handleRemoveTagsFromTagGroupSuccess: TagGroupsReducer<{ tagGroupId: string; tags: Tag[] }> = (
  state,
  { payload }
) => {
  const item = state.items[payload.tagGroupId] || state.selectedTagGroup;
  const removedUuids = payload.tags.map(({ uuid }) => uuid);
  const newTags = item.tag_values.filter(({ uuid }) => !removedUuids.includes(uuid));
  const updatedTagGroup = { ...item, tag_values: [...newTags] };

  return {
    ...state,
    items: { ...state.items, [payload.tagGroupId]: updatedTagGroup },
    ...(state.selectedTagGroup?.uuid === payload.tagGroupId
      ? { selectedTagGroup: updatedTagGroup }
      : {}),
    loading: state.loading.filter((s) => s !== removeTagsFromTagGroupRequest.toString()),
  };
};

const handlers = {
  [fetchAllTagGroupsRequest.toString()]: handleFetchAllTagGroupsRequest,
  [fetchAllTagGroupsSuccess.toString()]: handleFetchAllTagGroupsSuccess,
  [fetchAllTagGroupsFailure.toString()]: handleFetchAllTagGroupsFailure,
  [fetchTagGroupsRequest.toString()]: handleFetchTagGroupsRequest,
  [fetchTagGroupsSuccess.toString()]: handleFetchTagGroupsSuccess,
  [fetchTagGroupsFailure.toString()]: handleFetchTagGroupsFailure,
  [fetchSingleTagGroupRequest.toString()]: handleFetchSingleTagGroupRequest,
  [fetchSingleTagGroupSuccess.toString()]: handleFetchSingleTagGroupSuccess,
  [fetchSingleTagGroupFailure.toString()]: handleFetchSingleTagGroupFailure,
  [upsertTagGroupRequest.toString()]: handleUpsertTagGroupRequest,
  [upsertTagGroupSuccess.toString()]: handleUpsertTagGroupSuccess,
  [upsertTagGroupFailure.toString()]: handleUpsertTagGroupFailure,
  [deleteTagGroupRequest.toString()]: handleDeleteTagGroupRequest,
  [deleteTagGroupSuccess.toString()]: handleDeleteTagGroupSuccess,
  [deleteTagGroupFailure.toString()]: handleDeleteTagGroupFailure,
  [addTagsToTagGroupRequest.toString()]: handleAddTagsToTagGroupRequest,
  [addTagsToTagGroupFailure.toString()]: handleAddTagsToTagGroupFailure,
  [addTagsToTagGroupSuccess.toString()]: handleAddTagsToTagGroupSuccess,
  [removeTagsFromTagGroupSuccess.toString()]: handleRemoveTagsFromTagGroupSuccess,
  [removeTagsFromTagGroupRequest.toString()]: handleRemoveTagsFromTagGroupRequest,
  [removeTagsFromTagGroupFailure.toString()]: handleRemoveTagsFromTagGroupFailure,
};

const tagsReducer = createReducer(initialState, handlers);

export default tagsReducer;
