import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getFetchTagGroupsLoading, getTagGroups, getTagGroupsTotalCount } from 'selectors/tagGroup';
import type { TagGroup, UUID } from 'types';
import CreateTagGroupModal from './CreateTagGroupModal';
import TagGroupListActionRow from './TagGroupListActionRow';
import TagGroupListItem from './TagGroupListItem';

interface Props {
  tagGroups: TagGroup[];
  loading: boolean;
}

const TagGroupList: React.VFC<Props> = ({ tagGroups, loading }) => {
  const [selectedTagGroups, setSelectedTagGroups] = useState<UUID[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditModal = (): void => setIsEditing(!isEditing);

  return (
    <>
      <div className="table-wrapper__new custom-scrollbar overflow-auto">
        <LoadingOverlay
          active={loading}
          spinner={<LoadingIndicator size="10" />}
          fadeSpeed={0}
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: (base) => ({
              ...base,
              height: '100%',
            }),
            spinner: () => ({}),
            overlay: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <table className="table-wrapper__table table-wrapper_table--fixed">
            <thead className="sticky top-0 z-10">
              <tr>
                <th className="table-wrapper__new-th py-0 table-wrapper__th--w-5">
                  <div className="w-full pr-2 flex justify-end">
                    <input
                      id="checkbox-tag-groups"
                      data-testid="checkbox"
                      type="checkbox"
                      className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                        selectedTagGroups?.length > 0 &&
                        selectedTagGroups?.length < tagGroups.length
                          ? 'checkbox-select-all-partial'
                          : ''
                      }`}
                      onClick={(e): void => e.stopPropagation()}
                      onChange={(): void => {
                        if (selectedTagGroups.length === tagGroups.length) {
                          setSelectedTagGroups([]);
                        } else {
                          setSelectedTagGroups(tagGroups.map((t) => t.uuid));
                        }
                      }}
                      checked={
                        selectedTagGroups?.length === tagGroups.length &&
                        selectedTagGroups?.length > 0
                      }
                    />
                  </div>
                </th>
                <th className="table-wrapper__new-th table-wrapper__th--w-22">Name</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-16">Tags</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-16 leading-3">
                  On Coding Panel
                </th>
                <th className="table-wrapper__new-th">Description</th>
                <Permissions action="tags.seeTenancy">
                  <th className="table-wrapper__new-th table-wrapper__th--w-14">Type</th>
                </Permissions>
              </tr>
              {selectedTagGroups?.length >= 1 && (
                <TagGroupListActionRow
                  selectedTagGroups={selectedTagGroups}
                  setSelectedTagGroups={setSelectedTagGroups}
                  toggleModalOpen={toggleEditModal}
                />
              )}
            </thead>
            <tbody className="table-wrapper__tbody">
              {tagGroups.length === 0 ? (
                <EmptyTableState message="There are no tag groups to display" colCount="5" />
              ) : (
                tagGroups.map((tagGroup) => (
                  <TagGroupListItem
                    key={tagGroup.uuid}
                    tagGroup={tagGroup}
                    selected={selectedTagGroups.includes(tagGroup.uuid)}
                    setSelectedTagGroups={setSelectedTagGroups}
                  />
                ))
              )}
            </tbody>
          </table>
          <div className="border-t border-gray-200 sticky bottom-0 bg-white">
            <ListFooter
              resourceStateName={resourceQueryParamName.tagGroups}
              resourceName="group tag"
              getResourceList={getTagGroups}
              getResourceTotalCount={getTagGroupsTotalCount}
              getResourceLoading={getFetchTagGroupsLoading}
            />
          </div>
        </LoadingOverlay>
      </div>
      {isEditing && (
        <CreateTagGroupModal
          tagGroup={tagGroups.find((t) => selectedTagGroups.includes(t.uuid)) || null}
          isModalOpen={isEditing}
          toggleModalOpen={toggleEditModal}
        />
      )}
    </>
  );
};

export default TagGroupList;
