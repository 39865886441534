// FIXME: There are two components like this now in Envelope and EnvelopeList folders. This is to deal with weird typing
import { reviewEnvelope } from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import { getEnvelopeReviewLabelOptionsMemoized } from 'selectors/reviewStatus';
import { CommunicationEnvelopeSummary, ReviewValueConfigType } from 'types';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  data: CommunicationEnvelopeSummary;
  disabled?: boolean;
};

type ReviewOptionKeys = (keyof ReviewValueConfigType)[];

const EnvelopeListStatusDropdown: React.FC<ComponentProps> = (props) => {
  const { className, disabled, data } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [positionStyles, setPositionStyles] = useState({});

  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptionsMemoized);

  const singleAssigment = useSelector(getUsersSingleAssigment);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const changeEventsStatus = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    state: string
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    if (data.events != null && data.envelope.review_value !== state) {
      const envelopeId = data.envelope.uuid;
      dispatch(
        reviewEnvelope({
          envelopeId,
          value: state,
          secondsSpent: 0,
          assignmentId: showSkippedEnvelopes ? singleAssigment?.uuid : undefined,
          showSkips: showSkippedEnvelopes,
        })
      );
      logEvent('envelopes-list-status-change');
    }
  };

  const dropdownRef = useCallback((node) => {
    if (node !== null && wrapperRef.current) {
      const { height } = node.getBoundingClientRect();
      const { y } = wrapperRef.current.getBoundingClientRect();

      if (window.innerHeight < y + height + 130) {
        setPositionStyles({
          bottom: wrapperRef.current.getBoundingClientRect().height + 5,
        });
      } else {
        setPositionStyles({});
      }
    }
  }, []);

  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key: string
  ): void => {
    e.preventDefault();
    e.stopPropagation();

    changeEventsStatus(e, key);
    setIsOpen(false);
  };

  const getOptionValues = (): JSX.Element => {
    const keysWithoutSkipped = Object.keys(reviewLabelOptions).filter(
      (key) => key !== 'skipped'
    ) as ReviewOptionKeys;
    return (
      <>
        {keysWithoutSkipped.map((key) => (
          <button
            type="button"
            data-testid={`event-change-status-to-${reviewLabelOptions[key]?.toLowerCase()}`}
            key={key}
            className="w-full border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1"
            onClick={(e): void => handleOptionClick(e, key)}
          >
            <div className="flex text-base text-left leading-5">
              <span className="cursor-pointer">{reviewLabelOptions[key]}</span>
            </div>
          </button>
        ))}
      </>
    );
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className={`inline-block text-left py-px px-2 rounded border border-litlingo-gray-3 ${
          className || ''
        }`}
      >
        <div>
          <button
            type="button"
            className={`flex flex-row items-center gap-3 text-base font-normal  focus:outline-none transition ease-in-out duration-150 disabled:opacity-50 ${
              disabled ? '' : 'hover:font-bold'
            }`}
            id="options-menu"
            data-testid="event-change-status-button"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            <span>
              {data.envelope.review_value
                ? reviewLabelOptions[data.envelope.review_value as keyof typeof reviewLabelOptions]
                : reviewLabelOptions.pending}
            </span>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99221 4.75513L1.46719 0.399994L0 1.92898L5.99228 7.67406L12 1.92898L10.5328 0.399994L5.99221 4.75513Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>

        {isOpen && (
          <div
            data-testid="dropdown-menu"
            className="fixed -ml-2 mt-1 w-32 rounded shadow-lg z-10"
            style={positionStyles}
            ref={dropdownRef}
          >
            <div className="rounded bg-white shadow-xs overflow-hidden">
              <div
                role="menu"
                data-testid="options-container"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {getOptionValues()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EnvelopeListStatusDropdown;
