import { cleanChangedNodes, setCompareMode } from 'actions';
import { fetchCompareRule, promoteRule, updateRule } from 'actions/ruleGroup';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesList } from 'selectors/categories';
import { getCompareMode, getHasUnsavedChanges } from 'selectors/config';
import { getHasStagingRule, getShouldDisable } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import type { MRuleRevision } from 'types';

type ComponentProps = {
  rule: MRuleRevision;
};

const RuleManagerSaveButton: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();

  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisable);
  const categories = useSelector(getCategoriesList);
  const unsaved = useSelector(getHasUnsavedChanges);

  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);

  const hasStaging = useSelector(getHasStagingRule);

  const toggleModal = (): void => {
    setIsPromoteModalOpen((b) => !b);
  };

  const handleSaveClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();

    dispatch(updateRule({ rule }));
    dispatch(cleanChangedNodes());
  };

  const handlePromoteRule = (): void => {
    const stagingCategory = categories.find((c) => c.rule_head?.name === 'staging');
    if (hasStaging && stagingCategory?.rule_head) {
      dispatch(
        promoteRule({ ruleGroup: rule.rule_group_uuid, head: stagingCategory.rule_head.uuid })
      );
      dispatch(cleanChangedNodes());
    }
    toggleModal();
  };

  const handleExit = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    dispatch(setCompareMode(false));
  };

  const handleCompare = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    dispatch(setCompareMode(true));

    const prodCat = categories.find((c) => c.rule_head?.name === 'production');
    const stagingCat = categories.find((c) => c.rule_head?.name === 'staging');

    if (prodCat?.rule_head && stagingCat) {
      dispatch(
        fetchCompareRule({
          baseRevisionId: prodCat.rule_head?.rule_revision_uuid,
        })
      );
    }
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2">
        <h2 className="text-heading-1">Promote Rule</h2>
        <p className="text-body">Are you sure you want to promote this rule to production?</p>
      </div>
    </>
  );

  return (
    <>
      {compareMode && (
        <>
          <button
            type="button"
            onClick={(evt): void => handleExit(evt)}
            className="button button--secondary text-body w-22 h-8 justify-center"
            data-testid="rule-mananger-exit-compare"
            id="exit-compare-rule-button"
          >
            <span className="font-bold">Exit</span>
          </button>

          <button
            type="button"
            onClick={(): void => toggleModal()}
            className="button button--primary text-body h-8 justify-center"
            data-testid="rule-mananger-save"
            id="save-rule-button"
            disabled={shouldDisable || unsaved}
          >
            <span className="text-white font-bold ">Promote to Production</span>
          </button>
        </>
      )}
      {!compareMode && (
        <>
          {hasStaging && (
            <button
              type="button"
              onClick={(evt): void => handleCompare(evt)}
              className="button button--secondary text-body w-26 h-8 justify-center"
              data-testid="rule-mananger-exit-compare"
              id="exit-compare-rule-button"
            >
              <span className="font-bold">Compare</span>
            </button>
          )}
          <button
            type="button"
            onClick={(evt): void => handleSaveClick(evt)}
            className="button button--primary text-body h-8 justify-center"
            data-testid="rule-mananger-save"
            id="save-rule-button"
            disabled={!unsaved}
          >
            <span className="text-white font-bold ">Save</span>
          </button>
        </>
      )}
      {isPromoteModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText="Promote"
          okButtonOnClick={handlePromoteRule}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText="Cancel"
          cancelButtonOnclick={(): void => setIsPromoteModalOpen(false)}
          toggleShowModal={toggleModal}
        />
      )}
    </>
  );
};

export default RuleManagerSaveButton;
