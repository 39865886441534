import { createAction } from '@reduxjs/toolkit';
import type { API, Category, ErrorObject, Model, RuleChange, UUID } from 'types';

type FetchAllModelsPayload = {
  name?: string;
  limit?: string;
  relationships?: string[];
  selectable_fields?: string[];
};
export type FetchAllModelsSuccessPayload = API.WrappedAPIResponse<Model>;

export const fetchAllModels = createAction<FetchAllModelsPayload>('MODELS/FETCH');
export const fetchAllModelsRequest = createAction('MODELS/FETCH_REQUEST');
export const fetchAllModelsSuccess =
  createAction<API.WrappedAPIResponse<Model>>('MODELS/FETCH_SUCCESS');
export const fetchAllModelsFailure = createAction<ErrorObject>('MODELS/FETCH_FAILURE');
export const fetchAllModelsFulfill = createAction('MODELS/FETCH_FULFILL');

export const fetchModelCategories = createAction<{ modelId: UUID }>(
  'MODELS/FETCH_SINGLE_WITH_CATEGORIES'
);
export const fetchModelCategoriesRequest = createAction<{ modelId: UUID }>(
  'MODELS/FETCH_MODEL_CATEGORIES_REQUEST'
);
export const fetchModelCategoriesSuccess = createAction<{ modelId: UUID; categories: Category[] }>(
  'MODELS/FETCH_MODEL_CATEGORIES_SUCCESS'
);
export const fetchModelCategoriesFailure = createAction<ErrorObject>(
  'MODELS/FETCH_MODEL_CATEGORIES_FAILURE'
);
export const fetchModelCategoriesFulfill = createAction<{ modelId: UUID }>(
  'MODELS/FETCH_MODEL_CATEGORIES_FULFILL'
);

export const fetchSingleSimpleModel = createAction<{ modelId: UUID }>('MODELS/FETCH_SINGLE_SIMPLE');

export const fetchSingleModel = createAction<{ modelId: UUID }>('MODELS/FETCH_SINGLE');
export const fetchSingleModelRequest = createAction('MODELS/FETCH_SINGLE_REQUEST');
export const fetchSingleModelSuccess = createAction<Model>('MODELS/FETCH_SINGLE_SUCCESS');
export const fetchSingleModelFailure = createAction<ErrorObject>('MODELS/FETCH_SINGLE_FAILURE');
export const fetchSingleModelFulfill = createAction('MODELS/FETCH_SINGLE_FULFILL');

export const createModel =
  createAction<{ name: string; description?: string; priority?: number }>('MODELS/CREATE');
export const createModelRequest = createAction('MODELS/CREATE_REQUEST');
export const createModelSuccess = createAction<Model>('MODELS/CREATE_SUCCESS');
export const createModelFailure = createAction<ErrorObject>('MODELS/CREATE_FAILURE');
export const createModelFulfill = createAction('MODELS/CREATE_FULFILL');

export const deleteModel = createAction<{ id: UUID }>('MODELS/DELETE');
export const deleteModelRequest = createAction<string>('MODELS/DELETE_REQUEST');
export const deleteModelSuccess = createAction<string>('MODELS/DELETE_SUCCESS');
export const deleteModelCategorySuccess = createAction<{ id: UUID; categoryId: UUID }>(
  'MODELS/DELETE_CATEGORY_SUCCESS'
);
export const deleteModelFailure = createAction<ErrorObject>('MODELS/DELETE_FAILURE');
export const deleteModelFulfill = createAction('MODELS/DELETE_FULFILL');

export type UpsertModelPayload = {
  uuid?: string;
  name: string;
  description: string;
  redirect?: boolean;
  kind?: string;
};

export const upsertModel = createAction<UpsertModelPayload>('MODELS/UPSERT');
export const upsertModelRequest = createAction('MODELS/UPSERT_REQUEST');
export const upsertModelSuccess = createAction<Model>('MODELS/UPSERT_SUCCESS');
export const upsertModelFailure = createAction<ErrorObject>('MODELS/UPSERT_FAILURE');
export const upsertModelFulfill = createAction('MODELS/UPSERT_FULFILL');

export type BulkUpsertKindModelPayload = {
  modelIds: UUID[];
  group: string;
};

export const bulkUpsertKindModel =
  createAction<BulkUpsertKindModelPayload>('MODELS/BULK_UPSERT_KIND');
export const bulkUpsertKindModelRequest = createAction('MODELS/BULK_UPSERT_KIND_REQUEST');
export const bulkUpsertKindModelSuccess = createAction<Model[]>('MODELS/BULK_UPSERT_KIND_SUCCESS');
export const bulkUpsertKindModelFailure = createAction<ErrorObject>(
  'MODELS/BULK_UPSERT_KIND_FAILURE'
);

export type UpsertModelCategoryPayload = Partial<Category> & {
  uuid?: string;
  redirect?: boolean;
};

export const upsertModelCategory =
  createAction<UpsertModelCategoryPayload>('MODEL/UPSERT_CATEGORY');
export const upsertModelCategoryRequest = createAction('MODEL/UPSERT_CATEGORY_REQUEST');
export const upsertModelCategorySuccess = createAction<Category>('MODEL/UPSERT_CATEGORY_SUCCESS');
export const upsertModelCategoryFailure = createAction<ErrorObject>(
  'MODEL/UPSERT_CATEGORY_FAILURE'
);
export const upsertModelCategoryFulfill = createAction('MODEL/UPSERT_CATEGORY_FULFILL');

export type CloneModelPayload = {
  modelId: UUID;
  redirect?: boolean;
};

export const cloneModel = createAction<CloneModelPayload>('MODEL/CLONE');
export const cloneModelRequest = createAction('MODEL/CLONE_REQUEST');
export const cloneModelFailure = createAction<ErrorObject>('MODEL/CLONE_FAILURE');
export const cloneModelSuccess = createAction<Model>('MODEL/CLONE_SUCCESS');

export const promoteAll = createAction<{ modelId: string }>('MODEL/PROMOTE_ALL');
export const promoteAllRequest = createAction('MODEL/PROMOTE_ALL_REQUEST');
export const promoteAllFailure = createAction<ErrorObject>('MODEL/PROMOTE_ALL_FAILURE');
export const promoteAllSuccess = createAction('MODEL/PROMOTE_ALL_SUCCESS');

export const setStateFilter = createAction<string>('MODEL/SET_STATE_FILTER');

export const expandCategory = createAction<string>('MODEL/EXPAND_CATEGORY');
export const collapseCategory = createAction<string>('MODEL/COLLAPSE_CATEGORY');
export const clearExpandedCategories = createAction('MODEL/CLEAN_EXPANDED_CATEGORIES');

export const fetchModelCategoriesDiff = createAction<Category[]>(
  'MODEL/FETCH_MODEL_CATEGORIES_DIFF'
);
export const fetchModelCategoriesDiffRequest = createAction(
  'MODEL/FETCH_MODEL_CATEGORIES_DIFF_REQUEST'
);
export const fetchModelCategoriesDiffSuccess = createAction<Record<string, RuleChange[]>>(
  'MODEL/FETCH_MODEL_CATEGORIES_DIFF_SUCCESS'
);
