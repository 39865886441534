import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk, ModeOfSpeech, MRuleConfigNode, NormalizedResource, UUID } from 'types';
import isSameSentenceApplicable from 'utils/configValidation';

export type MoveItemPayload = {
  newParentId: UUID;
  idToMove: UUID;
};
export const moveItem = createAction<MoveItemPayload>('CONFIG/MOVE_ITEM');

export const removeItem = createAction<UUID>('CONFIG/REMOVE_ITEM');

export type CreateNewGroupPayload = {
  parentId: UUID;
  futureNodeIndex?: number;
};
export const createNewGroup = createAction<CreateNewGroupPayload>('CONFIG/CREATE_NEW_GROUP');

export type UpdateModifierPayload = {
  id: UUID;
  modifiers?: { NOT?: boolean; DISABLE?: boolean; DISALLOW_CUSTOMER_UUIDS?: string[] };
};
export const updateModifier = createAction<UpdateModifierPayload>('CONFIG/UPDATE_MODIFIER');

export type MatchBodyOnlyPayload = {
  id: UUID;
  matchOnlyBody: boolean;
};
export const setMatchBodyOnly = createAction<MatchBodyOnlyPayload>('CONFIG/SET_MATCH_ONLY_BODY');

export type ChangeScopePayload = {
  nodeId: UUID;
  customerId: UUID;
  act: 'add' | 'remove';
};
export const changeScope = createAction<ChangeScopePayload>('CONFIG/CHANGE_SCOPE');

export type UpdateModeOfSpeechPayload = {
  id: UUID;
  mode: ModeOfSpeech;
};
export const updateModeOfSpeech = createAction<UpdateModeOfSpeechPayload>(
  'CONFIG/UPDATE_MODE_OF_SPEECH'
);

export type UpdateNegatedPayload = {
  id: UUID;
  negated: boolean | undefined;
};
export const updateNegated = createAction<UpdateNegatedPayload>('CONFIG/UPDATE_NEGATED');

export const patchSingleRuleConfig = createAction<MRuleConfigNode>(
  'CONFIG/PATCH_SINGLE_RULE_CONFIG'
);

export const cleanChangedNodes = createAction('CONFIG/CLEAN_CHANGED_NODES');

export const cleanChangedAndNotSelectedNodes = createAction(
  'CONFIG/CLEAN_CHANGED_NOT_SELECTED_NODES'
);

export type SelectItemPayload = {
  index: number | null;
};
export const selectItem = createAction<SelectItemPayload>('CONFIG/SELECT_ITEM');

export const selectCustomerConfig = createAction<string | null>('CONFIG/SELECT_CUSTOMER');

export const cleanLastChangedNode = createAction('CONFIG/CLEAN_LAST_CHANGED_NODE');

type ValidateAndMoveThunk = (payload: { idToMove: UUID; newParentId: UUID }) => AsyncThunk<boolean>;

// TODO: (find a more robust way to avoid this kind of invalid movements)
export const validateAndMove: ValidateAndMoveThunk =
  (payload) =>
  async (dispatch, getState): Promise<boolean> => {
    const { items } = getState().config;
    const { newParentId, idToMove } = payload;

    const itemToMove = items[idToMove];
    const oldParentId = itemToMove.parent ? items[itemToMove.parent].id : -1;
    const potentialTarget = items[newParentId];

    const isValid = (): boolean => {
      // Move node to same parent
      if (newParentId === oldParentId) {
        return false;
      }
      // Move node to it's child
      if (idToMove === items[newParentId].parent) {
        return false;
      }
      // Move group into a relationship
      if (!isSameSentenceApplicable(itemToMove, potentialTarget)) {
        return false;
      }
      // Move any node into a child
      if (potentialTarget.typeOfConfig === 'ANNOTATION_MATCH') {
        return false;
      }
      return true;
    };

    if (isValid()) {
      dispatch(moveItem({ idToMove, newParentId }));
      dispatch(selectItem({ index: null }));
      return true;
    }

    return false;
  };

export type CopyItemPayload = {
  idToCopy: string;
};
export const copyItem = createAction<CopyItemPayload>('CONFIG/COPY_ITEM');

export type PasteItemPayload = {
  targetNodeId: string;
  useIdentifierId?: boolean;
};
export const pasteItem = createAction<PasteItemPayload>('CONFIG/PASTE_ITEM');

export type LinkParentWithChildPayload = {
  parentId: string;
  childId: string;
};
export const linkParentWithChild = createAction<LinkParentWithChildPayload>(
  'CONFIG/LINK_PARENT_WITH_CHILD'
);
export const discardChanges = createAction('CONFIG/DISCARD_CHANGES');

export const addModifiedItem = createAction<NormalizedResource<MRuleConfigNode>>(
  'CONFIG/ADD_MODIFIED_ITEM'
);

export const setCompareMode = createAction<boolean>('CONFIG/TOGGLE_COMPARE_MODE');

export const cleanConfig = createAction('CONFIG/CLEAN_CONFIG');
