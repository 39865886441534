import LoadingIndicator from 'components/LoadingIndicator';
import SimpleModal from 'components/SimpleModal';
import React from 'react';

type ComponentProps = {
  title?: string;
  body?: JSX.Element | null;
  okButton?: boolean;
  okButtonText?: string;
  okButtonOnClick?: () => void;
  okButtonStyle?: string;
  okButtonDisabled?: boolean;
  cancelButton?: boolean;
  cancelButtonText?: string;
  cancelButtonOnclick?: () => void;
  toggleShowModal?: () => void;
  style?: { [key: string]: string };
  cancelButtonStyle?: string;
  prevButtonOnClick?: () => void;
  xButton?: boolean;
  secondaryButton?: boolean;
  secondaryButtonText?: string;
  secondaryButtonOnclick?: () => void;
  secondaryButtonStyle?: string;
  loading?: boolean;
};

const ModalComponent: React.FC<ComponentProps> = ({
  title,
  body,
  okButton,
  okButtonText,
  okButtonOnClick,
  okButtonStyle,
  okButtonDisabled = false,
  cancelButton = true,
  cancelButtonText,
  cancelButtonOnclick,
  cancelButtonStyle,
  toggleShowModal,
  style,
  prevButtonOnClick,
  xButton,
  secondaryButton,
  secondaryButtonText,
  secondaryButtonOnclick,
  secondaryButtonStyle,
  loading,
}) => (
  <SimpleModal
    title={title || 'Custom title'}
    toggle={toggleShowModal}
    style={style}
    prevButtonOnClick={prevButtonOnClick}
    xButton={xButton}
  >
    <div className="h-4/5">{body}</div>

    <div className="flex flex-row justify-between w-full">
      {secondaryButton && (
        <div className="flex w-full">
          <button
            type="button"
            data-testid="cancel-button"
            className={secondaryButtonStyle}
            onClick={secondaryButtonOnclick || toggleShowModal}
          >
            {secondaryButtonText || 'Delete'}
          </button>
        </div>
      )}
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="flex flex-row justify-end gap-3 w-full">
          {cancelButton && (
            <span className="flex rounded">
              <button
                type="button"
                data-testid="cancel-button"
                className={`button button--secondary flex justify-center font-bold w-37 h-8 text-center ${cancelButtonStyle}`}
                onClick={cancelButtonOnclick || toggleShowModal}
              >
                {cancelButtonText || 'Cancel'}
              </button>
            </span>
          )}
          {okButton && (
            <span className="flex rounded">
              <button
                type="button"
                data-testid="ok-button"
                className={`button button--primary flex justify-center w-37 h-8 ${okButtonStyle}`}
                onClick={okButtonOnClick}
                disabled={okButtonDisabled}
              >
                {okButtonText || 'Ok'}
              </button>
            </span>
          )}
        </div>
      )}
    </div>
  </SimpleModal>
);

export default ModalComponent;
