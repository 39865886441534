import EmptyTableState from 'components/EmptyTableState';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
  getCustomReportList,
  getCustomReports,
  getCustomReportsLoading,
  getCustomReportsTotalCount,
} from 'selectors/customReports';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import EditReportModal from '../EditReportModal';
import CustomReportListRow from './CustomReportListRow';

const CustomReportList: React.FC = () => {
  const loading = useSelector(getCustomReportsLoading);
  const customReports = useSelector(getCustomReportList);
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const toggleCreateModal = (): void => setIsCreatingReport(!isCreatingReport);

  const handleCreateNew = (): void => {
    setIsCreatingReport(true);
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row h-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="py-10 h-full overflow-auto">
          <div className="flex flex-row justify-between sm:px-6 lg:px-8">
            <div className="flex flex-row items-center">
              <h2 className="title">Digests</h2>
              {loading && <LoadingIndicator className="ml-2" size="5" />}
            </div>
            <div className="flex justify-end w-full">
              <div>
                <Permissions action="campaigns.create">
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      onClick={handleCreateNew}
                      type="button"
                      data-testid="create-report-button"
                      className="button button--primary"
                    >
                      Create New Report
                    </button>
                  </span>
                </Permissions>
              </div>
            </div>
          </div>
          <div className="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col text-left">
              <div className="relative -my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <LoadingOverlay
                  active={loading}
                  spinner={<LoadingIndicator size="10" />}
                  fadeSpeed={0}
                >
                  <div className="table-wrapper__new">
                    <table className="table-wrapper__table">
                      <thead>
                        <tr>
                          <FilterListHeaderRedesign
                            resource={resourceQueryParamName.customReport}
                            text="Type"
                            field="kind"
                            className="table-wrapper__new-th table-wrapper__th--w-15 pl-4"
                          />
                          <FilterListHeaderRedesign
                            resource={resourceQueryParamName.customReport}
                            text="User"
                            field="user.name"
                            className="table-wrapper__new-th"
                          />
                          <FilterListHeaderRedesign
                            resource={resourceQueryParamName.customReport}
                            text="Saved Search"
                            field="search.name"
                            className="table-wrapper__new-th"
                          />
                          <th
                            aria-label="Actions"
                            className="table-wrapper__new-th table-wrapper__th--w-15"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-wrapper__tbody">
                        {!loading && customReports.length === 0 && (
                          <EmptyTableState
                            data-testid="empty-table-state"
                            message="There are no reports to display"
                            colCount="4"
                          />
                        )}
                        {customReports.map((data) => (
                          <CustomReportListRow customReport={data} key={data.uuid} />
                        ))}
                      </tbody>
                    </table>
                    <ListFooter
                      resourceStateName={resourceQueryParamName.customReport}
                      resourceName="report"
                      getResourceList={getCustomReports}
                      getResourceTotalCount={getCustomReportsTotalCount}
                      getResourceLoading={getCustomReportsLoading}
                    />
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
        {isCreatingReport && (
          <EditReportModal
            isCreating
            isModalOpen={isCreatingReport}
            toggleModalOpen={toggleCreateModal}
          />
        )}
      </div>
    </div>
  );
};

export default CustomReportList;
