import { deleteCategory, upsertCategory } from 'actions';
import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';
import ToggleButton from 'components/ToggleButton';
import { ADJUSTMENT_ICON } from 'constants/envelopeIcons';
import platform, { additionalSettings } from 'constants/platform';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getCategoriesList, getFetchAllCategoriesLoading } from 'selectors/categories';
import { useSelector } from 'store';
import type { CampaignRuleOutcome, Category } from 'types';
import { useHistory } from 'utils/urls';
import RenderJsonEditorModal from './RenderCategoryJsonEditorModal';

type ComponentProps = {
  ruleGroupId: string;
  group: 'staging' | 'production';
};

const OutcomeTable: React.FC<ComponentProps> = ({ ruleGroupId, group }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<Category['config'] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const customerDomain = useSelector(getCustomerDomain);

  const categories = useSelector(getCategoriesList);
  const loading = useSelector(getFetchAllCategoriesLoading);

  const handleDelete = (category: Category): void => {
    dispatch(
      deleteCategory({
        id: category.uuid,
        outcome: true,
      })
    );
  };

  const handleToggleButton = (category: Category, key: string): void => {
    const newCategory = {
      ...category,
      config: { ...category.config, [key]: !category[key as keyof Category] },
    };

    dispatch(upsertCategory(newCategory));
  };

  const extendedPlatforms = { ...platform, ...additionalSettings };

  const handleAdvanced = (category: Category): void => {
    setCurrentConfig(category.config);
    setOpenModal(true);
    setCurrentCategory(category);
  };

  return (
    <>
      <div className={`${group === 'production' ? 'h-3/5' : 'h-2/5'} flex flex-col gap-2`}>
        <span className="text-heading-3">{capitalize(group)}</span>
        <div className="flex h-full table-wrapper__new no-scrollbar overflow-auto">
          <LoadingOverlay
            active={loading}
            spinner={<LoadingIndicator size="10" />}
            className="relative w-full h-full"
            fadeSpeed={0}
            styles={{
              content: (base) => ({
                ...base,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
              wrapper: () => ({}),
              spinner: () => ({}),
              overlay: (base) => ({
                ...base,
                zIndex: 10,
              }),
            }}
          >
            <table className="table-wrapper__table table-wrapper_table--fixed">
              <thead className="sticky top-0 z-50">
                <tr>
                  <th className="table-wrapper__th table-wrapper__th--w-16">Name</th>
                  <th className="table-wrapper__th table-wrapper__th--w-18">Recipient Type</th>
                  {Object.values(extendedPlatforms).map((value) => (
                    <th key={value} className="table-wrapper__th p-2">
                      {value}
                    </th>
                  ))}
                  <th aria-label="Action" className="table-wrapper__th table-wrapper__th--w-4" />
                  <th aria-label="Action" className="table-wrapper__th table-wrapper__th--w-4" />
                  <th aria-label="Action" className="table-wrapper__th table-wrapper__th--w-4" />
                </tr>
              </thead>
              <tbody className="table-wrapper__tbody">
                {categories.length === 0 && (
                  <EmptyTableState message="There are no outcomes to display" colCount="13" />
                )}

                {categories
                  /* @ts-ignore */
                  .filter((c) => c.rule_head.name === group)
                  .map((category) => (
                    <tr key={category.uuid} className="border-t border-gray-200 hover:bg-gray-100">
                      <td className="table-wrapper__td">
                        <div className="flex items-center">
                          <div className="">
                            <div className="text">{category.outcome?.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="table-wrapper__td">
                        <div className="flex items-center">
                          <div className="">
                            <div className="text">{category.outcome?.type}</div>
                          </div>
                        </div>
                      </td>
                      {Object.keys(extendedPlatforms).map((key) => (
                        <td key={key} className="table-wrapper__td p-0">
                          <div className="flex items-center">
                            <div className="">
                              <div className="text">
                                <ToggleButton
                                  dataTestid={`${key}-toggle-button`}
                                  // @ts-ignore
                                  isOn={
                                    category && category.config
                                      ? category.config[key as keyof CampaignRuleOutcome['config']]
                                      : false
                                  }
                                  handleToggleClick={(): void => {
                                    handleToggleButton(category, key);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      ))}

                      <td className="table-wrapper__td">
                        <div className="flex items-center justify-center">
                          {category.config?.human_verification_config != null ? (
                            <button
                              type="button"
                              onClick={(): void => {
                                handleAdvanced(category);
                              }}
                            >
                              {ADJUSTMENT_ICON}
                            </button>
                          ) : (
                            <span className="text text--italic">NA</span>
                          )}
                        </div>
                      </td>

                      <td className="table-wrapper__td">
                        <div className="flex items-center justify-center">
                          <button
                            onClick={(): void => {
                              history.pushLookup({
                                routeName: 'rule-group-outcome-id',
                                routeParams: {
                                  outcomeId: category.outcome_uuid,
                                  ruleId: ruleGroupId,
                                },
                                customerDomain,
                              });
                            }}
                            type="button"
                            className="flex items-center justify-center"
                          >
                            <svg
                              fill="none"
                              className="h-4 w-4 text-gray-700 hover:text-gray-900 text-xs"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                          </button>
                        </div>
                      </td>

                      <td className="table-wrapper__td">
                        <div className="flex items-center justify-center">
                          {categories.length > 1 && (
                            <button
                              type="button"
                              className={`flex items-center justify-center ${
                                group === 'staging'
                                  ? 'text-litlingo-gray-2'
                                  : 'text-litlingo-gray-5 hover:text-litlingo-gray-7'
                              }`}
                              onClick={(): void => handleDelete(category)}
                              disabled={group === 'staging'}
                            >
                              <svg
                                className="mr-1.5 h-4 w-4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </LoadingOverlay>
        </div>
      </div>
      {openModal && currentCategory && (
        <RenderJsonEditorModal
          setOpenModal={setOpenModal}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          category={currentCategory}
        />
      )}
    </>
  );
};

export default OutcomeTable;
