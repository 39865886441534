import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchInput from 'components/SearchInput';
import TagCount from 'components/TagsList/TagCount';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeleteTagLoading, getFetchAllTagsLoading, getUpsertTagLoading } from 'selectors/tags';
import CreateTagModal from './CreateTagModal';

const TagsHeader: React.FC = () => {
  const upsertLoading = useSelector(getUpsertTagLoading);
  const deleteLoading = useSelector(getDeleteTagLoading);
  const fetchLoading = useSelector(getFetchAllTagsLoading);

  const [isTagModalOpen, setIsTagModalOpen] = useState(false);

  const toggleTagModalOpen = (): void => {
    setIsTagModalOpen(!isTagModalOpen);
  };

  return (
    <header className="px-6">
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-2">
          <div
            role="button"
            tabIndex={0}
            className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
          >
            All Tags
          </div>
          <LinkLookup
            routeName="tag-groups"
            className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
          >
            Tag Groups
          </LinkLookup>
        </div>
        <div className="h-8 flex flex-row gap-2">
          <button
            type="button"
            className="button button--primary w-37 justify-center"
            onClick={toggleTagModalOpen}
          >
            New Tag
          </button>
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center mt-6">
          <h2 className="text-heading-2">All Tags</h2>
          {(upsertLoading || deleteLoading || fetchLoading) && (
            <LoadingIndicator className="ml-2" size="5" />
          )}
        </div>

        <div className="flex justify-between mt-2 h-8">
          <div className="flex gap-4 w-58">
            <SearchInput
              resource={resourceQueryParamName.tags}
              field="broad_search"
              placeholder="Search tags"
            />
          </div>
          <div className="self-end">
            <TagCount />
          </div>
        </div>
      </div>
      {isTagModalOpen && (
        <CreateTagModal
          tag={null}
          isModalOpen={isTagModalOpen}
          toggleModalOpen={toggleTagModalOpen}
        />
      )}
    </header>
  );
};

export default TagsHeader;
