import { ARROW_DOWN_ICON, ARROW_UP_ICON, MINUS_ICON, PLUS_ICON } from 'constants/filterIcons';
import React, { useState } from 'react';

type ComponentProps = {
  headerTitle: string;
  customLoading?: boolean;
  children: JSX.Element;
  isDefaultClosed?: boolean;
  dataTestid?: string;
  headerClasses?: string;
  titleClasses?: string;
  containerClasses?: string;
  contentClasses?: string;
  iconColorClass?: string;
  useArrowIcon?: boolean;
  extraAction?: JSX.Element | null;
};

const CollapsableSection: React.FC<ComponentProps> = ({
  headerTitle,
  customLoading,
  children,
  isDefaultClosed,
  dataTestid,
  headerClasses,
  titleClasses,
  containerClasses,
  contentClasses,
  iconColorClass,
  useArrowIcon = false,
  extraAction,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isDefaultClosed);

  const handleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  const renderIcon = (): JSX.Element => {
    if (isCollapsed) {
      if (useArrowIcon) {
        return ARROW_DOWN_ICON(iconColorClass);
      }
      return PLUS_ICON(iconColorClass);
    }
    if (useArrowIcon) {
      return ARROW_UP_ICON(iconColorClass);
    }
    return MINUS_ICON(iconColorClass);
  };

  return (
    <div data-testid={dataTestid || 'section'} className={`border-b ${containerClasses}`}>
      <div
        className={`${headerClasses || 'py-4 px-5 hover:bg-litlingo-gray-2'}`}
        role="button"
        onClick={handleCollapse}
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className={`${titleClasses || 'text-litlingo-heading-3'}`}>{headerTitle}</div>
        {customLoading && (
          <div className="ml-4 flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
        )}
        <div className="flex flex-row items-center gap-4">
          {extraAction}
          {renderIcon()}
        </div>
      </div>
      {!isCollapsed && (
        <div
          className={`${contentClasses || 'relative mb-2 px-4'}`}
          data-testid="collapsable-content"
        >
          <div
            className={`flex flex-col ${
              isCollapsed ? 'litlingo-collapsed opacity-0' : 'opacity-100'
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default CollapsableSection;
