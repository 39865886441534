import type { AuthClient } from '@auth';
import { Client, Resource } from '@base';
import type { APIClientSpec, APIClientSpecResources } from '@types';
import { serializer } from '@utils';
import type { InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

type APIClientConfig = {
  logs: boolean;
  sentry: boolean;
};

type Resources<S extends APIClientSpecResources> = {
  [R in keyof S]: Resource<S[R]>;
};

class APIClient<S extends APIClientSpec> extends Client {
  private _auth: AuthClient;

  resources: Resources<S['resources']>;

  constructor(
    spec: S,
    auth: AuthClient,
    config: Partial<APIClientConfig> = {}
  ) {
    super(
      {
        baseURL: spec.base,
        headers: spec.headers,
        paramsSerializer: serializer,
        responseType: 'json',
        withCredentials: true,
      },
      {
        logs: config.logs ?? spec.logs ?? false,
        sentry: config.sentry ?? false,
      }
    );

    this._client.interceptors.request.use(this._headers.bind(this));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird build fail
    axiosRetry(this._client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) =>
        axiosRetry.isNetworkOrIdempotentRequestError(error),
    });

    this._auth = auth;

    this.resources = {} as Resources<S['resources']>;
    Object.entries(spec.resources).forEach(([resource, resourceSpec]) => {
      const name = resource as keyof S['resources'];

      this.resources[name] = new Resource(this, resource, resourceSpec);
    });
  }

  private async _headers(
    request: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    const headers = await this._auth.getAPIClientHeaders();
    Object.entries(headers).forEach(([key, value]) => {
      request.headers[key] = value;
    });

    return request;
  }
}

export default APIClient;
