/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import { deleteTagGroup } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDeleteTagGroupLoading } from 'selectors/tagGroup';
import { useSelector } from 'store';

type ComponentProps = {
  selectedTagGroups: UUID[];
  setSelectedTagGroups: React.Dispatch<React.SetStateAction<string[]>>;
  toggleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagGroupListActionRow: React.FC<ComponentProps> = ({
  selectedTagGroups,
  setSelectedTagGroups,
  toggleModalOpen,
}) => {
  const dispatch = useDispatch();
  const [isDeletingTagGroup, setIsDeletingTagGroup] = useState(false);
  const deleteLoading = useSelector(getDeleteTagGroupLoading);

  const toggleConfirmModal = (): void => setIsDeletingTagGroup(!isDeletingTagGroup);

  const handleDeleteClick = (): void => {
    if (selectedTagGroups.length > 0) {
      selectedTagGroups.forEach((tg) => {
        dispatch(deleteTagGroup({ id: tg }));
      });
      toggleConfirmModal();
      setSelectedTagGroups([]);
    }
  };

  return (
    <>
      <tr className="bg-litlingo-gray-2">
        <th
          colSpan={6}
          className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2"
        >
          <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
            <div className="w-full pl-6 pr-4 flex flex-row justify-between items-start gap-2">
              <button
                type="button"
                disabled={selectedTagGroups.length !== 1}
                onClick={(): void => toggleModalOpen(true)}
                className="button button--secondary font-bold h-8 w-24 flex justify-center"
              >
                Edit
              </button>
              <button
                type="button"
                disabled={selectedTagGroups.length === 0}
                onClick={(): void => toggleConfirmModal()}
                className="button button--secondary font-bold h-8 w-24 flex justify-center"
              >
                {deleteLoading ? <LoadingIndicator size="5" /> : 'Delete'}
              </button>
            </div>
          </div>
        </th>
      </tr>
      {isDeletingTagGroup && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this tag group?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteClick}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default TagGroupListActionRow;
