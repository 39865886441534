import LoadingIndicator from 'components/LoadingIndicator';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';

import { clearURLParams, saveCustomer } from 'actions';
import DivisionsFilter from 'components/Filters/DivisionsFilter';
import PromptsFilter from 'components/Filters/PromptsFilter';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPrompts } from 'selectors/entities';
import { getNavParamsByResource } from 'selectors/nav';
import { getActiveTasksLoading } from 'selectors/tasks';

const EntityHeader: React.FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector(getActiveTasksLoading);
  const user = useSelector(getUser);
  const promptFilter = useSelector(getNavParamsByResource('entities')).prompt_uuid as string;
  const teamsFilter = (useSelector(getNavParamsByResource('entities')).team_uuids ||
    []) as string[];
  const prompts = useSelector(getPrompts);

  const selectedPrompt = prompts.find((p) => p.uuid === promptFilter);
  // @ts-ignore
  const isOnEntityExtractConfig = user?.customer?.config?.entity_extraction_config?.some(
    // @ts-ignore
    (ec) => ec.prompt_uuid === selectedPrompt?.uuid
  );

  const handleAddRemoveEntityExtract = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      dispatch(
        saveCustomer({
          ...user?.customer,
          config: {
            ...user?.customer?.config,
            // @ts-ignore
            entity_extraction_config: [
              // @ts-ignore
              ...user?.customer?.config.entity_extraction_config.filter(
                // @ts-ignore
                (ec) => ec.prompt_uuid === selectedPrompt?.uuid
              ),
              {
                prompt_uuid: selectedPrompt?.uuid,
                // @ts-ignore
                entity_kind: selectedPrompt?.entity_kind,
                sample_size: 100,
              },
            ],
          },
        })
      );
    } else {
      dispatch(
        saveCustomer({
          ...user?.customer,
          config: {
            ...user?.customer?.config,
            // @ts-ignore
            entity_extraction_config: [
              // @ts-ignore
              ...user?.customer?.config.entity_extraction_config.filter(
                // @ts-ignore
                (ec) => ec.prompt_uuid !== selectedPrompt?.uuid
              ),
            ],
          },
        })
      );
    }
  };

  const isFiltering = promptFilter || teamsFilter.length > 0;

  return (
    <div className="flex flex-col gap-2 pt-6 px-10">
      <div className="flex flex-row items-center">
        <h2 className="text-heading-2">
          {
            // @ts-ignore
            selectedPrompt ? `Entities (${selectedPrompt.name})` : 'Entities'
          }
        </h2>
        {loading && <LoadingIndicator className="ml-2" size="5" />}
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-end justify-between gap-2.5">
          <SearchInput
            resource={resourceQueryParamName.entities}
            field="name"
            logEventMessage="entity-search"
            className="h-8 max-w-60"
            placeholder="Search entities"
          />
          <DivisionsFilter resource="entities" />
          <button
            type="button"
            className={`text-litlingo-gray-4 ${
              isFiltering ? 'hover:underline' : 'cursor-default'
            } focus:outline-none`}
            onClick={(): void => {
              dispatch(clearURLParams());
            }}
            disabled={!isFiltering}
          >
            Clear All
          </button>
          {selectedPrompt && (
            <label htmlFor="add-remove-entity" className="flex gap-2 items-center">
              <input
                id="add-remove-entity"
                type="checkbox"
                onChange={handleAddRemoveEntityExtract}
                className="form-checkbox litlingo-checkbox"
                checked={isOnEntityExtractConfig}
              />
              <span>Entity extract</span>
            </label>
          )}
        </div>
        <PromptsFilter resource="entities" />
      </div>
    </div>
  );
};

export default EntityHeader;
