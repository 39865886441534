import { fetchEventBodyComm } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { getRawCorrespondence } from 'selectors/events';
import type { UUID } from 'types';
import parseBody from 'utils/strings';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
};

const RawEmail: React.FC<ComponentProps> = ({ eventId, commUuid }) => {
  const dispatch = useDispatch();
  const rawCorrespondence = useSelector(getRawCorrespondence(eventId));

  const newLineWord = 'From:';
  const wordRegex = new RegExp(`${newLineWord}`, 'g');
  const replaceString = `&nbsp;\r\n___\r\n&nbsp;`;
  const finalRawCorrespondence = rawCorrespondence?.replace(
    wordRegex,
    `${replaceString}
    ${newLineWord}`
  );

  useEffect(() => {
    if (eventId && commUuid && !rawCorrespondence) {
      dispatch(fetchEventBodyComm({ eventId, commUuid }));
    }
  }, [commUuid, dispatch, eventId, rawCorrespondence]);

  const addLineBreaks = (text: string | undefined, keyWords: string[]): string => {
    if (!text) return '';

    const regex = new RegExp(`\\b(${keyWords.join('|')})\\b`, 'g');
    const words = text.split(regex);

    let newText = words.reduce((acc, word, index) => {
      if (index % 2 === 1) {
        return `${acc}\r\n${word}`;
      }
      return acc + word;
    }, '');

    const subjectRegex = /Subject:(.*)/g;
    const subjectMatches = newText.match(subjectRegex);
    if (subjectMatches) {
      subjectMatches.forEach((match) => {
        newText = newText.replace(match, `${match} &nbsp;\r\n\r\n&nbsp;`);
      });
      return newText;
    }
    return newText;
  };

  const sliceRaw = (): string => {
    let result = addLineBreaks(finalRawCorrespondence, ['From', 'Sent', 'To', 'Subject']);

    if (result) {
      if (result[0] === '"') {
        result = result.substring(1);
      }
      if (result[result.length - 1] === '"') {
        result = result.substring(0, result.length - 1);
      }
      return result;
    }

    return '';
  };

  return (
    <div className="flex flex-row flex-wrap items-end">
      {rawCorrespondence || rawCorrespondence === '' ? (
        <ReactMarkdown
          className="w-full px-7 pt-7 pb-2"
          renderers={{
            link: (props) => (
              <span className="text-black ">
                {'<'}
                {props.children}
                {'>'}
              </span>
            ),
          }}
        >
          {parseBody(sliceRaw())}
        </ReactMarkdown>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default RawEmail;
