import { MajorChange } from 'types';

export const ChangesSummaryMap: Record<MajorChange['type'], string> = {
  added_branch: 'branch added',
  added_identifier: 'new identifier',
  added_relationship: 'new relationship',
  changed_logic: 'logic change',
  changed_relationship: 'relationship changed',
  removed_branch: 'branch removed',
  removed_identifier: 'removed identifier',
  removed_relationship: 'removed relationship',
};

export const typeOptionsSort = {
  production: 3,
  development: 2,
  testing: 1,
};

export const typeOptionsMap = {
  production: 'Production',
  development: 'Development',
  testing: 'Testing',
  remove: 'Remove group',
};

export const typeOptionsColorMap = {
  production: {
    ellipse: '#F8351A',
    className: 'bg-litlingo-alert-light border border-litlingo-alert-medium',
  },
  development: {
    ellipse: '#F8A01A',
    className: 'bg-litlingo-secondary-60 border border-litlingo-secondary-100 ',
  },
  testing: {
    ellipse: '#16B126',
    className: 'bg-litlingo-gray-0.5 border border-litlingo-gray-2',
  },
};

export default typeOptionsMap;
