/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */

import { upsertTag, upsertTagAndAddToGroup } from 'actions';
import Modal from 'components/Modal';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { useSelector } from 'store';
import { Tag, TagGroup } from 'types';

type ComponentProps = {
  tag: Tag | null;
  tagGroup?: TagGroup;
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const typeOptions = [
  {
    label: 'Internal',
    value: 'modeler',
  },
  {
    label: 'External',
    value: 'human',
  },
];

const CreateTagModal: React.FC<ComponentProps> = ({
  tag,
  tagGroup,
  isModalOpen,
  toggleModalOpen,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const isAdmin = user.roles.includes('super-admin');

  let defaultTenant = isAdmin ? 'modeler' : 'external';
  if (tagGroup) {
    // @ts-ignore
    defaultTenant = tagGroup.tenant_name;
  }

  const [tenant, setTenant] = useState<string>(defaultTenant);
  const [errors, setErrors] = useState<Record<string, string>>();

  useEffect(() => {
    if (tag) {
      // @ts-ignore
      setTenant(tag.type);
    }
  }, [tag]);

  const { register, handleSubmit, watch, setValue, reset } = useForm<{
    value: string;
    description: string;
    color: string;
  }>({
    defaultValues: {
      value: tag?.value || '',
      description: tag?.description || '',
      color:
        tag?.color ||
        `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0')}`,
    },
  });

  const onSubmit = (
    data: {
      value: string;
      description: string;
      color: string;
    },
    next?: boolean
  ): void => {
    const { value, description, color } = data;

    if (tenant.length === 0) {
      setErrors({ tenant: 'You must select a type' });
      return;
    }

    setErrors({});

    if (tagGroup) {
      dispatch(
        upsertTagAndAddToGroup({
          ...(tag ? { uuid: tag.uuid } : {}),
          value,
          description,
          type: tenant,
          color,
          tagGroupId: tagGroup.uuid,
        })
      );
    } else {
      dispatch(
        upsertTag({
          ...(tag ? { uuid: tag.uuid } : {}),
          value,
          description,
          type: tenant,
          color,
        })
      );
    }

    if (next) {
      reset();
    } else {
      toggleModalOpen();
    }
  };

  const handleSaveButton = (): void => {
    handleSubmit((data) => onSubmit(data, false))();
  };

  const handleSaveAndNext = (): void => {
    handleSubmit((data) => onSubmit(data, true))();
  };

  const handleRandomColor = (): void => {
    setValue(
      'color',
      `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`
    ); // ✅ performant
  };

  const renderCreateTagGroup = (
    <div className="pt-1 pl-2 pb-8 flex flex-col gap-4">
      <span className="text-heading-1">{tag?.value ? 'Edit tag' : 'New tag'}</span>
      <form className="flex flex-col items-start">
        <div className="w-full flex flex-col items-start gap-2">
          <div className="w-full flex flex-row gap-4">
            <label htmlFor="name" className="flex flex-col flex-1 gap-1 text-body">
              <div className="w-full flex flex-row gap-1">
                <span>Name</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <input
                id="value"
                ref={register({ required: true, pattern: /\S/i })}
                name="value"
                placeholder="Tag name"
                className="form-input h-9  p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            <div className="w-44 flex flex-col gap-1 text-body">
              <div className="flex flex-row gap-1">
                <span>Type</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <Permissions action="tags.seeTenancy">
                <div className="flex-shrink-0 w-44">
                  <SelectRedesign
                    onChange={(value: string): void => {
                      setTenant(value);
                    }}
                    options={typeOptions}
                    value={tenant}
                    placeholder="Select Type"
                    dataTestid="view-select"
                    className="h-9"
                    disabled={!!tagGroup}
                  />
                  <div className="text-small">
                    <span className="text-litlingo-alert">{errors?.tenant}</span>
                  </div>
                </div>
              </Permissions>
            </div>
          </div>
          <label htmlFor="description" className="w-full flex flex-col gap-1 text-body">
            <div className="flex flex-row gap-1">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={2}
              placeholder="A short description that can help others understand the purpose of this tag."
              className="form-input p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <div className="flex flex-col gap-1 mt-2 text-body w-full">
            <div className="flex flex-row gap-1">
              <span>Show on coding panel</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <div className="flex flex-row gap-2.5 items-center">
              <div className="flex flex-row items-center relative">
                <div
                  className="absolute rounded-full w-4 h-4 right-2"
                  style={{ backgroundColor: `${watch('color')}` }}
                />
                <input
                  id="color"
                  name="color"
                  ref={register}
                  className="form-input h-9 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
                />
              </div>
              <button
                type="button"
                onClick={handleRandomColor}
                className="button button--secondary w-37 h-8 justify-center items-center font-bold"
              >
                Shuffle color
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderCreateTagGroup}
          title=" "
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          cancelButtonText="Save & Next"
          cancelButtonOnclick={handleSaveAndNext}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37rem' }}
        />
      )}
    </>
  );
};

export default CreateTagModal;
