import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import CreateTagModal from 'components/TagsManager/CreateTagModal';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllTagsLoading, getTags, getTagsTotalCount } from 'selectors/tags';
import type { Tag, TagGroup, UUID } from 'types';
import TagListActionRow from './TagListActionRow';
import TagListItem from './TagListItem';

interface Props {
  tagGroup?: TagGroup;
  loading: boolean;
  tags: Tag[];
}

const TagsList: React.VFC<Props> = ({ tags, loading, tagGroup }) => {
  const [selectedTags, setSelectedTags] = useState<UUID[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditModal = (): void => setIsEditing(!isEditing);

  return (
    <>
      <div className="table-wrapper__new custom-scrollbar overflow-auto">
        <LoadingOverlay
          active={loading}
          spinner={<LoadingIndicator size="10" />}
          fadeSpeed={0}
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: (base) => ({
              ...base,
              height: '100%',
            }),
            spinner: () => ({}),
            overlay: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <table className="table-wrapper__table table-wrapper_table--fixed">
            <thead className="sticky top-0 z-10">
              <tr>
                <th className="table-wrapper__new-th py-0 w-13">
                  <div className="w-full pl-6 flex justify-start">
                    <input
                      id="checkbox-tag-groups"
                      data-testid="checkbox"
                      type="checkbox"
                      className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                        selectedTags?.length > 0 && selectedTags?.length < tags.length
                          ? 'checkbox-select-all-partial'
                          : ''
                      }`}
                      onClick={(e): void => e.stopPropagation()}
                      onChange={(): void => {
                        if (selectedTags.length === tags.length) {
                          setSelectedTags([]);
                        } else {
                          setSelectedTags(tags.map((t) => t.uuid));
                        }
                      }}
                      checked={selectedTags?.length === tags.length && selectedTags?.length > 0}
                    />
                  </div>
                </th>
                <th className="table-wrapper__new-th table-wrapper__th--w-22">Name</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-12">Details</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-12 leading-3">Usage</th>
                <th className="table-wrapper__new-th">Description</th>
              </tr>
              {selectedTags?.length >= 1 && (
                <TagListActionRow
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  toggleModalOpen={toggleEditModal}
                  tagGroup={tagGroup}
                />
              )}
            </thead>
            <tbody className="table-wrapper__tbody">
              {tags.length === 0 ? (
                <EmptyTableState message="There are no tag groups to display" colCount="5" />
              ) : (
                tags.map((tag) => (
                  <TagListItem
                    key={tag.uuid}
                    tag={tag}
                    selected={selectedTags.includes(tag.uuid)}
                    setSelectedTags={setSelectedTags}
                  />
                ))
              )}
            </tbody>
          </table>
          <div className="border-t border-gray-200 sticky bottom-0 bg-white">
            <ListFooter
              resourceStateName={resourceQueryParamName.tags}
              resourceName="tag"
              getResourceList={getTags}
              getResourceTotalCount={getTagsTotalCount}
              getResourceLoading={getAllTagsLoading}
            />
          </div>
        </LoadingOverlay>
      </div>
      {isEditing && (
        <CreateTagModal
          tag={tags.find((t) => selectedTags.includes(t.uuid)) || null}
          tagGroup={tagGroup}
          isModalOpen={isEditing}
          toggleModalOpen={toggleEditModal}
        />
      )}
    </>
  );
};

export default TagsList;
