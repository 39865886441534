import { UUID } from '@litlingo/client';
import { bulkUpsertKindModel, cloneModel } from 'actions/models';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModelBulkActionDropdown from './ModelBulkActionDropdown';

type ComponentProps = {
  selectedModels: UUID[];
  setSelectedModels: React.Dispatch<React.SetStateAction<string[]>>;
};

const ModelListActionsRow: React.FC<ComponentProps> = ({ selectedModels, setSelectedModels }) => {
  const dispatch = useDispatch();

  const [group, setGroup] = useState<string | undefined>(undefined);

  const modelCount = selectedModels.length;
  const disableClone = modelCount !== 1;

  const handleApplyChanges = (): void => {
    if (group) {
      dispatch(
        bulkUpsertKindModel({
          modelIds: selectedModels,
          group: group === 'remove' ? '' : group,
        })
      );
      setSelectedModels([]);
    }
  };

  const handleCloneModel = (): void => {
    dispatch(cloneModel({ modelId: selectedModels[0], redirect: true }));
  };

  return (
    <tr className="bg-litlingo-gray-2">
      <th
        colSpan={6}
        className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
      >
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible w-full">
          <div className="flex flex-row justify-between items-start gap-3 m-0 h-auto overflow-visible w-full">
            <div className="flex justify-between items-start gap-2 ml-6 h-auto overflow-visible">
              <ModelBulkActionDropdown setGroup={setGroup} group={group} />
              <button
                type="button"
                disabled={disableClone}
                onClick={handleCloneModel}
                className={classNames('button button--secondary h-8 ', {
                  'disabled-button': disableClone,
                })}
              >
                <span className="text-litlingo-gray-600 font-bold whitespace-no-wrap">
                  Duplicate
                </span>
              </button>
            </div>

            <div className="flex flex-row w-full justify-end h-8">
              <div className="text-body whitespace-no-wrap self-end mx-2">{`${modelCount.toLocaleString()} selected`}</div>
              <button
                onClick={(): void => handleApplyChanges()}
                type="submit"
                className="flex justify-center self-end w-32 h-8 box-border py-1.5 ml-2 button button--secondary border-litlingo-gray-100 text-body focus:outline-none"
                data-testid="save-button"
                disabled={!selectedModels || !group}
              >
                <span className="text-litlingo-gray-600 font-bold">Apply</span>
              </button>
            </div>
          </div>
        </div>
      </th>
    </tr>
  );
};

export const MemoizedModelListActionsRow = React.memo(ModelListActionsRow);

export default ModelListActionsRow;
