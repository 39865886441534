/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */

import { upsertTagGroup } from 'actions';
import Modal from 'components/Modal';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { useSelector } from 'store';
import { TagGroup } from 'types';

type ComponentProps = {
  tagGroup: TagGroup | null;
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const CreateTagGroupModal: React.FC<ComponentProps> = ({
  tagGroup,
  isModalOpen,
  toggleModalOpen,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const isAdmin = user.roles.includes('super-admin');

  // @ts-ignore
  const [codingPanel, setCodingPanel] = useState(tagGroup?.on_coding_panel);
  const [tenant, setTenant] = useState<string>(isAdmin ? 'modeler' : 'external');
  const [errors, setErrors] = useState<Record<string, string>>();

  useEffect(() => {
    if (tagGroup) {
      // @ts-ignore
      setTenant(tagGroup.tenant_name);
    }
  }, [tagGroup]);

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    description: string;
  }>({
    defaultValues: {
      name: tagGroup?.name || '',
      description: tagGroup?.description || '',
    },
  });

  const onSubmit = (): void => {
    const { name, description } = getValues();

    if (tenant.length === 0) {
      setErrors({ tenant: 'You must select a type' });
      return;
    }

    setErrors({});

    dispatch(
      upsertTagGroup({
        ...(tagGroup ? { uuid: tagGroup.uuid } : {}),
        name,
        description,
        // @ts-ignore
        tenant_name: tenant,
        on_coding_panel: codingPanel,
      })
    );

    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const options = [
    {
      label: 'Internal',
      value: 'modeler',
    },
    {
      label: 'External',
      value: 'human',
    },
  ];

  const renderCreateTagGroup = (
    <div className="pt-1 pl-2 pb-8 flex flex-col gap-4">
      <span className="text-heading-1">{tagGroup?.name ? 'Edit tag group' : 'New tag group'}</span>
      <form className="flex flex-col items-start">
        <div className="w-full flex flex-col items-start gap-2">
          <div className="w-full flex flex-row gap-4">
            <label htmlFor="name" className="flex flex-col flex-1 gap-1 text-body">
              <div className="w-full flex flex-row gap-1">
                <span>Name</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <input
                id="name"
                ref={register({ required: true, pattern: /\S/i })}
                name="name"
                placeholder="Tag group name"
                className="form-input h-9  p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            <div className="w-44 flex flex-col gap-1 text-body">
              <div className="flex flex-row gap-1">
                <span>Type</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <Permissions action="tags.seeTenancy">
                <div className="flex-shrink-0 w-44">
                  <SelectRedesign
                    onChange={(value: string): void => {
                      setTenant(value);
                    }}
                    options={options}
                    value={tenant}
                    placeholder="Select Type"
                    dataTestid="view-select"
                    className="h-9"
                  />
                  <div className="text-small">
                    <span className="text-litlingo-alert">{errors?.tenant}</span>
                  </div>
                </div>
              </Permissions>
            </div>
          </div>
          <label htmlFor="description" className="w-full flex flex-col gap-1 text-body">
            <div className="flex flex-row gap-1">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={2}
              placeholder="A short description that can help others understand the purpose of this tag group."
              className="form-input p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <div className="flex flex-col gap-1 mt-2 text-body w-full">
            <div className="flex flex-row gap-1">
              <span>Show on coding panel</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex flex-row gap-14 items-center">
              <label htmlFor="radio-no" className="flex flex-row gap-1 items-center">
                <input
                  type="radio"
                  id="radio-no"
                  name="no"
                  value="false"
                  className="input-radio"
                  checked={!codingPanel}
                  onChange={(): void => setCodingPanel(false)}
                />
                <span className="text-xss">No</span>
              </label>
              <label htmlFor="radio-yes" className="flex flex-row gap-1 items-center">
                <input
                  type="radio"
                  id="radio-yes"
                  name="yes"
                  value="true"
                  className="input-radio"
                  checked={codingPanel}
                  onChange={(): void => setCodingPanel(true)}
                />
                <span className="text-xss">Yes</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderCreateTagGroup}
          title=" "
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37rem' }}
        />
      )}
    </>
  );
};

export default CreateTagGroupModal;
