import { UUID } from '@litlingo/client';
import { SEARCH_ICON } from 'constants/filterIcons';
import React from 'react';
import {
  getChangedLanguageMatchers,
  getCompareMode,
  getShouldDisableEdit,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { MLanguageMatcher } from 'types';
import LanguageMatcherSidebarItem from './LanguageMatcherSidebarItem';

type ComponentProps = {
  languageMatchers: MLanguageMatcher[];
  languageMatcherFilter: string;
  highlightedLanguageMatcher: string | undefined;
  setLanguageMatcherFilter: React.Dispatch<React.SetStateAction<string>>;
  addLocalLanguageMatcher: () => void;
  handleLanguageMatcherClick: (languageMatcherId: UUID) => void;
};

const LanguageMatcherSidebarList: React.FC<ComponentProps> = ({
  languageMatchers,
  languageMatcherFilter,
  highlightedLanguageMatcher,
  setLanguageMatcherFilter,
  addLocalLanguageMatcher,
  handleLanguageMatcherClick,
}) => {
  const changedLanguageMatchers = useSelector(getChangedLanguageMatchers);
  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisableEdit);

  const handleFilterChange = (value: string): void => {
    setLanguageMatcherFilter(value);
  };

  return (
    <div className="w-full flex flex-col relative">
      <div className="px-4 py-2 flex flex-row gap-2">
        <div className="relative w-104">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <div className="absolute left-0 ml-1 w-5">{SEARCH_ICON()}</div>
          </div>
          <input
            id="search"
            name="search"
            className="form-input pl-7 pr-6 py-1.5 h-8 w-full text-litlingo-gray-6 text-body placeholder-italic placeholder-litlingo-gray-6"
            placeholder="Search Matchers and Terms"
            onChange={(event): void => {
              handleFilterChange(event.target.value);
            }}
            value={languageMatcherFilter}
            autoComplete="off"
            data-testid="search-input"
          />
          {languageMatcherFilter && (
            <button
              type="button"
              className="absolute right-6 inset-y-0 flex items-center"
              onClick={(): void => setLanguageMatcherFilter('')}
            >
              <div className="absolute left-0 ml-1 w-5">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.67969 1.68018L10.4157 10.4174"
                    stroke="#5C5C5C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4157 1.68018L1.67969 10.4174"
                    stroke="#5C5C5C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
          )}
        </div>
        <button
          type="button"
          onClick={addLocalLanguageMatcher}
          className="button button--secondary h-8"
          data-testid="add-matcher-button"
          disabled={compareMode || shouldDisable}
        >
          <span className="font-bold">Add</span>
        </button>
      </div>
      <div className="w-full border-b border-litlingo-gray-1" />
      <div className="flex flex-col w-full">
        {compareMode && languageMatchers.length > 0 && (
          <div className="relative flex flex-col w-full">
            {languageMatchers
              .filter((l) => changedLanguageMatchers?.includes(l.uuid))
              .map((languageMatcher) => (
                <LanguageMatcherSidebarItem
                  key={languageMatcher.uuid}
                  languageMatcher={languageMatcher}
                  handleLanguageMatcherClick={handleLanguageMatcherClick}
                  highlightedLanguageMatcher={highlightedLanguageMatcher}
                />
              ))}
          </div>
        )}
        {compareMode && changedLanguageMatchers?.length > 0 && (
          <div className="w-full h-6 py-0.5 flex flex-row justify-center items-center gap-1 text-small bg-litlingo-highlight">
            <span>Changes made above</span>
          </div>
        )}
        <div className="relative flex flex-col w-full">
          {languageMatchers.length > 0 ? (
            languageMatchers
              .filter((l) => !changedLanguageMatchers?.includes(l.uuid) || !compareMode)
              .map((languageMatcher) => (
                <LanguageMatcherSidebarItem
                  key={languageMatcher.uuid}
                  languageMatcher={languageMatcher}
                  handleLanguageMatcherClick={handleLanguageMatcherClick}
                  highlightedLanguageMatcher={highlightedLanguageMatcher}
                />
              ))
          ) : (
            <div className="flex justify-center py-6">
              <span className="text-sm font-semibold">No language matchers to show</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageMatcherSidebarList;
