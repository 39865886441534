/* eslint-disable max-lines */

import { Category, Customer } from '@litlingo/client';
import { collapseCategory, expandCategory, selectCustomerAndRedirect } from 'actions';
import LinkLookup from 'components/LinkLookup';
import RenderCategoryJsonEditorModal from 'components/OutcomeManagerStarship/OutcomeTable/RenderCategoryJsonEditorModal';
import { ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import { SETTINGS_ICON_GRAY } from 'constants/headerMenuIcons';
import platform from 'constants/platform';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getExpandedCategories, getStateFilter } from 'selectors/models';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { printStringArray } from 'utils/strings';
import { groupedCategoryByRule } from '.';

type ComponentProps = {
  ruleCategory: groupedCategoryByRule;
  customer: Customer;
};

const CustomerListItem: React.FC<ComponentProps> = ({ ruleCategory, customer }) => {
  const dispatch = useDispatch();

  const stateFilter = useSelector(getStateFilter);
  const expandedCategories = useSelector(getExpandedCategories);

  const [expandedCustomer, setExpandedCustomer] = useState(
    expandedCategories.includes(customer.uuid)
  );
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<Category['config'] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const categories = ruleCategory.categories.filter((cat) => cat.customer_uuid === customer.uuid);
  const outcomes = categories.map((cat) => cat.outcome);

  const handleAdvanced = (category: Category): void => {
    setCurrentConfig(category.config);
    setOpenModal(true);
    setCurrentCategory(category);
  };

  const handleRedirectOutcomes = (): void => {
    const { domain } = customer;

    if (domain) {
      dispatch(
        selectCustomerAndRedirect({
          domain,
          redirect: {
            routeName: 'rule-group-outcome',
            routeParams: {
              ruleId: ruleCategory.uuid,
            },
            customerDomain: domain,
          },
        })
      );
    }
  };

  const expandedRule = (): JSX.Element[] =>
    categories
      .filter((c) => {
        if (!stateFilter) return true;
        if (
          stateFilter === 'staging' &&
          // @ts-ignore
          c.tenant_name !== 'modeler'
        ) {
          return false;
        }
        if (
          stateFilter === 'production' &&
          // @ts-ignore
          c.tenant_name !== 'human'
        ) {
          return false;
        }
        return true;
      })
      .map((category) => (
        <LinkLookup
          routeName="global-rule-group-manager"
          routeParams={{ ruleId: category.rule?.rule_group_uuid || '' }}
          className="relative border-t border-litlingo-gray-1 bg-litlingo-gray-2 hover:bg-litlingo-gray-3 table-row"
          onClick={(): Promise<void> => logEvent('single-use-case')}
          as="tr"
          key={category.uuid}
        >
          <td className="table-wrapper__new-td" />
          <td className="table-wrapper__new-td pl-5 h-12">
            <div className="flex items-center text-body">
              <div className="overflow-hidden">
                <div className="truncate">
                  {category.outcome?.name}
                  {/* @ts-ignore */}
                  {category.tenant_name === 'modeler' ? ' (Staging)' : ''}
                </div>
              </div>
            </div>
          </td>
          <td className="table-wrapper__new-td pr-1 py-1">
            <div className="text-body">
              {category.active_platforms &&
                printStringArray(
                  Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                    if (category[key as keyof typeof category]) {
                      return [...acc, value];
                    }
                    return acc;
                  }, [])
                )}
            </div>
          </td>

          <td className="table-wrapper__new-td" colSpan={3}>
            {categories.length > 0 && (
              <div className="flex gap-1 items-center justify-end pr-8">
                {category.config?.human_verification_config != null && (
                  <button
                    type="button"
                    className="button button--secondary focus:outline-none w-5.5 h-5.5 p-0.5"
                    data-testid="advanced-button"
                    onClick={(e): void => {
                      e.stopPropagation();
                      handleAdvanced(category);
                    }}
                  >
                    {SETTINGS_ICON_GRAY}
                  </button>
                )}
              </div>
            )}
          </td>
        </LinkLookup>
      ));

  return (
    <>
      <LinkLookup
        routeName="global-rule-group-manager"
        routeParams={{ ruleId: ruleCategory.categories[0].rule?.rule_group_uuid || '' }}
        className="border-t border-litlingo-gray-1 bg-litlingo-gray-0.5 hover:bg-litlingo-gray-1 table-row"
        onClick={(): Promise<void> => logEvent('single-use-case')}
        as="tr"
      >
        <td className="table-wrapper__new-td h-12" />
        <td className="table-wrapper__new-td h-12">
          <div
            className={`flex items-center gap-1 text-body pr-4 ${
              categories.length > 0 ? '' : 'pl-5'
            }`}
          >
            {categories.length > 0 && (
              <div className="flex justify-center h-full">
                <button
                  className="flex justify-center items-center w-full h-full focus:outline-none"
                  type="button"
                  data-testid="expand-button"
                  onClick={(e): void => {
                    e.stopPropagation();
                    if (expandedCustomer) {
                      dispatch(collapseCategory(customer.uuid));
                    } else {
                      dispatch(expandCategory(customer.uuid));
                    }
                    setExpandedCustomer(!expandedCustomer);
                  }}
                >
                  <span
                    className={`py-1.5 focus:outline-none transition-transform duration-300 ${
                      expandedCustomer && 'transform rotate-90'
                    }`}
                  >
                    {ARROW_CLOSE_ENVELOPE()}
                  </span>
                </button>
              </div>
            )}
            <div className="overflow-hidden">
              <div className="truncate">{customer.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden pr-4">
              <div className="truncate">{printStringArray(outcomes.map((o) => o?.name || ''))}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{`${outcomes.length} ${pluralize(
                'Outcome',
                outcomes.length
              )}`}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td" colSpan={2}>
          <div className="flex gap-1 items-center justify-end pr-8">
            <button
              type="button"
              className="button button--secondary focus:outline-none h-5.5 w-20 justify-center"
              data-testid="edit-button"
              onClick={(e): void => {
                e.stopPropagation();
                handleRedirectOutcomes();
              }}
            >
              <span className="font-bold">Edit</span>
            </button>
          </div>
        </td>
      </LinkLookup>
      {expandedCustomer && categories.length > 0 && expandedRule()}
      {openModal && currentCategory && (
        <RenderCategoryJsonEditorModal
          setOpenModal={setOpenModal}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          category={currentCategory}
          fromModel
        />
      )}
    </>
  );
};

export default CustomerListItem;
