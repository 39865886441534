/* eslint-disable camelcase */
/* eslint-disable max-lines */

import { fetchAggs, fetchCampaignsForFilterPills } from 'actions';
import useTree from 'hooks/envelopes/useTree';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaignFilterItems } from 'selectors/campaigns';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import { MModelMetric } from 'types';
import { fillDefaultTree, Operator, Tree } from 'utils/parserTree';
import { v4 } from 'uuid';
import ModelMetricsCard from './ModelMetricsCard';

export type ModelMetricsType = {
  uuid: string;
  name: string;
  true_positive: number;
  false_positive: number;
  true_negative: number;
  false_negative: number;
  corpus: number;
  precision: number;
  recall: number;
  f1: number;
  f2: number;
};

type ComponentProps = {
  aggs:
    | {
        [key: string]: {
          [key: string]: {
            count: {
              buckets: { key: string; doc_count: number }[];
            };
            doc_count: number;
          };
        };
      }
    | undefined;
  exp: MModelMetric;
  selectedModels: string[];
  setSelectedModels: React.Dispatch<React.SetStateAction<string[]>>;
  compareMode: 'left' | 'right' | '';
  performanceFn: ((a: ModelMetricsType, b: ModelMetricsType) => number) | undefined;
};

const ModelMetricsCardsSection: React.FC<ComponentProps> = ({
  aggs,
  exp,
  selectedModels,
  setSelectedModels,
  compareMode,
  performanceFn,
}) => {
  const dispatch = useDispatch();

  const campaignById = useSelector(getCampaignFilterItems);
  const typeFilter = useSelector(getNavParamsByResource('envelopes')).type;
  const nameFilter = useSelector(getNavParamsByResource('envelopes')).name;

  const { data: corpusData, error: corpusError } = useTree(exp.corpus);
  const { data: negativeData, error: negativeError } = useTree(exp.negative);
  const { data: positiveData, error: positiveError } = useTree(exp.positive);

  const tree: Tree | null = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };

  let corpusTree = tree;
  let negativeTree = tree;
  let positiveTree = tree;

  if (corpusData && !corpusError) {
    corpusTree = fillDefaultTree(corpusData);
  }
  if (negativeData && !negativeError) {
    negativeTree = fillDefaultTree(negativeData);
  }
  if (positiveData && !positiveError) {
    positiveTree = fillDefaultTree(positiveData);
  }

  const formattedAggs: {
    [key: string]: ModelMetricsType;
  } = {};

  if (aggs && aggs[exp.id]) {
    aggs[exp.id].false_positive.count.buckets.forEach((b: { key: string; doc_count: number }) => {
      formattedAggs[b.key] = {
        ...(formattedAggs[b.key] || {}),
        false_positive: b.doc_count,
      };
    });

    aggs[exp.id].true_positive.count.buckets.forEach((b: { key: string; doc_count: number }) => {
      if (formattedAggs[b.key]) {
        const true_positive = b.doc_count;
        const { false_positive } = formattedAggs[b.key];
        const tp_total = aggs[exp.id].true_positive.doc_count;
        const fp_total = aggs[exp.id].false_positive.doc_count;
        const false_negative = tp_total - true_positive;
        const true_negative = fp_total - false_positive;
        const precision = true_positive / (true_positive + false_positive);
        const recall = true_positive / (true_positive + false_negative);
        const f1 = (2 * (precision * recall)) / (precision + recall);
        const f2 = 4 * ((precision * recall) / (4 * precision + recall));

        formattedAggs[b.key] = {
          ...(formattedAggs[b.key] || {}),
          uuid: b.key,
          true_positive,
          true_negative,
          false_negative,
          precision,
          recall,
          f1,
          f2,
        };
      }
    });

    if (aggs[exp.id].corpus) {
      aggs[exp.id].corpus?.count.buckets.forEach((b: { key: string; doc_count: number }) => {
        if (formattedAggs[b.key]) {
          const corpus = b.doc_count || 0;

          formattedAggs[b.key] = {
            ...(formattedAggs[b.key] || {}),
            corpus,
          };
        }
      });
    } else {
      Object.keys(formattedAggs).forEach((key) => {
        formattedAggs[key] = {
          ...(formattedAggs[key] || {}),
          corpus: 0,
        };
      });
    }

    if (Object.keys(campaignById).length > 0) {
      Object.keys(formattedAggs).forEach((key) => {
        if (campaignById[key]) {
          formattedAggs[key] = {
            ...formattedAggs[key],
            name: campaignById[key].name,
          };
        }
      });
    }
  }

  useEffect(() => {
    dispatch(
      fetchAggs({
        aggQuery: {
          true_positive: {
            filters_search: exp.positive,
            aggs: {
              count: {
                terms: {
                  field: 'campaign_uuids',
                  include: exp.testing.map((item: { uuid: string }) => item.uuid),
                  size: 10000,
                },
              },
            },
          },
          false_positive: {
            filters_search: exp.negative,
            aggs: {
              count: {
                terms: {
                  field: 'campaign_uuids',
                  include: exp.testing.map((item: { uuid: string }) => item.uuid),
                  size: 10000,
                },
              },
            },
          },
        },
        expId: exp.id,
      })
    );

    dispatch(
      fetchCampaignsForFilterPills({
        campaignIds: exp.testing.map((item) => item.uuid),
      })
    );
  }, [typeFilter, nameFilter, corpusData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col pb-2">
      <div className="flex flex-col gap-2">
        {Object.values(formattedAggs)
          .filter((modelMetrics) => {
            if (compareMode === '') {
              return true;
            }

            if (
              (compareMode === 'left' && modelMetrics.uuid === selectedModels[0]) ||
              (compareMode === 'right' && modelMetrics.uuid === selectedModels[1])
            ) {
              return true;
            }

            return false;
          })
          .sort(performanceFn)
          .map((modelMetrics) => (
            <ModelMetricsCard
              key={modelMetrics.uuid}
              aggs={aggs}
              exp={exp}
              modelMetrics={modelMetrics}
              selectedModels={selectedModels}
              setSelectedModels={setSelectedModels}
              compareMode={compareMode}
              corpusTree={corpusTree}
              positiveTree={positiveTree}
              negativeTree={negativeTree}
            />
          ))}
      </div>
    </div>
  );
};

export default ModelMetricsCardsSection;
