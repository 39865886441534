import { fetchFilterCampaigns, fetchFilterRules } from 'actions';
import {
  clearModelMetricType,
  createModelMetrics,
  requestTreeFiltersToogle,
  setTree,
} from 'actions/envelopes';
import useTree from 'hooks/envelopes/useTree';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getCampaignsList } from 'selectors/campaigns';
import { getNewModelMetric } from 'selectors/envelopes';
import { getRulesList } from 'selectors/rules';
import { Tree } from 'types';
import { fillDefaultTree, Operator } from 'utils/parserTree';
import { reverse, useHistory } from 'utils/urls';
import { v4 } from 'uuid';
import CategoriesOption from '../CategoriesOption';
import FilterPills from '../FilterPills';

const ModelMetricsCreateForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const allCampaigns = useSelector(getCampaignsList);
  const allRules = useSelector(getRulesList);
  const modelMetric = useSelector(getNewModelMetric);
  const user = useSelector(getUser);
  const [selectedModels, setSelectedModels] = React.useState<
    {
      name: string;
      value: string;
      type: string;
    }[]
  >(
    modelMetric.testing.map((t) => ({
      ...t,
      value: t.uuid,
      name: '',
    }))
  );

  // @ts-ignore
  const { data: corpusData, error: corpusError } = useTree(modelMetric?.corpus ?? '');
  // @ts-ignore
  const { data: negativeData, error: negativeError } = useTree(modelMetric?.negative ?? '');
  // @ts-ignore
  const { data: positiveData, error: positiveError } = useTree(modelMetric?.positive ?? '');

  useEffect(() => {
    dispatch(
      fetchFilterCampaigns({
        limit: '-1',
      })
    );
    dispatch(
      fetchFilterRules({
        limit: '-1',
      })
    );
  }, [dispatch]);

  const tree: Tree | null = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };

  let corpusTree = tree;
  let negativeTree = tree;
  let positiveTree = tree;

  if (corpusData && corpusData?.data?.length > 0 && !corpusError) {
    corpusTree = fillDefaultTree(corpusData);
  }
  if (negativeData && negativeData?.data?.length > 0 && !negativeError) {
    negativeTree = fillDefaultTree(negativeData);
  }
  if (positiveData && positiveData?.data?.length > 0 && !positiveError) {
    positiveTree = fillDefaultTree(positiveData);
  }

  const handleNavigateEditUrl = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string,
    urlString?: string
  ): void => {
    const url = new URLSearchParams();
    url.append('envelopes__performance_type', type);
    if (urlString) {
      url.append('envelopes__filters_search', urlString);
    }

    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(
      setTree({
        tree: {
          op: 'and',
          data: [],
          id: v4(),
        },
      })
    );

    if (e.ctrlKey || e.metaKey) {
      window.open(
        reverse({
          routeName: 'envelope-list',
          queryParams: url.toString(),
          customerDomain: user.customer?.domain,
        }),
        '_blank'
      );
      return;
    }

    history.push(
      reverse({
        routeName: 'envelope-list',
        queryParams: url.toString(),
        customerDomain: user.customer?.domain,
      })
    );
  };

  const handleCreateModelMetric = (): void => {
    dispatch(
      createModelMetrics({
        corpus: modelMetric?.corpus as unknown as string,
        positive: modelMetric?.positive as unknown as string,
        negative: modelMetric?.negative as unknown as string,
        testing: selectedModels.map((m) => ({
          type: m.type,
          uuid: m.value,
        })),
        customer_uuid: user.customer?.uuid || '',
        uuid: modelMetric.uuid,
      })
    );
    dispatch(clearModelMetricType());
    setSelectedModels([]);
  };

  return (
    <div className="py-6 px-10 w-full">
      <div className="flex justify-between gap-4">
        <h2 className="title">{modelMetric.uuid ? 'Edit model metric' : 'New model metric'}</h2>
        <button
          type="button"
          className="flex justify-center button button--primary h-8 w-32"
          onClick={handleCreateModelMetric}
        >
          Save
        </button>
      </div>
      <div className="flex flex-col gap-4 w-full mt-6">
        <div className="grid grid-cols-6 gap-4 items-center w-full">
          <span className="col-span-1">Corpus :</span>
          <div className="col-span-4">
            <FilterPills customTree={corpusTree} />
          </div>
          <button
            type="button"
            className="flex justify-center button button--secondary h-8 col-span-1"
            onClick={(e): void =>
              handleNavigateEditUrl(e, 'corpus', modelMetric?.corpus as unknown as string)
            }
          >
            {modelMetric?.corpus ? 'Edit Search' : 'Add Search'}
          </button>
        </div>
        <div className="grid grid-cols-6 gap-4 items-center w-full">
          <span className="col-span-1">Positive :</span>
          <div className="col-span-4">
            <FilterPills customTree={positiveTree} />
          </div>
          <button
            type="button"
            className="flex justify-center button button--secondary h-8 col-span-1"
            onClick={(e): void =>
              handleNavigateEditUrl(e, 'positive', modelMetric?.positive as unknown as string)
            }
          >
            {modelMetric?.positive ? 'Edit Search' : 'Add Search'}
          </button>
        </div>
        <div className="grid grid-cols-6 gap-4 items-center justify-between">
          <span className="col-span-1">Negative :</span>
          <div className="col-span-4">
            <FilterPills customTree={negativeTree} />
          </div>
          <button
            type="button"
            className="flex justify-center button button--secondary h-8 col-span-1 "
            onClick={(e): void =>
              handleNavigateEditUrl(e, 'negative', modelMetric?.negative as unknown as string)
            }
          >
            {modelMetric?.negative ? 'Edit Search' : 'Add Search'}
          </button>
        </div>
        <div className="grid grid-cols-6 gap-4 relative z-20">
          <span className="col-span-1">Models :</span>
          <div className="col-span-4">
            <div className="flex flex-col gap-2 ">
              {selectedModels.map((m) => (
                <span key={m.value}>
                  {m.name ||
                    [...allCampaigns, ...allRules].find((v) => v.uuid === m.value)?.name ||
                    m.value}
                </span>
              ))}
            </div>
          </div>
          <div className="bg-litlingo-primary-120 w-full h-8 col-span-1">
            <CategoriesOption
              setSelectedModels={setSelectedModels}
              selectedModels={selectedModels}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelMetricsCreateForm;
