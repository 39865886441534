/* eslint-disable camelcase */
import React from 'react';
import { getSummaryTag, getTranslationCommunication } from 'selectors/envelopeReview';
import { getOpenAttachmentState } from 'selectors/envelopeView';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import EnvelopeTranslation from './EnvelopeTranslation';
import SummaryTag from './SummaryTag';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const CommunicationContentHeader: React.FC<ComponentProps> = ({ communication }) => {
  const openAttachment = useSelector(getOpenAttachmentState);
  const envelopeTranslation = useSelector((state) =>
    getTranslationCommunication(state, openAttachment as unknown as string)
  );

  const summaryTag = useSelector((state) => getSummaryTag(state, communication?.uuid || ''));

  if (summaryTag === 'none' && !envelopeTranslation) {
    return null;
  }

  return (
    <div className="flex flex-row justify-between">
      <SummaryTag type={summaryTag} />
      <EnvelopeTranslation />
    </div>
  );
};

export default CommunicationContentHeader;
