/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import { fetchFilterCampaignRuleOutcomes, fetchFilterCampaigns, fetchFilterRules } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import {
  getCampaignRuleOutcomesList,
  getFetchAllCampaignRuleOutcomesLoading,
} from 'selectors/campaignRuleOutcomes';
import { getCampaignsList, getFetchAllCampaignsLoading } from 'selectors/campaigns';
import { getFetchAllRulesLoading, getRulesList } from 'selectors/rules';
import { useSelector } from 'store';
import { MCampaign, MRule } from 'types';

type ResourceFilterProps = {
  className?: string;
  title?: string;
  dataTestid?: string;
  hasSelectedParams?: boolean;
  isAdvanceFilterView?: boolean;
  isOpening?: boolean;
  setSelectedModels: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        value: string;
        type: string;
      }[]
    >
  >;
  selectedModels: {
    name: string;
    value: string;
    type: string;
  }[];
};

const CategoriesOption: React.FC<ResourceFilterProps> = (props) => {
  const {
    className,
    title = '',
    hasSelectedParams,
    dataTestid,

    isAdvanceFilterView,
    isOpening,
    setSelectedModels,
    selectedModels,
  } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [firstLoad, setFirstLoad] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const allCampaigns = useSelector(getCampaignsList);
  let allRules = useSelector(getRulesList);
  if (isAdvanceFilterView) {
    allRules = [];
  }

  const outcomes = useSelector(getCampaignRuleOutcomesList);

  const loadingCampaigns = useSelector(getFetchAllCampaignsLoading);
  const loadingRules = useSelector(getFetchAllRulesLoading);
  const loadingCampaignRuleOutcomes = useSelector(getFetchAllCampaignRuleOutcomesLoading);
  const loading = loadingCampaigns || loadingRules || loadingCampaignRuleOutcomes;

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if ((isOpen && firstLoad) || (hasSelectedParams && firstLoad)) {
      setFirstLoad(false);
      dispatch(
        fetchFilterCampaigns({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(
        fetchFilterRules({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(fetchFilterCampaignRuleOutcomes({ limit: -1 }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  const handleButtonClick = (): void => {
    if (isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isRule: boolean
  ): void => {
    const { id: state } = event.currentTarget;

    const item =
      allCampaigns.find((c) => c.uuid === state) || allRules.find((r) => r.uuid === state);

    if (!selectedModels.some((model) => model.value === state)) {
      setSelectedModels([
        ...selectedModels,
        { name: item?.name || '', value: state, type: isRule ? 'rule' : 'campaign' },
      ]);
    } else {
      setSelectedModels([...selectedModels.filter((model) => model.value !== state)]);
    }

    event.stopPropagation();
  };

  const getData = (): (MCampaign | MRule)[] =>
    [...allCampaigns, ...allRules].filter(
      ({ name }) => name && name.toLowerCase().includes(searchValue.toLowerCase())
    ) || [];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
          <div className="w-5">{SEARCH_ICON('white')}</div>
          <input
            id="search"
            name="search"
            ref={inputRef}
            className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
            placeholder={`Search ${title}`}
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            value={searchValue}
            autoComplete="off"
          />
        </div>

        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
              <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
                <fieldset className="m-2 min-h-5">
                  {getData().map((item, idx) => {
                    const ruleOutcome = outcomes.find(
                      (outcome) => outcome.rule_uuid === item.uuid && !outcome.campaign?.deleted_at
                    );
                    const isRule = 'rule_uuid' in item;

                    if (isRule && !ruleOutcome) {
                      return null;
                    }

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          tabIndex={0}
                          className="relative flex items-center cursor-pointer"
                          data-checked={selectedModels.some((model) => model.value === item.uuid)}
                          onClick={(e): void => handleInputChange(e, isRule)}
                          onKeyPress={(e): void => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleInputChange(
                                e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>,
                                isRule
                              );
                            }
                          }}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-start">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer "
                                    checked={selectedModels.some(
                                      (model) => model.value === item.uuid
                                    )}
                                  />
                                </div>

                                {!isRule && (
                                  <div className="h-5 flex items-center">
                                    <span className="mr-1">
                                      <svg
                                        width="3"
                                        height="16"
                                        viewBox="0 0 3 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect width="3" height="16" fill="#F8A01A" />
                                      </svg>
                                    </span>
                                  </div>
                                )}

                                <div className="flex flex-col text-sm leading-5">
                                  <label htmlFor={item.uuid} className="cursor-pointer">
                                    <span className="text-litlingo-gray-6 text-xxs font-normal select-none break-word">
                                      {item.name}
                                    </span>
                                  </label>
                                  {ruleOutcome && (
                                    <span className="text-litlingo-gray-5 text-xxs font-normal select-none break-word">
                                      {ruleOutcome.campaign?.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            </LoadingOverlayWrapper>
          </>
        )}
      </div>
    </>
  );
};

export default CategoriesOption;
