/* eslint-disable no-unused-expressions */
/* eslint-disable max-lines */

import { postTestSentenceV2, replaceURLParams, setCompareMode } from 'actions';
import {
  clearRuleGroupTest,
  previewRuleGroup,
  setCurrentTestRuleId,
  setShowHighlights,
} from 'actions/ruleGroup';
import LoadingIndicator from 'components/LoadingIndicator';
import SelectRedesign from 'components/Select/SelectRedesign';
import SentencesViolatedSidebarSection from 'components/SentencesViolatedSection/SentencesViolatedSidebarSection';
import keyMap from 'constants/configHotKeys';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import React, { useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { getCompareRule, getSelectedCustomerRuleConfig, getSelectedNode } from 'selectors/config';
import { getNavParams } from 'selectors/nav';
import {
  getAnnotatedTestSentenceRuleGroupResult,
  getCampaignsRulesViolatedRuleGroupV2,
  getCurrentTestRuleId,
  getPreviewRuleGroupLoading,
  getRuleGroupTest,
  getSelectedCustomerGroup,
  getShowV2Graph,
} from 'selectors/ruleGroup';
import { getSelectedRuleRevision, getShowRuleHighlights } from 'selectors/ruleGroups';
import type { Platform } from 'types';
import logEvent from 'utils/analytics';
import { v4 } from 'uuid';

type ComponentProps = {
  testPhrase?: string;
};

const TestSentenceVisualizerSidebarRuleGroup: React.FC<ComponentProps> = ({ testPhrase }) => {
  const { useEffect } = React;
  const dispatch = useDispatch();

  const prodRevision = useSelector(getCompareRule);
  const revisionId = useSelector(getSelectedRuleRevision)?.uuid;
  const ruleGroupId = useSelector(getSelectedRuleRevision)?.rule_group_uuid;
  const selectedCustomerGroup = useSelector(getSelectedCustomerGroup);

  const campaignRulesViolatedV2 = useSelector(getCampaignsRulesViolatedRuleGroupV2);
  // const arrayTree = useSelector(getConfigRuleAsArray);
  const selectedNode = useSelector(getSelectedNode);
  const testSentenceLoading = useSelector(getPreviewRuleGroupLoading);
  const showHighlights = useSelector(getShowRuleHighlights);

  const resultLines = useSelector(getAnnotatedTestSentenceRuleGroupResult);
  const communicationTest = useSelector(getRuleGroupTest);

  const bodyParam = useSelector(getNavParams).body || '';
  const currentTestRuleId = useSelector(getCurrentTestRuleId);

  const selectedCustomer = useSelector(getSelectedCustomerRuleConfig);

  const [shouldTestFire, setShouldTestFire] = useState(false);
  const [platform, setPlatform] = useState<string>('this-model');
  const [body, setBody] = useState<string>(bodyParam as string);
  const [debouncedBody, setDebouncedBody] = useState('');
  const [firstTime, setFirstTime] = useState(true);

  const showV2 = useSelector(getShowV2Graph);

  const RunButtonRef = useRef<HTMLButtonElement>(null);
  const SaveButtonRef = useRef<HTMLButtonElement>(null);
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  const [channelId] = useState(v4());

  useEffect(() => {
    const channel = new BroadcastChannel(`test-visualizer-channel-${channelId}`);
    if (communicationTest) {
      channel.postMessage({ communication: communicationTest });
    }
  }, [communicationTest, channelId]);

  useEffect(() => {
    const channel = new BroadcastChannel(`test-visualizer-channel-${channelId}`);

    const handleEvent = (event: MessageEvent<{ ready: boolean }>): void => {
      if (event.data.ready) {
        if (communicationTest) {
          channel.postMessage({ communication: communicationTest });
        }
      }
    };

    channel.addEventListener('message', handleEvent);

    return () => {
      channel.removeEventListener('message', handleEvent);
    };
  }, [communicationTest, channelId]);

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => {
    if (!currentTestRuleId) {
      dispatch(clearRuleGroupTest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestRuleId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedBody(body), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [body]);

  useEffect(() => {
    if (bodyParam !== debouncedBody) {
      dispatch(replaceURLParams({ body: debouncedBody }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBody, dispatch]);

  useEffect(() => {
    if (testPhrase) {
      setBody(testPhrase || '');
    }
  }, [testPhrase]);

  const submitTest = (useV2 = false): void => {
    const revId =
      selectedCustomerGroup === 'production' && prodRevision ? prodRevision.uuid : revisionId;

    if (platform === 'this-model') {
      dispatch(
        previewRuleGroup({
          body,
          ruleRevisionId: revId || '',
          ruleGroupId: ruleGroupId || '',
          platform: 'all',
          force_v2_graphs: useV2,
          customer_uuid: selectedCustomer || '',
        })
      );
    } else if (platform === 'selected-branch') {
      dispatch(
        previewRuleGroup({
          body,
          ruleRevisionId: revId || '',
          ruleGroupId: ruleGroupId || '',
          platform,
          force_v2_graphs: useV2,
          customer_uuid: selectedCustomer || '',
        })
      );
    } else if (platform === 'all') {
      dispatch(
        postTestSentenceV2({ body, platform: 'all', inRuleGroup: true, force_v2_graphs: useV2 })
      );
    } else {
      dispatch(
        previewRuleGroup({
          body,
          ruleRevisionId: revId || '',
          ruleGroupId: ruleGroupId || '',
          platform,
          force_v2_graphs: useV2,
          customer_uuid: selectedCustomer || '',
        })
      );
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    logEvent('tests-run-test');

    dispatch(setCompareMode(false));
    submitTest(showV2);
  };

  useEffect(() => {
    if (!bodyParam) return;
    setBody(bodyParam as string);
    if (firstTime) {
      submitTest(true);
      setFirstTime(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyParam, firstTime]);

  const handlers = {
    RUN_TEST_PHRASE: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    RUN_TEST_PHRASE_ALT: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    SAVE_TO_SUITE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        if (document.activeElement === TextAreaRef?.current) {
          SaveButtonRef?.current?.click();
        }
      }
    },
  };

  const selectTargetOptions = [
    {
      label: 'This Model',
      value: 'this-model',
    },
    {
      label: 'Selected Branch',
      value: 'selected-branch',
    },
    {
      label: 'All',
      value: 'all',
    },
  ];

  const handlePlatformChange = (option: string): void => {
    logEvent('tests-platform-change');
    setPlatform(option as Platform);
  };

  /*   const handleSaveTestCase = (): void => {
    const branchId = arrayTree[selectedNode as number].id;

    const entities = {
      rule_uuid: rule.uuid || '',
      branchId: branchId || '',
    };

    dispatch(saveTestCase({ test_string: body, should_match: shouldTestFire, ...entities }));
  }; */

  const handleShowHighlights = (): void => {
    dispatch(setShowHighlights(!showHighlights));
  };

  const handleDisable = (): boolean => {
    if (body === '') {
      return true;
    }
    if (
      platform === 'selected-branch' &&
      (!selectedNode || selectedNode === -1) &&
      selectedNode !== 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="flex flex-col gap-6 pb-5">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center gap-2">
            <span className="text-body">on</span>
            <div className="w-52">
              <SelectRedesign
                onChange={handlePlatformChange}
                options={selectTargetOptions}
                value={platform}
                valueClassName="italic"
              />
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            data-testid="test-viz-phrase-form"
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-1">
              <textarea
                ref={TextAreaRef}
                id="body"
                data-testid="test-viz-phrase-input"
                rows={12}
                className="form-textarea text-body w-full transition duration-150 ease-in-out resize-none"
                onChange={(event): void => {
                  setBody(event.target.value);
                }}
                value={body}
              />

              <p className="text-small">
                <strong>Ctrl + Enter </strong>
                to test the phrase.
              </p>
            </div>

            <button
              ref={RunButtonRef}
              type="submit"
              data-testid="run-test-button"
              className={`h-8 w-37 text-body button button--primary flex justify-center self-end ${
                body === '' ? 'bg-opacity-25' : ''
              }`}
              disabled={handleDisable()}
              onClick={(): void => {
                dispatch(setCurrentTestRuleId(revisionId ?? ''));
                dispatch(setShowHighlights(true));
              }}
            >
              <span className="text-white font-bold">
                {testSentenceLoading ? <LoadingIndicator size="5" /> : 'Run Test'}
              </span>
            </button>
          </form>
        </div>
        <div className="border-b border-litlingo-gray-4" />

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-heading-3">Results</span>

            {resultLines ? (
              <div className="flex flex-col gap-4">
                {resultLines && (
                  <SentencesViolatedSidebarSection
                    campaignRulesViolated={campaignRulesViolatedV2}
                    resultLines={resultLines}
                    noLink
                  />
                )}

                <div className="flex flex-col gap-2 pl-0.5">
                  <span>
                    This should:<span className="text-litlingo-alert">*</span>
                  </span>
                  <div className="flex flex-row gap-5 text-small">
                    <label htmlFor="match" className="flex flex-row items-center gap-1 w-full">
                      <input
                        type="radio"
                        id="match"
                        className="input-radio"
                        name="true"
                        value="true"
                        checked={shouldTestFire}
                        onChange={(): void => setShouldTestFire(true)}
                      />
                      <span>Match</span>
                    </label>
                    <label htmlFor="no-match" className="flex flex-row items-center gap-1 w-full">
                      <input
                        type="radio"
                        id="no-match"
                        className="input-radio"
                        name="false"
                        value="false"
                        checked={!shouldTestFire}
                        onChange={(): void => setShouldTestFire(false)}
                      />
                      <span>No Match</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-body italic text-litlingo-gray-4">
                Run your test to see the results here.
              </span>
            )}
          </div>
          {resultLines && (
            <>
              <div className="flex flex-row justify-between gap-2.5">
                <button
                  type="button"
                  className="button button--secondary flex justify-center text-body w-full h-8"
                  onClick={handleShowHighlights}
                >
                  <span className="font-bold">
                    {showHighlights ? 'Hide in Logic' : 'Show in Logic'}{' '}
                  </span>
                </button>
                {/* <button
                  type="button"
                  className="button button--primary w-full flex justify-center h-8"
                  onClick={handleSaveTestCase}
                >
                  <span className="font-bold">Add to Test Suite</span>
                </button> */}
              </div>

              <a
                className="flex flex-row items-center gap-2 self-center text-body"
                target="_blank"
                rel="noopener noreferrer"
                href={`${window.location.origin}/global/test-viz-result?channel_id=${channelId}`}
              >
                <span>{EXPAND_ICON}</span>
                <span>Language Visualizer Pop-out</span>
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TestSentenceVisualizerSidebarRuleGroup;
