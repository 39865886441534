import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFetchAllTagGroupsLoading, getTagGroups } from 'selectors/tagGroup';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TagGroupsHeader from './TagGroupsHeader';
import TagGroupsList from './TagGroupsList';

const TagGroups: React.VFC = () => {
  const tagGroups = useSelector(getTagGroups);
  const loading = useSelector(getFetchAllTagGroupsLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />

        <div className="pt-4 pb-10 h-full flex flex-col gap-2 overflow-hidden w-full">
          <TagGroupsHeader />

          <main className="flex w-full overflow-hidden">
            <TagGroupsList tagGroups={tagGroups} loading={loading} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default TagGroups;
