import { LoadingIndicator } from '@litlingo/react';
import LinkLookup from 'components/LinkLookup';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFetchAllTagGroupsLoading, getTagGroups } from 'selectors/tagGroup';
import CreateTagGroupModal from './CreateTagGroupModal';

const TagGroupsHeader: React.VFC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tagGroups = useSelector(getTagGroups);
  const loading = useSelector(getFetchAllTagGroupsLoading);

  const toggleModalOpen = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <header className="w-full px-6">
      <div className="flex flex-col gap-6">
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center gap-2">
            <LinkLookup
              routeName="tags"
              className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
            >
              All Tags
            </LinkLookup>
            <div
              role="button"
              tabIndex={0}
              className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
            >
              Tag Groups
            </div>
          </div>
          <button
            type="button"
            className="button button--primary h-8 w-37 flex justify-center"
            onClick={(): void => {
              toggleModalOpen();
            }}
          >
            New Group
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center">
            <h2 className="text-heading-2">Tag Groups</h2>
            {loading && <LoadingIndicator className="ml-2" size="5" />}
          </div>

          <div className="flex flex-nowrap justify-between h-8">
            <div className="flex gap-4">
              <SearchInput
                className="max-w-60"
                resource={resourceQueryParamName.tagGroups}
                field="broad_search"
                placeholder="Search group tags"
              />
            </div>
            <div className="self-end">
              {loading ? (
                <LoadingIndicator className="ml-2" size="5" />
              ) : (
                <div className="">
                  {tagGroups.length} of {tagGroups.length} Tag Groups
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <CreateTagGroupModal
          tagGroup={null}
          isModalOpen={isModalOpen}
          toggleModalOpen={toggleModalOpen}
        />
      )}
    </header>
  );
};

export default TagGroupsHeader;
