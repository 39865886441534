/* eslint-disable max-lines */
import { deleteModel, fetchModelCategories, fetchModelCategoriesDiff } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LoadingIndicator from 'components/LoadingIndicator';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import keyMap from 'constants/configHotKeys';
import React, { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFetchSingleModelLoading, getModel, getModelCategories } from 'selectors/models';
import { useSelector } from 'store';
import type { UUID } from 'types';
import ModelHeader from './ModelHeader';
import RenderCategories from './RenderCategories';
import RenderTestSuite from './RenderTestSuite';

type RouteParams = {
  modelId: UUID;
};

const Model: React.FC = () => {
  const { modelId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  const item = useSelector(getModel(modelId)) || { active: false };
  const loading = useSelector(getFetchSingleModelLoading);
  const categories = useSelector((state) => getModelCategories(state, modelId));

  const [isDeletingModel, setIsDeletingModel] = useState(false);

  useEffect(() => {
    dispatch(fetchModelCategories({ modelId }));
  }, [dispatch, modelId]);

  useEffect(() => {
    if (categories.length === 0) return;
    dispatch(fetchModelCategoriesDiff(categories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, modelId, categories.length]);

  const handleDelete = async (): Promise<void> => {
    dispatch(deleteModel({ id: modelId }));
  };

  const toggleConfirmModal = (): void => setIsDeletingModel(false);

  const handlers = {
    SAVE_MODEL: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-model-setup-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (loading && (!item || item.uuid !== modelId))
    return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <GlobalNavbar />
        <div className="flex flex-col gap-2 h-full pt-6 pb-4">
          <ModelHeader />

          <main>
            <div className="relative flex flex-col gap-8">
              <div className="z-10">
                <RenderCategories item={item} />
              </div>
              <div className="px-6">
                <RenderTestSuite item={item} />
              </div>
            </div>
          </main>
        </div>

        {isDeletingModel && (
          <ConfirmModal
            text="Are you sure you want to delete this use case?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDelete}
            toggleShowModal={toggleConfirmModal}
          />
        )}
      </div>
    </>
  );
};
export default Model;
