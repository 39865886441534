import { createIdentifier, fetchIdentifierTypes, setShowEditIdentifier } from 'actions/identifier';
import EmptyTableState from 'components/EmptyTableState';
import LinkLookup from 'components/LinkLookup';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIdentifierTypes } from 'selectors/identifier';
import { useSelector } from 'store';

const IdentifierTypeListTable: React.FC = () => {
  const dispatch = useDispatch();

  const types = useSelector(getIdentifierTypes);
  useEffect(() => {
    dispatch(fetchIdentifierTypes());
  }, [dispatch]);

  const handleCreateLLMChain = (): void => {
    dispatch(setShowEditIdentifier(true));
    dispatch(
      createIdentifier({
        name: 'New Identifier',
        description: '',
        kind: 'llm_chain',
        options_config: {
          feature: 'new feature',
          // @ts-ignore
          min_threshold: '0',
          prompt: 'new prompt',
        },
        redirect: true,
      })
    );
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="table-wrapper">
            <table className="table-wrapper__table">
              <thead>
                <tr>
                  <th className="table-wrapper__th bg-litlingo-background text text--semibold">
                    TEMPLATES
                  </th>
                </tr>
              </thead>
              <tbody className="table-wrapper__tbody">
                {Object.keys(types).length === 0 && (
                  <EmptyTableState message="There are no types to display" colCount="1" />
                )}
                {(Object.keys(types) as Array<keyof typeof types>).map((key) => {
                  if (key === 'custom') {
                    return null;
                  }

                  if (key === 'llm_chain') {
                    return (
                      <tr key={key} className="border-t border-gray-200 table-row">
                        <td className="table-wrapper__td p-0">
                          <button
                            type="button"
                            className="w-full hover:bg-gray-100 py-4 px-6 focus:outline-none"
                            onClick={handleCreateLLMChain}
                          >
                            <div className="flex items-center">
                              <div>
                                {/*
                              // @ts-ignore */}
                                <div className="text text--semibold flex">{types[key].name}</div>
                                {/*
                              // @ts-ignore */}
                                <div className="text text--lighter-3">{types[key].description}</div>
                              </div>
                            </div>
                          </button>
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <LinkLookup
                      key={key}
                      className="border-t border-gray-200 hover:bg-gray-100 table-row"
                      routeName="global-identifier-list-new-type"
                      routeParams={{ type: key.toString() }}
                      as="tr"
                    >
                      <td className="table-wrapper__td">
                        <div className="flex items-center">
                          <div>
                            {/*
                              // @ts-ignore */}
                            <div className="text text--semibold flex">{types[key].name}</div>
                            {/*
                              // @ts-ignore */}
                            <div className="text text--lighter-3">{types[key].description}</div>
                          </div>
                        </div>
                      </td>
                    </LinkLookup>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentifierTypeListTable;
