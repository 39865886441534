/* eslint-disable max-lines */

import { Customer } from '@litlingo/client';
import { collapseCategory, expandCategory } from 'actions';
import LinkLookup from 'components/LinkLookup';
import { ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import { startCase } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getExpandedCategories, getStateFilter } from 'selectors/models';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { printStringArray } from 'utils/strings';
import { groupedCategoryByRule } from '.';
import CustomerListItem from './CustomerListItem';

type ComponentProps = {
  ruleCategory: groupedCategoryByRule;
};

const CategoryListItem: React.FC<ComponentProps> = ({ ruleCategory }) => {
  const dispatch = useDispatch();

  const stateFilter = useSelector(getStateFilter);
  const expandedCategories = useSelector(getExpandedCategories);

  const [expanded, setExpanded] = useState(expandedCategories.includes(ruleCategory.uuid));

  const customers = ruleCategory.categories.reduce<Record<string, Customer>>((acc, cur) => {
    if (cur.customer) {
      return { ...acc, [cur.customer_uuid]: cur.customer };
    }
    return acc;
  }, {});

  const getCategoryState = (): string[] => {
    const categoryState = [];
    // @ts-ignore
    if (ruleCategory.categories.some((c) => c.tenant_name === 'human')) {
      categoryState.push('Production');
    }
    // @ts-ignore
    if (ruleCategory.categories.some((c) => c.tenant_name === 'modeler')) {
      categoryState.push('Staging');
    }
    return categoryState;
  };

  if (stateFilter) {
    if (
      stateFilter === 'staging' &&
      // @ts-ignore
      !ruleCategory.categories.some((c) => c.tenant_name === 'modeler')
    ) {
      return null;
    }
    if (
      stateFilter === 'production' &&
      // @ts-ignore
      !ruleCategory.categories.some((c) => c.tenant_name === 'human')
    ) {
      return null;
    }
  }

  const expandedCustomerRow = Object.values(customers)
    .sort((a, b) => {
      if (a.name && b.name && a.name > b.name) {
        return 1;
      }
      if (a.uuid > b.uuid) {
        return 1;
      }
      return -1;
    })
    .filter((c) => {
      const customerCategories = ruleCategory.categories.filter(
        (cat) => cat.customer_uuid === c.uuid
      );
      if (!stateFilter) return true;
      if (
        stateFilter === 'staging' &&
        // @ts-ignore
        !customerCategories.some((cat) => cat.tenant_name === 'modeler')
      ) {
        return false;
      }
      if (
        stateFilter === 'production' &&
        // @ts-ignore
        !customerCategories.some((cat) => cat.tenant_name === 'human')
      ) {
        return false;
      }
      return true;
    })
    .map((c) => <CustomerListItem key={c.uuid} ruleCategory={ruleCategory} customer={c} />);

  return (
    <>
      <LinkLookup
        routeName="global-rule-group-manager"
        routeParams={{ ruleId: ruleCategory.categories[0].rule?.rule_group_uuid || '' }}
        className="border-t border-litlingo-gray-1 hover:bg-litlingo-gray-0.5 table-row"
        onClick={(): Promise<void> => logEvent('single-use-case')}
        as="tr"
      >
        <td className="table-wrapper__new-td h-12">
          <div className="flex justify-end h-full">
            <button
              className="flex px-3 justify-end items-center w-full h-full focus:outline-none"
              type="button"
              data-testid="expand-button"
              onClick={(e): void => {
                e.stopPropagation();
                if (expanded) {
                  dispatch(collapseCategory(ruleCategory.uuid));
                } else {
                  dispatch(expandCategory(ruleCategory.uuid));
                }
                setExpanded(!expanded);
              }}
            >
              <span
                className={`py-1.5 focus:outline-none transition-transform duration-300 ${
                  expanded && 'transform rotate-90'
                }`}
              >
                {ARROW_CLOSE_ENVELOPE()}
              </span>
            </button>
          </div>
        </td>
        <td className="table-wrapper__new-td h-12">
          <div className="flex items-center text-body pr-4">
            <div className="overflow-hidden">
              <div className="truncate font-bold">{ruleCategory.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden pr-4">
              <div className="truncate">{ruleCategory.description}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{`${Object.keys(customers).length} ${pluralize(
                'Customer',
                Object.keys(customers).length
              )}`}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{printStringArray(getCategoryState())}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden pr-4">
              <div className="truncate">
                {startCase(
                  // @ts-ignore
                  ruleCategory.categories.filter((c) => c.tenant_name === 'modeler')[0]?.updated_by
                    .name || ''
                )}
              </div>
            </div>
          </div>
        </td>
      </LinkLookup>
      {expanded && expandedCustomerRow}
    </>
  );
};

export default CategoryListItem;
