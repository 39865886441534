import { setDropPosition } from 'actions/identifier';
import { addAnnotationMatcherToRule } from 'actions/ruleGroup';
import { ItemTypes } from 'components/RuleGroupManagerTable/RuleManagerUtils';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { MRuleRevision, NormalizedIdentifier } from 'types';
import RenderIdentifier from './RenderIdentifier';

type ComponentProps = {
  Component: typeof RenderIdentifier;
  identifier: NormalizedIdentifier;
  rule: MRuleRevision;
  searchValue: string;
  idx: number;
  disabled?: boolean;
};

const MakeIdentifierDraggable: React.FC<ComponentProps> = ({
  Component,
  identifier,
  rule,
  searchValue,
  idx,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const type: keyof typeof ItemTypes = 'ANNOTATOR';

  const [, drag, preview] = useDrag({
    item: { type: ItemTypes[type], identifier, rule },
    canDrag: !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        const { collapsedFolder, handleUncollapse, id } = dropResult;

        dispatch(
          // @ts-expect-error starship
          addAnnotationMatcherToRule({ rule, annotator: identifier, dropIndex: dropResult.index })
        );

        if (collapsedFolder.includes(id)) {
          handleUncollapse(id);
        }
      }
      dispatch(setDropPosition(-1));
    },
  });

  return (
    <Component
      drag={drag}
      preview={preview}
      identifier={identifier}
      rule={rule}
      searchValue={searchValue}
      idx={idx}
    />
  );
};

export default MakeIdentifierDraggable;
