/* eslint-disable camelcase */
import { EntityTag } from '@litlingo/client';
import { addTagToReview, removeTagFromReview } from 'actions';
import { fetchAllTags } from 'actions/tags';
import EnvelopeLabel from 'components/EnvelopeLabel';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import { getUsersSingleAssigment } from 'selectors/assignments';
import { getTags } from 'selectors/envelopeReview';
import { addEnvelopeTagLoading, removeEnvelopeTagLoading } from 'selectors/envelopes';
import {
  getFurtherReviewTagGroup,
  getTagGroupsForAssignment,
  getTagGroupsForCustomer,
} from 'selectors/tagGroup';
import { getFetchAllTagsLoading, getTagsForFilters } from 'selectors/tags';
import { useSelector } from 'store';
import 'styles/labelsStyle.css';
import logEvent from 'utils/analytics';

type EnvelopeTagsProps = {
  hideModelerTags?: boolean;
};

const EnvelopeTags: React.FC<EnvelopeTagsProps> = ({ hideModelerTags = false }) => {
  const dispatch = useDispatch();
  const envelopeTags = useSelector(getTags) || [];
  const allTags = useSelector(getTagsForFilters);
  const tagsLoading = useSelector(getFetchAllTagsLoading);
  const addLoading = useSelector(addEnvelopeTagLoading);
  const removeLoading = useSelector(removeEnvelopeTagLoading);
  const singleAssigment = useSelector(getUsersSingleAssigment);
  const tagGroups = useSelector((state) =>
    getTagGroupsForAssignment(state, singleAssigment?.uuid || '')
  );

  const customerTagGroups = useSelector(getTagGroupsForCustomer);
  const furtherReviewTagGroup = useSelector(getFurtherReviewTagGroup);

  // Filter out all tags we don't want users to select from here
  const suggestions = allTags
    .filter((tag) => envelopeTags.every((t) => t.tag_value?.value !== tag.value))
    .filter((tag) =>
      tagGroups.every((group) => group.tag_values.every((tag_value) => tag_value.uuid !== tag.uuid))
    )
    .filter((tag) =>
      customerTagGroups.every((group) =>
        group.tag_values.every((tag_value) => tag_value.uuid !== tag.uuid)
      )
    )
    .filter((tag) =>
      !furtherReviewTagGroup
        ? true
        : furtherReviewTagGroup?.tag_values.every((tag_value) => tag_value.uuid !== tag.uuid)
    )
    // @ts-ignore
    .filter((t) => t.tenant_name !== 'modeler' || !hideModelerTags)
    .map((tag) => ({ id: tag.uuid, name: tag.value }));

  useEffect(() => {
    dispatch(fetchAllTags({ limit: -1, include_envelopes_count: false }));
  }, [dispatch]);

  const handleAddition = (tag: { name: string }): void => {
    const tagToAdd = allTags.find((t) => t.value === tag.name);

    if (tagToAdd) {
      dispatch(
        addTagToReview({
          tag: tagToAdd,
        })
      );
    } else {
      dispatch(
        addTagToReview({
          tag: {
            value: tag.name,
            color: '',
          },
        })
      );
    }
    logEvent('envelope-add-tag');
  };

  const handleRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string): void => {
    e.preventDefault();
    const tagToRemove = envelopeTags.find((t) => t.tag_value?.value === tag);

    if (tagToRemove && tagToRemove.tag_value) {
      dispatch(removeTagFromReview({ tag: tagToRemove.tag_value }));
    }

    logEvent('envelope-remove-tag');
  };

  const renderTags = (): (JSX.Element | null)[] => {
    let filteredTags: EntityTag[] = envelopeTags;
    // Filter out all tags we don't want to show here
    if (tagGroups) {
      filteredTags = filteredTags.filter((tag) =>
        tagGroups.every((group) =>
          group.tag_values.every((tag_value) => tag_value.uuid !== tag.tag_value_uuid)
        )
      );
    }

    if (customerTagGroups) {
      filteredTags = filteredTags.filter((tag) =>
        customerTagGroups.every((group) =>
          group.tag_values.every((tag_value) => tag_value.uuid !== tag.tag_value_uuid)
        )
      );
    }

    if (furtherReviewTagGroup) {
      filteredTags = filteredTags.filter((tag) =>
        furtherReviewTagGroup.tag_values.every((tag_value) => tag_value.uuid !== tag.tag_value_uuid)
      );
    }

    if (hideModelerTags) {
      // @ts-ignore
      filteredTags = filteredTags.filter((tag) => tag.tenant_name !== 'modeler');
    }

    return filteredTags.map((tag) => {
      const tagObj = tag.tag_value;
      if (tagObj == null) {
        return null;
      }

      return (
        <div style={{ order: 0, margin: '2px 0' }} key={tagObj.uuid}>
          <EnvelopeLabel
            text={tagObj.value}
            backgroundColor={tagObj.color}
            className="litlingo-tag-color"
            allowedRemove
            handleRemove={(e): void => handleRemove(e, tagObj.value)}
          />
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      <div className="flex flex-row gap-2 items-center h-8 w-full">
        <span className="text-base font-normal leading-5">Add tag:</span>
        <div className="envelope-page w-48">
          <ReactTags
            allowNew
            // placeholderText is not present on the types library but it is on the library docs
            // @ts-ignore
            placeholderText=""
            suggestions={suggestions}
            onAddition={handleAddition}
            onDelete={(): Record<string, never> => ({})}
            maxSuggestionsLength={25}
            minQueryLength={0}
          />
        </div>
        {(tagsLoading || addLoading || removeLoading) && (
          <div className="flex flex-row">
            <div className="ml-3 loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
          </div>
        )}
      </div>

      <div className="flex flex-row gap-1 flex-wrap">{renderTags()}</div>
    </div>
  );
};

export default EnvelopeTags;
