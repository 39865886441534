/* eslint-disable max-lines */
import { fetchPrompts } from 'actions';
import { setSelectedIdentifierOptionsConfig } from 'actions/identifier';
import SelectRedesign from 'components/Select/SelectRedesign';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPrompts } from 'selectors/entities';
import {
  getCompareMode,
  getProductionIdentifierRevision,
  getSelectedIdentifierRevision,
  getShouldDisableEdit,
  saveIdentifierRequestLoading,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import { languageMatcherNotificationMsgs } from 'utils/userNotificationMsgs';

type LanguageMatcherType = {
  isSaved: boolean;
};

const LLMChainForm: React.FC<LanguageMatcherType> = ({ isSaved }) => {
  const dispatch = useDispatch();

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const prodRevision = useSelector(getProductionIdentifierRevision);
  const unsaved = useSelector(unsavedChanges);
  const isLoadingSaveIdentifier = useSelector(saveIdentifierRequestLoading);
  const compareMode = useSelector(getCompareMode);
  const shouldDisable = useSelector(getShouldDisableEdit);
  const prompts = useSelector(getPrompts);

  const [selectedPrompt, setSelectedPrompt] = useState(
    // @ts-ignore
    prompts?.find((p) => p?.name === identifierRevision?.options_config.prompt) ||
      // @ts-ignore
      prompts[0]
  );

  const promptsOptions = prompts.map((p) => ({
    // @ts-ignore
    label: p.name,
    value: p.uuid,
  }));

  useEffect(() => {
    dispatch(fetchPrompts());
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore
    setSelectedPrompt(prompts?.find((p) => p?.name === identifierRevision?.options_config.prompt));
  }, [prompts, identifierRevision?.options_config.prompt]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!identifierRevision?.options_config) {
    return null;
  }

  const { feature, min_threshold: facet } = identifierRevision?.options_config;
  const {
    feature: prodFeature,
    min_threshold: prodFacet,
    prompt: prodPrompt,
  } = prodRevision?.options_config || identifierRevision?.options_config;

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const config = {
      ...identifierRevision.options_config,
      [e.target.name]: e.target.value,
    };

    dispatch(setSelectedIdentifierOptionsConfig(config));
  };

  const handleSelectPrompt = (value: string): void => {
    const prompt = prompts.find((p) => p.uuid === value);
    if (prompt) {
      setSelectedPrompt(prompt);
    }

    const config = {
      ...identifierRevision.options_config,
      // @ts-ignore
      prompt: prompt.name,
    };

    dispatch(setSelectedIdentifierOptionsConfig(config));
  };

  return (
    <div className="flex flex-col gap-4 pt-4 px-4 pb-10 text-body">
      {unsaved && (
        <div className="flex flex-row justify-end">
          <WarningMsg
            message={languageMatcherNotificationMsgs.unsavedChanges}
            showLoading={isLoadingSaveIdentifier}
            showWarning={unsaved}
            showSavedMsg={isSaved}
          />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-5">
          <label htmlFor="feature" className="flex flex-col gap-1 text-body w-1/2">
            <div className="flex flex-row">
              <span>Feature</span>
              <span className="text-litlingo-alert">*</span>
            </div>
            <input
              name="feature"
              id="feature"
              type="text"
              data-testid="matcher-feature-input"
              value={feature?.toString()}
              className={`form-input w-full h-9 py-2 px-4 border border-litlingo-gray-2 rounded-lg text-body focus:outline-none ${
                prodFeature !== feature && compareMode ? 'bg-litlingo-focus-area-background' : ''
              }`}
              onChange={onEditLanguageMatcher}
              disabled={compareMode || shouldDisable}
              pattern=".*\S.*"
            />
          </label>

          <label htmlFor="min_threshold" className="flex flex-col gap-1 text-body w-62">
            <div className="flex flex-row">
              <span>Facet score threshold [0.0 - 1.0]</span>
              <span className="text-litlingo-alert">*</span>
            </div>
            <input
              name="min_threshold"
              id="min_threshold"
              type="number"
              data-testid="matcher-min-threshold-input"
              value={facet?.toString()}
              className={`form-input w-full h-9 py-2 px-4 border border-litlingo-gray-2 rounded-lg text-body focus:outline-none ${
                prodFacet !== facet && compareMode ? 'bg-litlingo-focus-area-background' : ''
              }`}
              onChange={onEditLanguageMatcher}
              disabled={compareMode || shouldDisable}
              min={0}
              max={1}
              step={0.01}
            />
          </label>
        </div>

        <div className="w-1/2 flex flex-col">
          <div className="flex flex-row">
            <span>Prompt</span>
            <span className="text-litlingo-alert">*</span>
          </div>
          <SelectRedesign
            onChange={handleSelectPrompt}
            options={promptsOptions}
            value={selectedPrompt?.uuid || ''}
            className={`h-9 ${
              // @ts-ignore
              prodPrompt !== prompt.name && compareMode ? 'bg-litlingo-focus-area-background' : ''
            }`}
            dropdownClassname="h-60 overflow-auto custom-scrollbar"
            disabled={shouldDisable}
          />
        </div>
        {selectedPrompt && (
          <div className="mt-2 flex flex-col gap-4">
            <span className="flex-body italic">
              Deployed with {selectedPrompt?.deployment} (Source: {selectedPrompt?.source})
            </span>
            <span className="font-bold">Prompt:</span>
            <span className="text-body whitespace-pre-line">{selectedPrompt?.content}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LLMChainForm;
