/* eslint-disable max-lines */
import {
  clearSelectedFields,
  clearSelectedFilters,
  deleteURLParams,
  removeSelectedFilter,
  setURLParams,
} from 'actions';
import { removeFilterOrder, setFilterOrder } from 'actions/envelopeListView';
import { displayValuesMapping, displayValuesMappingV2 } from 'constants/filtersMappingValues';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFiltersOrder, getNavParamsFetchingFilters } from 'selectors/envelopeListView';
import {
  getAllFilterValuesFromTreeWithLabels,
  getSelectedFilters,
  getTree,
} from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import getEnvelopeReviewLabelOptions from 'selectors/reviewStatus';
import { useSelector } from 'store';
import type { ReviewValueConfigType, RouteParams } from 'types';
import logEvent from 'utils/analytics';
import {
  Tree,
  TreeFieldsValuesWithLabels,
  addLabelToNode,
  allNodesLabeled,
  setNewValue,
} from 'utils/parserTree';
import FilterPillsTree from './FilterPillsTree';

export type FilterValue = { id: string; value: string };
type ComponentProps = {
  propParams?: RouteParams;
  treePropsParams?: TreeFieldsValuesWithLabels;
  allowDelete?: boolean;
  envelopesPage?: boolean;
  scrolled?: boolean;
  showAllFilters?: boolean;
  filtersModified?: boolean;
  placeholder?: string;
  customTree?: Tree;
  renderSampleButton?: boolean;
};

const FilterPills: React.FC<ComponentProps> = ({
  propParams,
  treePropsParams,
  allowDelete = true,
  envelopesPage = true,
  scrolled,
  showAllFilters,
  filtersModified,
  placeholder,
  customTree,
  renderSampleButton = true,
}) => {
  const dispatch = useDispatch();
  const urlParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );
  const navParams = propParams || urlParams;
  const urlTreeParams = useSelector(getAllFilterValuesFromTreeWithLabels);
  const treeParams = treePropsParams || urlTreeParams;
  const tree = useSelector(getTree);

  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);
  const FiltersOrdering = useSelector(getFiltersOrder);
  const loadingFilterPills = useSelector(getNavParamsFetchingFilters);
  const filtersHiglighted = useSelector(getSelectedFilters);

  const ignoredParams = [
    'offset',
    'limit',
    'order_desc',
    'order_by',
    'has_events',
    'matches',
    'review_set_edit_uuid',
  ];

  useEffect(() => {
    const params = Object.entries(navParams).filter(([key]) => !ignoredParams.includes(key));

    if (params.length) {
      params.forEach(([key], index) => {
        dispatch(setFilterOrder({ key, order: index }));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navParams]);

  const removeFilter = (key: string, id: string): void => {
    const params: string | string[] = navParams[key] || '';

    if (Array.isArray(params)) {
      dispatch(
        setURLParams({
          [`${resourceQueryParamName.envelopes}__${key}`]: params.filter((p) => p !== id),
          [`envelopes__offset`]: '0',
        })
      );
      if (params.length === 1) {
        dispatch(removeFilterOrder());
      }
    } else {
      dispatch(deleteURLParams([`${resourceQueryParamName.envelopes}__${key}`]));
    }
    const treeNodeHighlighted = filtersHiglighted.find((f) => f.value === id);
    if (treeNodeHighlighted) {
      dispatch(removeSelectedFilter({ node: treeNodeHighlighted }));
    }
    dispatch(clearSelectedFields());
    dispatch(clearSelectedFilters());
    logEvent('envelopes-list-remove-filter-pill');
  };

  const removeAllFilter = (): void => {
    logEvent('envelopes-list-remove-all-filter-pill');

    dispatch(clearSelectedFilters());
    dispatch(clearSelectedFields());
  };

  const renderTree = (): JSX.Element => {
    let treeCopy = cloneDeep(customTree ?? tree);

    const completeParams = {
      ...treeParams,
      ...(navParams.sample_uuid
        ? { sample_uuid: [{ value: navParams.sample_uuid, label: '' }] }
        : {}),
      ...(navParams.created_before
        ? { created_before: [{ value: navParams.created_before, label: navParams.created_before }] }
        : {}),
      ...(navParams.created_after
        ? { created_after: [{ value: navParams.created_after, label: navParams.created_after }] }
        : {}),
    } as TreeFieldsValuesWithLabels;

    const filteredParams = Object.entries(completeParams)
      .filter(
        ([key]) =>
          ![
            'offset',
            'limit',
            'order_desc',
            'order_by',
            'has_events',
            'matches',
            'review_set_edit_uuid',
            'filters_search',
            'should_return_tree',
            'currentReview',
            'is_first_time_create_review_set',
          ].includes(key)
      )
      .sort(([key1], [key2]) =>
        FiltersOrdering?.[key1 as keyof typeof FiltersOrdering] >
        FiltersOrdering?.[key2 as keyof typeof FiltersOrdering]
          ? 1
          : -1
      );
    let dateFilter = '';

    filteredParams.forEach(([key, value]) => {
      if (key.includes('states')) {
        value.forEach((v) => {
          treeCopy = addLabelToNode(
            treeCopy,
            displayValuesMapping[v.value as keyof typeof displayValuesMapping],
            v.value,
            key.replace('_and', '')
          );
        });
      } else if (key.includes('review_values') || key.includes('review_history')) {
        value.forEach((v) => {
          treeCopy = addLabelToNode(
            treeCopy,
            reviewLabelOptions[v.value as keyof ReviewValueConfigType] || '',
            v.value,
            key.replace('_and', '')
          );
        });
      } else if (key.includes('created_after') || key.includes('created_before')) {
        if (key.includes('created_after')) {
          if (!completeParams.created_before) {
            dateFilter = `After: ${moment(value[0].value).format('MM-DD-yyyy').toString()}`;
          } else {
            dateFilter =
              dateFilter.length > 0
                ? `After: ${moment(value[0].value).format('MM-DD-yyyy').toString()} ${dateFilter}`
                : `After: ${moment(value[0].value).format('MM-DD-yyyy').toString()} `;
          }
        }

        if (key.includes('created_before')) {
          if (!completeParams.created_after) {
            dateFilter = `Before: ${moment(value[0].value).format('MM-DD-yyyy').toString()}`;
          } else {
            dateFilter =
              dateFilter.length > 0
                ? dateFilter.concat(
                    `- Before: ${moment(value[0].value).format('MM-DD-yyyy').toString()}`
                  )
                : `- Before: ${moment(value[0].value).format('MM-DD-yyyy').toString()}`;
          }
        }
        treeCopy = setNewValue(treeCopy, 'date_range', 'date_range', dateFilter, true);
      } else if (key.includes('date_range')) {
        const [createdAfter, createdBefore] = value[0].value.split('<>');
        let dFilter = '';

        if (createdAfter) {
          dFilter = `After: ${moment(createdAfter).format('MM-DD-yyyy').toString()}`;
        }

        if (createdBefore) {
          if (!createdAfter) {
            dFilter = `Before: ${moment(createdBefore).format('MM-DD-yyyy').toString()}`;
          } else {
            dFilter = dFilter.concat(
              `- Before: ${moment(createdBefore).format('MM-DD-yyyy').toString()}`
            );
          }
        }
        treeCopy = addLabelToNode(treeCopy, dFilter, value[0].value, 'date_range');
      } else if (displayValuesMappingV2[key]) {
        value.forEach((v) => {
          if (!v.label) {
            treeCopy = addLabelToNode(
              treeCopy,
              displayValuesMappingV2[key][v.value] ||
                displayValuesMappingV2[key].default ||
                v.value,
              v.value,
              key
            );
          }
        });
      } else {
        value.forEach((v) => {
          treeCopy = addLabelToNode(treeCopy, v.label || v.value, v.value, key);
        });
      }
    });
    if (!allNodesLabeled(treeCopy) && !envelopesPage) {
      return <></>;
    }

    return (
      <FilterPillsTree
        tree={treeCopy}
        removeAllFilter={removeAllFilter}
        showAllFilters={showAllFilters}
        filtersModified={filtersModified}
        scrolled={scrolled}
        removeFilter={removeFilter}
        allowDelete={allowDelete}
        envelopesPage={envelopesPage}
        renderSampleButton={renderSampleButton}
      />
    );
  };

  const params = Object.entries(navParams).filter(([key]) => !ignoredParams.includes(key));

  if (params.length === 0) {
    if (placeholder) {
      return <span className="italic">{placeholder}</span>;
    }
    return null;
  }
  const filterSectionStyles = 'flex flex-row items-center gap-1 flex-wrap flex-grow-0';

  return (
    <div className="flex items-center">
      <div
        className={`${
          envelopesPage
            ? `flex gap-1 ${!scrolled ? 'flex-wrap' : 'flex-no-wrap'}`
            : filterSectionStyles
        } `}
        style={{
          gridTemplateColumns: 'auto 1fr',
          flexWrap: showAllFilters || filtersModified ? 'wrap' : undefined,
        }}
      >
        {!envelopesPage && !loadingFilterPills && renderTree()}
        {envelopesPage && renderTree()}
      </div>
    </div>
  );
};

export default FilterPills;
