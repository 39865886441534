/* eslint-disable max-lines */

import LoadingIndicator from 'components/LoadingIndicator';
import { defaultReviewer } from 'constants/reviewSets';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getSelectedReviewer } from 'selectors/envelopeReview';
import getEnvelopeReviewLabelOptions from 'selectors/reviewStatus';

type ComponentProps = {
  declaredStatus: string;
  handleChangeDeclaredStatus: (value: string) => void;
  renderTitle?: boolean;
  name?: string;
  loading?: boolean;
  extraAnnotation?: string;
};

const StatusOptionsSection: React.FC<ComponentProps> = (props) => {
  const {
    declaredStatus,
    handleChangeDeclaredStatus,
    renderTitle = true,
    name = 'default',
    loading = false,
    extraAnnotation = '',
  } = props;

  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);
  const user = useSelector(getUser);
  const selectedReviewer = useSelector(getSelectedReviewer);

  const statusOptions = Object.entries(reviewLabelOptions)
    .filter(([key]) => key !== 'pending' && key !== 'skipped')
    .map(([key, value]) => ({ label: value, value: key }));

  const isAdmin = user.roles.includes('super-admin');
  const disableReview = isAdmin && selectedReviewer === defaultReviewer.value;

  return (
    <div className="flex flex-col gap-2 text-body">
      {renderTitle && (
        <div className="flex flex-row items-center gap-2 border-b border-litlingo-gray-1">
          <div className="font-bold pb-1">Review Status</div>
          {loading && <LoadingIndicator size="4" />}
        </div>
      )}
      <div className="flex flex-col gap-2 pl-2 text-small">
        {statusOptions.map((o) => (
          <label key={o.value} htmlFor={`radio-${name}-${o.value}`} className="flex flex-row gap-1">
            <input
              type={statusOptions.length === 1 ? 'checkbox' : 'radio'}
              id={`radio-${name}-${o.value}`}
              name={name}
              value={o.value}
              checked={declaredStatus === o.value}
              className={`${
                statusOptions.length === 1 ? 'form-checkbox litlingo-checkbox' : 'input-radio'
              }  `}
              onChange={(e): void => handleChangeDeclaredStatus(e.target.value)}
              disabled={disableReview}
            />
            <div
              className={`flex flex-row gap-1 w-full ${
                declaredStatus === o.value ? 'bg-litlingo-primary-60 bg-opacity-25' : ''
              }`}
            >
              <span>{o.label}</span>
              <span className="text-litlingo-info">
                {declaredStatus === o.value && extraAnnotation}
              </span>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default StatusOptionsSection;
