import type {
  EnvelopeFilterKeys,
  EnvelopeFilterValueType,
  EnvelopeFilterValueTypeSpec,
} from 'types';
import { platformNames } from './platform';

export const FILTER_VALUES_TYPE_SPEC: EnvelopeFilterValueTypeSpec = {
  boolean: {
    type: 'boolean',
  },
  string: {
    type: 'string',
  },
  date: {
    type: 'date',
  },
  campaign: {
    type: 'resource',
    key: 'name',
    path: 'campaign.campaigns',
  },
  tags: {
    type: 'resource',
    path: 'tags.items',
    key: 'name',
  },
  tag_value_groups: {
    type: 'resource',
    path: 'tag_value_groups.items',
    key: 'name',
  },
  team: {
    type: 'resource',
    path: 'teams.items',
    key: 'name',
  },
  rules: {
    type: 'resource',
    key: 'name',
    path: 'rules.items',
  },
  users: {
    type: 'resource',
    key: 'name',
    path: 'users.items',
  },
};

export const FILTER_VALUES_SPEC_MAPPING: {
  [key in EnvelopeFilterKeys]: EnvelopeFilterValueType;
} = {
  tags: FILTER_VALUES_TYPE_SPEC.tags,
  sender_team_uuids: FILTER_VALUES_TYPE_SPEC.sender_team_uuids,
  not_sender_team_uuids: FILTER_VALUES_TYPE_SPEC.sender_team_uuids,
  sender_team_uuids_and: FILTER_VALUES_TYPE_SPEC.sender_team_uuids_and,
  tags_and: FILTER_VALUES_TYPE_SPEC.tags,
  not_tags: FILTER_VALUES_TYPE_SPEC.tags,
  not_tags_and: FILTER_VALUES_TYPE_SPEC.tags,
  tag_value_groups: FILTER_VALUES_TYPE_SPEC.tag_value_groups,
  tag_value_groups_and: FILTER_VALUES_TYPE_SPEC.tag_value_groups,
  not_tag_value_groups: FILTER_VALUES_TYPE_SPEC.tag_value_groups,
  not_tag_value_groups_and: FILTER_VALUES_TYPE_SPEC.tag_value_groups,
  is_starred: FILTER_VALUES_TYPE_SPEC.boolean,
  same_sentence: FILTER_VALUES_TYPE_SPEC.boolean,
  key_actions: FILTER_VALUES_TYPE_SPEC.string,
  key_actions_and: FILTER_VALUES_TYPE_SPEC.string,
  not_key_actions: FILTER_VALUES_TYPE_SPEC.string,
  not_key_actions_and: FILTER_VALUES_TYPE_SPEC.string,
  campaign_uuids: FILTER_VALUES_TYPE_SPEC.campaign,
  not_campaign_uuids: FILTER_VALUES_TYPE_SPEC.campaign,
  campaign_uuids_and: FILTER_VALUES_TYPE_SPEC.campaign,
  not_campaign_uuids_and: FILTER_VALUES_TYPE_SPEC.campaign,
  rule_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  not_rule_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  rule_uuids_and: FILTER_VALUES_TYPE_SPEC.rules,
  not_rule_uuids_and: FILTER_VALUES_TYPE_SPEC.rules,
  rule_context_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  not_rule_context_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  rule_context_uuids_and: FILTER_VALUES_TYPE_SPEC.rules,
  not_rule_context_uuids_and: FILTER_VALUES_TYPE_SPEC.rules,
  annotator_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  not_annotator_uuids: FILTER_VALUES_TYPE_SPEC.rules,
  sample_uuid: FILTER_VALUES_TYPE_SPEC.string,
  sample_uuids: FILTER_VALUES_TYPE_SPEC.string,
  states: FILTER_VALUES_TYPE_SPEC.string,
  not_states: FILTER_VALUES_TYPE_SPEC.string,
  review_values: FILTER_VALUES_TYPE_SPEC.string,
  not_review_values: FILTER_VALUES_TYPE_SPEC.string,
  review_history: FILTER_VALUES_TYPE_SPEC.string,
  not_review_history: FILTER_VALUES_TYPE_SPEC.string,
  platforms: FILTER_VALUES_TYPE_SPEC.string,
  not_platforms: FILTER_VALUES_TYPE_SPEC.string,
  created_by: FILTER_VALUES_TYPE_SPEC.users,
  not_created_by: FILTER_VALUES_TYPE_SPEC.users,
  recipients: FILTER_VALUES_TYPE_SPEC.users,
  not_recipients: FILTER_VALUES_TYPE_SPEC.users,
  reviewed_by_user_uuids: FILTER_VALUES_TYPE_SPEC.users,
  not_reviewed_by_user_uuids: FILTER_VALUES_TYPE_SPEC.users,
  created_after: FILTER_VALUES_TYPE_SPEC.tags,
  created_before: FILTER_VALUES_TYPE_SPEC.tags,
  has_events: FILTER_VALUES_TYPE_SPEC.boolean,
  inbound: FILTER_VALUES_TYPE_SPEC.boolean,
  is_external: FILTER_VALUES_TYPE_SPEC.boolean,
  is_read: FILTER_VALUES_TYPE_SPEC.boolean,
  is_review_confirmed: FILTER_VALUES_TYPE_SPEC.boolean,
  broad_search: FILTER_VALUES_TYPE_SPEC.string,
  integration_types: FILTER_VALUES_TYPE_SPEC.string,
  body_campaign_uuids: FILTER_VALUES_TYPE_SPEC.string,
  not_body_campaign_uuids: FILTER_VALUES_TYPE_SPEC.string,
  body_rule_uuids: FILTER_VALUES_TYPE_SPEC.string,
  not_body_rule_uuids: FILTER_VALUES_TYPE_SPEC.string,
  body_campaign_uuids_and: FILTER_VALUES_TYPE_SPEC.string,
  not_body_campaign_uuids_and: FILTER_VALUES_TYPE_SPEC.string,
  attachment_campaign_uuids: FILTER_VALUES_TYPE_SPEC.string,
  not_attachment_campaign_uuids: FILTER_VALUES_TYPE_SPEC.string,
  attachment_rule_uuids: FILTER_VALUES_TYPE_SPEC.string,
  not_attachment_rule_uuids: FILTER_VALUES_TYPE_SPEC.string,
  has_attachments: FILTER_VALUES_TYPE_SPEC.boolean,
  has_attachment_events: FILTER_VALUES_TYPE_SPEC.boolean,
  has_translation: FILTER_VALUES_TYPE_SPEC.boolean,
  subject: FILTER_VALUES_TYPE_SPEC.string,
  body: FILTER_VALUES_TYPE_SPEC.string,
  attachment_text: FILTER_VALUES_TYPE_SPEC.string,
  date_range: FILTER_VALUES_TYPE_SPEC.string,
  has_comments: FILTER_VALUES_TYPE_SPEC.boolean,
  days_from_oor: FILTER_VALUES_TYPE_SPEC.string,
  rule_branch_uuid: FILTER_VALUES_TYPE_SPEC.string,
  sender_domain: FILTER_VALUES_TYPE_SPEC.string,
  recipient_domains: FILTER_VALUES_TYPE_SPEC.string,
  not_sender_domain: FILTER_VALUES_TYPE_SPEC.string,
  not_recipient_domains: FILTER_VALUES_TYPE_SPEC.string,
  sender_domain_and: FILTER_VALUES_TYPE_SPEC.string,
  recipient_domains_and: FILTER_VALUES_TYPE_SPEC.string,
  not_recipient_domains_and: FILTER_VALUES_TYPE_SPEC.string,
  not_sender_domain_and: FILTER_VALUES_TYPE_SPEC.string,
  uuid: FILTER_VALUES_TYPE_SPEC.string,
};

export const filterKeysMapping: { [key in EnvelopeFilterKeys]: string } = {
  tags: 'Tags',
  tags_and: 'Tags',
  not_tags: 'Not Tags',
  not_tags_and: 'Not Tags',
  tag_value_groups: 'Tag Groups',
  tag_value_groups_and: 'Tag Groups',
  not_tag_value_groups: 'Not Tag Groups',
  not_tag_value_groups_and: 'Not Tag Groups',
  sender_team_uuids_and: 'Divisions',
  sender_team_uuids: 'Divisions',
  not_sender_team_uuids: 'Not Divisions',
  is_starred: 'Starred',
  same_sentence: 'Same sentence',
  states: 'Status',
  not_states: 'Not Status',
  review_values: 'Status',
  not_review_values: 'Not Status',
  review_history: 'Review History',
  not_review_history: 'Not Review HistoryStatus',
  key_actions: 'Actions',
  key_actions_and: 'Actions',
  not_key_actions: 'Not Actions',
  has_comments: 'Comments',
  not_key_actions_and: 'Not Actions',
  campaign_uuids: 'Use Cases',
  campaign_uuids_and: 'Use Cases',
  not_campaign_uuids: 'Not Use Cases',
  not_campaign_uuids_and: 'Not Use Cases',
  rule_uuids: 'Models',
  not_rule_uuids: 'Not Models',
  rule_uuids_and: 'Models',
  not_rule_uuids_and: 'Not Models',
  rule_context_uuids: 'Context',
  not_rule_context_uuids: 'Not Context',
  rule_context_uuids_and: 'Context',
  not_rule_context_uuids_and: 'Not Context',
  annotator_uuids: 'Identifiers',
  not_annotator_uuids: 'Not Identifiers',
  sample_uuid: 'Sample Set',
  sample_uuids: 'Sample Set',
  platforms: 'Platform',
  created_by: 'Senders',
  recipients: 'Recipients',
  reviewed_by_user_uuids: 'Reviewers',
  not_platforms: 'Not Platform',
  not_created_by: 'Not Senders',
  not_recipients: 'Not Recipients',
  not_reviewed_by_user_uuids: 'Not Reviewers',
  created_after: 'After',
  created_before: 'Before',
  has_events: 'Has events',
  inbound: 'Direction',
  is_external: 'Source',
  is_read: 'Read',
  is_review_confirmed: 'Confirmed Review',
  broad_search: 'Search',
  integration_types: 'LitLingo Product',
  body_campaign_uuids: 'Use Cases in Body',
  not_body_campaign_uuids: 'Not Use Cases in Body',
  body_rule_uuids: 'Models in Body',
  not_body_rule_uuids: 'Not Models in Body',
  body_campaign_uuids_and: 'Use Cases in Body',
  not_body_campaign_uuids_and: 'Not Use Cases in Body',
  attachment_campaign_uuids: 'Use Cases in Attachment',
  not_attachment_campaign_uuids: 'Not Use Cases in Attachment',
  attachment_rule_uuids: 'Models in Attachment',
  not_attachment_rule_uuids: 'Not Models in Attachment',
  has_attachments: 'Has Attachments',
  has_attachment_events: 'Has Hits in Attachment',
  has_translation: 'Has Translation',
  subject: 'Subject',
  body: 'Body',
  attachment_text: 'Attachment',
  date_range: 'Date Range',
  days_from_oor: 'Retention Window',
  rule_branch_uuid: 'Branch hits',
  sender_domain: 'Sender Domain',
  recipient_domains: 'Recipient Domain',
  not_sender_domain: 'Not Sender Domain',
  not_recipient_domains: 'Not Recipient Domain',
  sender_domain_and: 'Sender Domain',
  recipient_domains_and: 'Recipient Domain',
  not_recipient_domains_and: 'Not Recipient Domain',
  not_sender_domain_and: 'Not Sender Domain',
  uuid: 'Messages',
};

export const displayValuesMapping = {
  NEW: 'Pending',
  contains_issues: 'Violation',
  no_issue: 'Acceptable',
  sent_out_of_policy: 'Out of policy',
  timeout: 'Timeout',
  show_modal: 'Show modal',
  back_to_edit: 'Back to edit',
  send_anyways: 'Out of policy',
  sent_within_policy: 'Sent within policy',
  state_change: 'State change',
  comment: 'Comments',
  author_notify: 'Author notified',
  app: 'Prevent',
  api: 'Insight',
};

export const displayValuesMappingV2: Record<string, Record<string, string>> = {
  inbound: { true: 'Inbound', false: 'Outbound' },
  is_external: { true: 'External', false: 'Internal' },
  has_comments: { true: 'Has Comments', false: 'No Comments' },
  is_review_confirmed: { true: 'Yes', false: 'No' },
  integration_types: { app: 'Prevent', api: 'Insight' },
  has_attachments: { true: 'Has Attachments', false: 'No Attachments' },
  has_translation: { true: 'Has Translation', false: 'No Translation' },
  key_actions: {
    send_anyways: 'Sent out of policy',
    timeout: 'Timeout',
    show_modal: 'Show modal',
    back_to_edit: 'Back to edit',
    sent_within_policy: 'Sent within policy',
    state_change: 'State change',
    author_notify: 'Author notified',
    o365_teams_tombstone: 'Teams Message Blocked',
  },
  rule_branch_uuid: { default: 'True', true: 'True', false: 'False' },
  days_from_oor: {
    '1': 'Day',
    '7': 'Week',
    '30': 'Month',
  },
  sample_uuid: { default: 'True', true: 'True', false: 'False' },
  // sample_uuids: { default: 'True', true: 'True', false: 'False' },
  platforms: { ...platformNames },
};

export const andFilters = [
  'campaign_uuids_and',
  'not_campaign_uuids_and',
  'rule_uuids_and',
  'not_rule_uuids_and',
  'not_rule_context_uuids_and',
  'rule_context_uuids_and',
  'tags_and',
  'not_tags_and',
  'tag_value_groups_and',
  'not_tag_value_groups_and',
  'key_actions_and',
  'not_key_actions_and',
  'sender_team_uuids_and',
  'not_sender_team_uuids_and',
  'body_campaign_uuids_and',
  'not_body_campaign_uuids_and',
  'reviewed_by_user_uuids_and',
  'not_reviewed_by_user_uuids_and',
  'annotator_uuids_and',
  'not_annotator_uuids_and',
  'body_rule_uuids_and',
  'not_body_rule_uuids_and',
  'attachment_campaign_uuids_and',
  'not_attachment_campaign_uuids_and',
  'attachment_rule_uuids_and',
  'not_attachment_rule_uuids_and',
  'created_by_and',
  'not_created_by_and',
  'recipients_and',
  'not_recipients_and',
  'platforms_and',
  'not_platforms_and',
  'sender_domain_and',
  'not_sender_domain_and',
  'recipient_domains_and',
  'not_recipient_domains_and',
];
export const orFilters = [
  'campaign_uuids',
  'not_campaign_uuids',
  'rule_uuids',
  'not_rule_uuids',
  'rule_context_uuids',
  'not_rule_context_uuids',
  'tags',
  'not_tags',
  'tag_value_groups',
  'not_tag_value_groups',
  'key_actions',
  'not_key_actions',
  'sender_team_uuids',
  'body_campaign_uuids',
  'not_body_campaign_uuids',
  'sender_domain',
  'not_sender_domain',
  'recipient_domains',
  'not_recipient_domains',
  'uuid',
];
