/* eslint-disable camelcase */
/* eslint-disable max-lines */
import { clearModelMetricType } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'utils/urls';

const ModelsMetricsHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddNew = (): void => {
    history.pushLookup({
      routeName: 'create-models-metrics',
    });
    dispatch(clearModelMetricType());
  };

  return (
    <div className="flex flex-row justify-between">
      <span className="text-heading-1">Performance Metrics</span>
      <button type="button" className="button button--primary h-8" onClick={handleAddNew}>
        Add New
      </button>
    </div>
  );
};

export default ModelsMetricsHeader;
