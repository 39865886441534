/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { cloneIdentifier, setCompareModeIdentifier } from 'actions/identifier';
import CustomerSimpleList from 'components/CustomerSimpleList';
import Modal from 'components/Modal';
import SplitButton from 'components/SplitButton';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getHasStaging, getSelectedIdentifierRevision } from 'selectors/identifier';
import { useSelector } from 'store';
import { useClampText } from 'use-clamp-text';
import EditIdentifierModal from './EditIdentifierModal';

type RouterParams = { identifierId: string };

const IdentifierHeader: React.FC = () => {
  const { identifierId } = useParams<RouterParams>();
  const dispatch = useDispatch();

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const hasStaging = useSelector(getHasStaging);

  const [isEditingIdentifier, setIsEditingIdentifier] = useState(false);
  const [isCloneIdentifierOpen, setIsCloneIdentifierOpen] = useState(false);

  const [showMore] = useState(false);

  const [descriptionRef, { clampedText: descriptionClampedText, key: descriptionKey }] =
    useClampText({
      text: `${capitalize(identifierRevision?.kind)} matcher ${
        identifierRevision?.description ? `: ${identifierRevision?.description}` : ''
      }`,
      lines: 2,
      ellipsis: 12.5,
      expanded: showMore,
    });

  const toggleIsCloneIdentifierOpen = (): void => {
    setIsCloneIdentifierOpen(!isCloneIdentifierOpen);
  };

  const handleClinkOnEdit = (): void => {
    setIsEditingIdentifier(true);
  };

  const handleCloneIdentifier = (): void => {
    dispatch(cloneIdentifier({ identifierId, redirect: true }));
  };

  const toggleEditModal = (): void => setIsEditingIdentifier(false);

  const renderIdentifierScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneIdentifierOpen}
      resourceId={identifierId}
      cloneResource={cloneIdentifier}
    />
  );

  const handleCompareModeClick = (): void => {
    dispatch(setCompareModeIdentifier(true));
  };

  const options = [
    {
      label: 'Compare Staging to Prod',
      action: handleCompareModeClick,
      disabled: !hasStaging,
    },
    {
      label: 'Duplicate',
      action: handleCloneIdentifier,
    },
  ];

  if (!identifierRevision) return null;

  return (
    <header className="flex flex-col pt-4 px-4 pb-1 bg-white">
      <div className="flex flex-col justify-between gap-4 bg-white">
        <div className="flex flex-col gap-1.5">
          <div className="w-full flex flex-row justify-between items-baseline gap-2 text-body">
            <span className="font-bold break-words">
              {identifierRevision && identifierRevision.name}
            </span>
            <button
              type="button"
              className="flex-none w-5 self-center focus:outline-none"
              onClick={handleClinkOnEdit}
            >
              {NEW_EDIT_ICON('text-litlingo-primary-120')}
            </button>
          </div>
          {identifierRevision && identifierRevision.description && (
            <div className="flex flex-col h-full justify-start gap-2">
              <div ref={descriptionRef as React.LegacyRef<HTMLDivElement>} key={descriptionKey}>
                {descriptionClampedText}
              </div>
            </div>
          )}
          <div className="flex flex-row justify-end gap-1 w-full pr-2 pb-4">
            <div className="w-30 h-8">
              <SplitButton
                buttonStyle="secondary"
                primaryLabel="Options"
                primaryAction={(): void => {
                  // moot
                }}
                options={options}
                dropdownDirection="down"
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>

      {isCloneIdentifierOpen && (
        <Modal
          body={renderIdentifierScreen}
          title="Choose a customer"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleIsCloneIdentifierOpen}
          toggleShowModal={toggleIsCloneIdentifierOpen}
        />
      )}
      {isEditingIdentifier && (
        <EditIdentifierModal
          isModalOpen={isEditingIdentifier}
          toggleModalOpen={toggleEditModal}
          identifier={identifierRevision}
        />
      )}
    </header>
  );
};

export default IdentifierHeader;
