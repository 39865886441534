/* eslint-disable max-lines */

import { deleteURLParams, setURLParams, showErrorAlert } from 'actions';
import { setTimeRange } from 'actions/dashboard';
import DateRangePicker from 'components/Calendar';
import SelectRedesign from 'components/Select/SelectRedesign';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { DateRange, TZDate } from 'react-day-picker';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSyncDates } from 'selectors/auth';
import { getDashboardState, getDashboardTimeRange } from 'selectors/dashboard';
import { useDeepCompareSelector, useSelector } from 'store';
import logEvent from 'utils/analytics';
import { isValidDate } from 'utils/dateValidation';
import {
  TIME_RANGE_FIELD,
  UNIT_OF_TIME,
  days,
  halfYearToDate,
  hours,
  yearToDate,
} from 'utils/timeRanges';

export const dateOptions: {
  label: string;
  value: string;
  className?: string;
}[] = [
  { label: 'Date Range', value: 'date-range' },
  { label: 'Before', value: 'before' },
  { label: 'After', value: 'after', className: 'border-b border-litlingo-gray-2' },
];

export const timeRangeOptions: {
  label: string;
  value: string;
  className?: string;
}[] = [
  { label: 'Past 24 Hours', value: hours(-24) },
  { label: 'Past 7 Days', value: days(-7) },
  { label: 'Past 30 Days', value: days(-30) },
  { label: 'Past 60 Days', value: days(-60) },
  { label: 'Past 90 Days', value: days(-90) },
  { label: 'Past 6 Months', value: halfYearToDate() },
  { label: 'Year to Date', value: yearToDate() },
];

const DashboardDateFilter: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { activeUuid } = useParams<{ activeUuid: string }>();
  const dashboardState = useSelector(getDashboardState(activeUuid));
  const syncDates = useSelector(getSyncDates);

  const [showCalendar, setShowCalendar] = useState(false);

  const { after, before } = useDeepCompareSelector(getDashboardTimeRange);

  const createdAfter = after;
  const createdBefore = before;

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  const [dateOption, setDateOption] = useState<string>('date-range');

  moment.tz.setDefault(dashboardState.primary_timezone);

  const createdAfterDate = isValidDate(createdAfter)
    ? new TZDate(moment(createdAfter).format(), dashboardState.primary_timezone)
    : undefined;
  const createdBeforeDate = isValidDate(createdBefore)
    ? new TZDate(moment(createdBefore).format(), dashboardState.primary_timezone)
    : undefined;

  useEffect(() => {
    if (createdAfterDate) {
      setFromValue(moment(createdAfterDate).format('MM/DD/yyyy'));
    } else {
      setFromValue('');
    }

    if (createdBeforeDate) {
      setToValue(moment(createdBeforeDate).format('MM/DD/yyyy'));
    } else {
      setToValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdAfterDate?.toString(), createdBeforeDate?.toString()]);

  useEffect(() => {
    if (fromValue && !toValue) {
      setDateOption('after');
    }
    if (!fromValue && toValue) {
      setDateOption('before');
    }
  }, [toValue, fromValue]);

  const getDateRangeOptions = (): {
    label: string;
    value: string;
    className?: string;
  }[] => {
    const fullDateOptions = [...dateOptions, ...timeRangeOptions];
    if (syncDates[0] && syncDates[1]) {
      fullDateOptions.push({ label: 'Investigation Period', value: 'sync-range' });
    }
    return fullDateOptions;
  };

  const handleSetDateRange = (range: DateRange): void => {
    logEvent('envelopes-list-filter-date');

    const { from, to } = range;

    const fromDate = from ? moment(from) : null;
    const toDate = to ? moment(to) : null;

    const fromUrl = fromDate ? fromDate.startOf('day').utc().format() : null;
    const toUrl = toDate ? toDate.endOf('day').utc().format() : null;

    if (fromUrl && !toUrl) {
      const toUrlParam = moment(from).endOf('day').utc().format() ?? '';
      dispatch(
        setURLParams({
          [TIME_RANGE_FIELD]: `${fromUrl};${toUrlParam}`,
        })
      );
      dispatch(setTimeRange({ after: fromUrl as string, before: toUrlParam as string }));
    } else {
      dispatch(
        setURLParams({
          [TIME_RANGE_FIELD]: `${fromUrl};${toUrl}`,
        })
      );
      dispatch(setTimeRange({ after: fromUrl as string, before: toUrl as string }));
    }
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setFromValue(value);

    const fromDate = moment(value, 'MM/DD/YYYY', true);

    if (createdBeforeDate && moment(createdBeforeDate).isValid()) {
      const beforeDate = moment(createdBeforeDate);
      if (fromDate.isAfter(beforeDate)) {
        dispatch(showErrorAlert('Invalid Date Range'));
        return;
      }
    }

    if (fromDate.isValid()) {
      handleSetDateRange({
        from: fromDate.toDate(),
        to: moment(toValue, 'MM/DD/YYYY', true).toDate(),
      });
    } else if (value.length > 9) {
      dispatch(showErrorAlert('Invalid Date'));
    } else if (value.length === 0) {
      handleSetDateRange({ from: undefined, to: moment(toValue, 'MM/DD/YYYY', true).toDate() });
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setToValue(value);

    const toDate = moment(value, 'MM/DD/YYYY', true);

    if (createdAfterDate && moment(createdAfterDate)) {
      const afterDate = moment(createdAfterDate);
      if (toDate.isBefore(afterDate)) {
        dispatch(showErrorAlert('Invalid Date Range'));
        return;
      }
    }

    if (toDate.isValid()) {
      handleSetDateRange({
        from: moment(fromValue, 'MM/DD/YYYY', true).toDate(),
        to: toDate.toDate(),
      });
    } else if (value.length > 9) {
      dispatch(showErrorAlert('Invalid Date'));
    } else if (value.length === 0) {
      handleSetDateRange({ from: moment(fromValue, 'MM/DD/YYYY', true).toDate(), to: undefined });
    }
  };

  const defaultSelected: DateRange = {
    from: createdAfterDate,
    to: createdBeforeDate,
  };

  const handleDateOptionChanged = (value: string): void => {
    const prevOption = dateOption;
    setDateOption(value);

    if (value === 'after') {
      if (prevOption === 'before') {
        dispatch(
          setURLParams({
            [TIME_RANGE_FIELD]: `${before};`,
          })
        );
        dispatch(setTimeRange({ after: before as string, before: '' }));
      } else {
        dispatch(
          setURLParams({
            [TIME_RANGE_FIELD]: `${after};`,
          })
        );
        dispatch(setTimeRange({ after, before: '' }));
      }
    } else if (value === 'before') {
      if (prevOption === 'after') {
        dispatch(
          setURLParams({
            [TIME_RANGE_FIELD]: `;${after};`,
          })
        );
        dispatch(setTimeRange({ after: '', before: after as string }));
      } else {
        dispatch(
          setURLParams({
            [TIME_RANGE_FIELD]: `;${before};`,
          })
        );
        dispatch(setTimeRange({ after: '', before }));
      }
    } else if (value === 'sync-range') {
      logEvent(`envelopes-date-filter-${value}`);
      const [syncStart, syncEnd] = syncDates;

      if (syncStart && syncEnd) {
        dispatch(
          setURLParams({
            [TIME_RANGE_FIELD]: `${moment(syncStart).utc().format() as string};${
              moment(syncEnd).utc().format() as string
            }`,
          })
        );
        dispatch(
          setTimeRange({
            after: moment(syncStart).utc().format() as string,
            before: moment(syncEnd).utc().format() as string,
          })
        );
      }
    } else if (value !== 'date-range') {
      logEvent(`Dashboard-date-filter-${value}`);
      dispatch(setURLParams({ [TIME_RANGE_FIELD]: value }));
      dispatch(deleteURLParams([UNIT_OF_TIME]));
    }
  };

  return (
    <div
      aria-hidden
      ref={ref}
      className="flex flex-col gap-2"
      onClick={(): void => setShowCalendar(true)}
    >
      <div className="bg-litlingo-success-light bg-opacity-10 rounded">
        <SelectRedesign
          className="bg-transparent border-none"
          valueClassName="text-white px-0"
          optionClassname="text-litlingo-gray-6"
          onChange={(value): void => {
            handleDateOptionChanged(value);
          }}
          options={getDateRangeOptions()}
          value={dateOption}
        />
      </div>
      {dateOption === 'after' || dateOption === 'before' || dateOption === 'date-range' ? (
        <div className="w-full h-8 flex flex-row justify-start gap-1 py-1.5 text-body px-2 bg-litlingo-success-light bg-opacity-10 rounded">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.5 2.75C5.5 2.33579 5.16421 2 4.75 2C4.33579 2 4 2.33579 4 2.75V4H3C1.89543 4 1 4.89543 1 6V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V6C19 4.89543 18.1046 4 17 4H16.5V2.75C16.5 2.33579 16.1642 2 15.75 2C15.3358 2 15 2.33579 15 2.75V4H5.5V2.75ZM17.5 8H2.5V17C2.5 17.2761 2.72386 17.5 3 17.5H17C17.2761 17.5 17.5 17.2761 17.5 17V8Z"
              fill="white"
            />
          </svg>

          <div className="w-full flex flex-row gap-1">
            {dateOption !== 'before' && (
              <input
                data-testid="date-filter-input"
                type="text"
                placeholder={moment().format('MM/DD/yyyy')}
                className="text-white bg-transparent placeholder-litlingo-gray-3 w-19"
                value={fromValue}
                onChange={handleFromChange}
              />
            )}
            {dateOption !== 'after' && dateOption !== 'before' && (
              <span className="text-white">-</span>
            )}
            {dateOption !== 'after' && (
              <input
                data-testid="date-filter-input"
                type="text"
                placeholder={moment().format('MM/DD/yyyy')}
                className="text-white bg-transparent placeholder-litlingo-gray-3 w-19"
                value={toValue}
                onChange={handleToChange}
              />
            )}
          </div>
        </div>
      ) : null}
      {showCalendar && (
        <DateRangePicker
          containerRef={ref}
          defaultRange={defaultSelected}
          setShowCalendar={(): void => setShowCalendar(!showCalendar)}
          setDateRange={handleSetDateRange}
          dateOption={dateOption}
          timeZone={dashboardState.primary_timezone}
        />
      )}
    </div>
  );
};

export default DashboardDateFilter;
