/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { fetchSingleEnvelope } from 'actions';
import { setEnvelopePreview } from 'actions/envelopeListView';
import KeyActionsIcons from 'components/KeyActionsIcons/KeyActionsIconsRedesign';
import Redacted from 'components/Redacted';
import Tooltip from 'components/Tooltip';
import { COMMENT_ICON, NOTIFY_AUTHOR_ICON, SHIELD_ICON } from 'constants/envelopeIcons';
import { platformTooltipParser } from 'constants/platform';
import { platformIcon } from 'constants/platformIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getEnvelopeToPreview, getOrderByNewParam } from 'selectors/envelopeListView';
import {
  getEnvelopeInFlaggedIds,
  getFetchFlaggedTextLoading,
  getSummaryTagFromSummaries,
} from 'selectors/envelopes';
import { getEventSummaryLoading } from 'selectors/events';
import { getNavParamsByResourceMemo, getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary, User } from 'types';
import logEvent from 'utils/analytics';
import parseBody from 'utils/strings';
import EnvelopeListCampaignsRules from './EnvelopeListCampaignsRules';
import EnvelopesListStatusDropdown from './EnvelopeListStatusDropdown';

import NoMatchSentences from './NoMatchSentences';
import RenderTags from './RenderTags';
import WholeSentence from './WholeSentence';

type Sender = (name: string, isRead: boolean) => JSX.Element;

const sender: Sender = (name) => <span className="text-litlingo-gray-6 text-xss">{name}</span>;

type GetDate = (date: string, isRead: boolean) => JSX.Element;

const getDate: GetDate = (date) => {
  const fixedDate = date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
  const eventDate = moment(fixedDate).utc().local();
  const dateToShow = eventDate.format('YYYY MMM DD hh:mm A z');

  return <span className="text-litlingo-gray-6 text-xss">on {dateToShow}</span>;
};

type GetTeams = (user: User) => JSX.Element | null;

const getTeams: GetTeams = (user) => {
  const { teams } = user;

  if (!teams || teams.length === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-center gap-1">
      <span>in</span>
      <div className="flex flex-row items-center gap-1">
        {teams.map((t, idx, arr) => (
          <span key={t.uuid}>{`${t.name}${idx === arr.length - 1 ? '' : ','}`}</span>
        ))}
      </div>
    </div>
  );
};

type HasKey = (keys: string[], key: string) => boolean;

const hasKey: HasKey = (keys, key) => {
  if (keys) {
    return keys.includes(key);
  }
  return false;
};

type ComponentProps = {
  data: CommunicationEnvelopeSummary;
  envelopeSelected: boolean;
  expanded: boolean;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, item: CommunicationEnvelopeSummary) => void;
  envelopeIdx: number;
};

const EnvelopeListRow: React.FC<ComponentProps> = ({
  data,
  handleCheck,
  envelopeSelected,
  expanded,
  envelopeIdx,
}) => {
  const { envelope, key_actions, events, is_review_confirmed } = data;

  const dispatch = useDispatch();

  const [selectedRule, setSelectedRule] = useState<string | null>(null);

  const loading = useSelector(getEventSummaryLoading);
  const flaggedTextLoading = useSelector((state) =>
    getFetchFlaggedTextLoading(state, envelope.uuid)
  );
  const isEnvelopeInFlaggedIds = useSelector((state) =>
    getEnvelopeInFlaggedIds(state, envelope.uuid)
  );
  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );
  const envelopeParams = useSelector(getNavParamsFromTree);
  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const isOrderingByNewParam = useSelector(getOrderByNewParam);
  const summaryTag = useSelector((state) => getSummaryTagFromSummaries(state, data.envelope.uuid));
  const user = useSelector(getUser);

  const isAdmin = user.roles.includes('super-admin');
  const isRead = envelope.is_read;
  const { name } = envelope.created_by || {};
  const envelopeTags = envelope.tags?.filter((t) => t.tag_value !== null);

  const handleEnvelopePreview = (e: React.MouseEvent<HTMLButtonElement>): void => {
    dispatch(setEnvelopePreview({ envelopeId: envelope.uuid, envelopeIdx }));
    dispatch(fetchSingleEnvelope({ envelopeId: envelope.uuid, preview: true }));
    e.stopPropagation();
  };

  const getColValue = (): string | null | undefined => {
    if (navParams.order_by === 'sent_at') {
      return moment(data.sent_at).format('MMMM DD, YYYY');
    }
    if (navParams.order_by === 'reviewed_at') {
      return moment(data.envelope.reviewed_at).format('MMMM DD, YYYY');
    }
    if (navParams.order_by === 'review_value') {
      return data.envelope.review_value;
    }
    if (navParams.order_by === 'user_name') {
      return data.envelope.created_by?.name;
    }
    if (navParams.order_by === 'subject') {
      return events && events[events.length - 1]?.subject;
    }
    return '';
  };

  const renderSentences = (): JSX.Element[] | [] => {
    const sentences: JSX.Element[] = [];
    let communications: string[] = [];

    const uniqueRulesEvents = data.events.filter(
      (e, i, arr) => arr.findIndex((ev) => ev.rule_uuid === e.rule_uuid) === i
    );

    uniqueRulesEvents.forEach((e) => {
      const annotated = e.rule_uuid === selectedRule;
      if (annotated && e.communication_uuid) {
        communications.push(e.communication_uuid);
      }
    });

    uniqueRulesEvents.forEach((e) => {
      const annotated = e.rule_uuid === selectedRule;
      if ((expanded || annotated) && e.communication_uuid) {
        const communicationRules = data.events.reduce<string[]>((acc, ev) => {
          if (ev.communication_uuid === e.communication_uuid) {
            return [...acc, ev.rule_uuid || ''];
          }
          return acc;
        }, []);

        if (
          !communications.some((c) =>
            data.events.some(
              (ev) =>
                ev.communication_uuid === c &&
                ev.rule_uuid &&
                communicationRules.includes(ev.rule_uuid)
            )
          )
        ) {
          communications.push(e.communication_uuid);
        }
      }
    });
    communications = communications.filter((c, i, arr) => arr.indexOf(c) === i);

    if (data.events?.length === 0) {
      return [
        <div key={envelope.uuid} className="mb-1">
          <NoMatchSentences loading={loading} envelopeId={data.envelope.uuid} />
        </div>,
      ];
    }

    communications.forEach((c) => {
      sentences.push(
        <div key={c} className="mb-1">
          <WholeSentence
            loading={loading}
            envelopeId={data.envelope.uuid}
            communicationId={c}
            selectedRuleId={selectedRule || ''}
          />
        </div>
      );
    });
    return sentences;
  };

  const renderFlaggedText = (): JSX.Element => {
    const useSearchMatch = !!envelopeParams.broad_search;

    if ((flaggedTextLoading || !isEnvelopeInFlaggedIds) && !useSearchMatch) {
      return (
        <div className="w-40">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      );
    }

    if (useSearchMatch) {
      const bodyHighlights = data.search_highlights?.body?.toString() || '';
      const subjectHighlights = data.search_highlights?.subjects?.toString() || '';

      return (
        <Redacted field="events.audit">
          {subjectHighlights && (
            <ReactMarkdown className="elastic-highlights" allowDangerousHtml>
              {parseBody(subjectHighlights)}
            </ReactMarkdown>
          )}
          {bodyHighlights && (
            <ReactMarkdown className="elastic-highlights" allowDangerousHtml>
              {parseBody(bodyHighlights)}
            </ReactMarkdown>
          )}
        </Redacted>
      );
    }
    return <Redacted field="events.audit">{renderSentences()}</Redacted>;
  };

  if (!data.envelope.uuid) return null;

  return (
    <>
      <td className="table-wrapper__new-td">
        <div
          className={`table-wrapper__new-cell-content flex ${
            envelopeToPreview?.envelopeId === envelope.uuid
              ? 'border-l-5 border-litlingo-primary-120'
              : ''
          }`}
          style={{
            paddingLeft: envelopeToPreview?.envelopeId === envelope.uuid ? '3px' : '',
          }}
        >
          <input
            id={`checkbox-envelope-${envelope.uuid}`}
            data-testid="select-button"
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleCheck(e, data);
              logEvent('envelopes-list-select-one');
            }}
            checked={envelopeSelected}
          />
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="pt-2  text-base text-litlingo-gray-4">{envelopeIdx + 1}</div>
      </td>

      {isOrderingByNewParam && navParams.order_by !== 'random' && (
        <td className="table-wrapper__new-td bg-litlingo-focus-area-background px-1 py-2 text-base">
          {getColValue()}
        </td>
      )}

      <td
        className={`table-wrapper__new-td ${
          isOrderingByNewParam && isOrderingByNewParam ? 'pl-1' : ''
        }`}
      >
        <div
          className="table-wrapper__new-cell-content flex flex-col gap-1"
          style={{ height: expanded ? 'auto' : '' }}
        >
          {events && events[events.length - 1]?.subject && (
            <div className="font-normal text-base subject">
              {events && events[events.length - 1]?.subject}
            </div>
          )}
          <div className="flex flex-col justify-start text-small">
            <div className="flex flex-row items-center h-4">
              <span className="mr-1 truncate">
                <Redacted field="communication_envelopes.created_by">
                  <>
                    <span>Sent by </span>
                    {sender(name as string, isRead)}
                  </>
                </Redacted>
              </span>
              <span className="mr-1">via</span>
              <div className="flex justify-center items-center">
                <Tooltip
                  tooltip={`platforms.${platformTooltipParser(
                    envelope.platform,
                    envelope.integration_type
                  )}`}
                  redesign
                >
                  <div className="w-4">
                    {platformIcon(envelope.platform, envelope.integration_type)}
                  </div>
                </Tooltip>
              </div>
            </div>
            {expanded && data.envelope.created_by && getTeams(data.envelope.created_by)}
            <div className="h-4">{data.sent_at != null && getDate(data.sent_at, isRead)}</div>
          </div>

          <div className="flex justify-start items-center">
            {key_actions && (
              <KeyActionsIcons
                keys={key_actions}
                envelopeUuid={data.envelope.uuid}
                summaryTag={summaryTag}
                platform={envelope.platform}
              />
            )}
          </div>
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div
          className="flex flex-col table-wrapper__new-cell-content"
          style={{ height: expanded ? 'auto' : '' }}
        >
          <EnvelopeListCampaignsRules
            events={data.events}
            setSelectedRule={setSelectedRule}
            selectedRule={selectedRule}
            expanded={expanded}
          />
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div
          className="table-wrapper__new-cell-content font-normal text-base"
          style={{ height: expanded ? 'auto' : '' }}
        >
          {renderFlaggedText()}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div
          className="table-wrapper__new-cell-content h-full flex flex-col gap-1 justify-between overflow-visible"
          style={{ height: 'auto', minHeight: '110px' }}
        >
          <div className="flex flex-col gap-1 justify-between">
            <div>
              <div>
                {data.events && <EnvelopesListStatusDropdown data={data} disabled={isAdmin} />}
              </div>

              {envelopeTags && (
                <div className="mt-1 font-normal text-base">
                  {envelopeTags.length > 0 && (
                    <span>
                      {envelopeTags.length} {pluralize('tag', envelopeTags.length)}
                    </span>
                  )}
                  <div className="mt-1">
                    <RenderTags tags={envelopeTags} expanded={expanded} envelope={envelope} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <div className="flex justify-start gap-1">
              {hasKey(key_actions, 'author_notify') ? (
                <Tooltip tooltip="envelopesListActions.author_notify" redesign>
                  <div className="w-5">{NOTIFY_AUTHOR_ICON(true)}</div>
                </Tooltip>
              ) : null}
              {hasKey(key_actions, 'comment') ? (
                <Tooltip tooltip="envelopesListActions.comment" redesign>
                  <div className="w-5">{COMMENT_ICON(true)}</div>
                </Tooltip>
              ) : null}
            </div>
            <div className="w-4">{is_review_confirmed && SHIELD_ICON}</div>
          </div>
          <div className="self-end mr-1 table-wrapper__new-td--view-section">
            <button type="button" className="focus:outline-none" onClick={handleEnvelopePreview}>
              <span
                data-testid="show-all-button"
                className="self-end ml-2 whitespace-no-wrap underline text-litlingo-primary-120 text-base "
              >
                View
              </span>
            </button>
          </div>
        </div>
      </td>
    </>
  );
};

export const MemoizedEnvelopeListRow = React.memo(EnvelopeListRow);

export default EnvelopeListRow;
