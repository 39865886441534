import React from 'react';
import { Tag } from 'types';

interface Props {
  tag: Tag;
  selected: boolean;
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const TagListItem: React.VFC<Props> = ({ tag, selected, setSelectedTags }) => {
  const { uuid, value, description } = tag;

  const handleTagGroupSelect = (checked: boolean): void => {
    if (checked) {
      setSelectedTags((prev) => [...prev, uuid]);
    } else {
      setSelectedTags((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    handleTagGroupSelect(e.target.checked);
  };

  return (
    <tr className="border-t border-gray-200 hover:bg-gray-100 table-row h-10">
      <td className="table-wrapper__new-td flex w-full">
        <div className="w-full pt-2.5 px-2 min-h-8 flex justify-end">
          <input
            id={`checkbox-user-${uuid}`}
            data-testid={`checkbox-${value}`}
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleRowCheckboxChange(e);
            }}
            checked={selected}
          />
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{value}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="w-4 h-4 rounded-full" style={{ backgroundColor: tag.color }} />
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{tag.entity_count}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
          <div className="flex overflow-hidden">
            <div className="">{description}</div>
          </div>
        </div>
      </td>
    </tr>
  );
};

(1.002).toFixed();

export default TagListItem;
