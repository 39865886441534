/* eslint-disable max-lines */
import { Team } from '@litlingo/client';
import {
  addExcludedInsight,
  addExcludedPrevent,
  addExcludedRoles,
  addExcludedTeams,
  addIncludedInsight,
  addIncludedPrevent,
  addIncludedRoles,
  addIncludedTeams,
  removeExcludedInsight,
  removeExcludedPrevent,
  removeExcludedRoles,
  removeExcludedTeams,
  removeIncludedInsight,
  removeIncludedPrevent,
  removeIncludedRoles,
  removeIncludedTeams,
} from 'actions/userPermissionsPage';
import Permissions from 'components/Permissions';
import TeamSelectRedesign from 'components/TeamsManager/TeamSelectRedesign';
import { selectableUserTypes } from 'constants/userRoles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import {
  getExcludedInsight,
  getExcludedPrevent,
  getExcludedRoles,
  getExcludedTeams,
  getIncludedInsight,
  getIncludedPrevent,
  getIncludedRoles,
  getIncludedTeams,
} from 'selectors/userPermissionsPage';
import PermissionsSelect from './PermissionsSelect';

const insightOptions = ['email', 'chat'];
const preventOptions = ['email', 'chat'];

type ComponentProps = {
  singleUser?: boolean;
};

const PermissionsSelections: React.FC<ComponentProps> = ({ singleUser = true }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const includedTeams = useSelector(getIncludedTeams);
  const excludedTeams = useSelector(getExcludedTeams);

  const isSuperAdmin = user.roles.includes('super-admin');

  const handleAddTeams = (teams: Team[], exclude = false): void => {
    if (exclude) {
      dispatch(addExcludedTeams(teams));
    } else {
      dispatch(addIncludedTeams(teams));
    }
  };

  const handleRemoveTeams = (teams: Team[], exclude = false): void => {
    if (exclude) {
      dispatch(removeExcludedTeams(teams));
    } else {
      dispatch(removeIncludedTeams(teams));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <TeamSelectRedesign
        allowExclude={!singleUser}
        includedTeams={includedTeams}
        addIncludedTeams={handleAddTeams}
        removeIncludedTeams={handleRemoveTeams}
        excludedTeams={singleUser ? [] : excludedTeams}
        addExcludedTeams={(teams): void => handleAddTeams(teams, true)}
        removeExcludedTeams={(teams): void => handleRemoveTeams(teams, true)}
        showLabels={false}
      />

      <PermissionsSelect
        includedItemsSelector={getIncludedRoles}
        excludedItemsSelector={singleUser ? undefined : getExcludedRoles}
        addIncludedAction={addIncludedRoles}
        removeIncludedAction={removeIncludedRoles}
        addExcludedAction={singleUser ? undefined : addExcludedRoles}
        removeExcludedAction={singleUser ? undefined : removeExcludedRoles}
        options={Object.values(selectableUserTypes)
          .filter((r) => r.label !== 'Litlingo Admin' || isSuperAdmin)
          .map((r) => r.label)}
        title="Roles"
        showLabels={false}
      />

      <Permissions action="users.editAdvanced">
        <PermissionsSelect
          includedItemsSelector={getIncludedInsight}
          excludedItemsSelector={singleUser ? undefined : getExcludedInsight}
          addIncludedAction={addIncludedInsight}
          removeIncludedAction={removeIncludedInsight}
          addExcludedAction={singleUser ? undefined : addExcludedInsight}
          removeExcludedAction={singleUser ? undefined : removeExcludedInsight}
          options={insightOptions}
          title="Insight Integrations"
          showLabels={false}
        />
      </Permissions>

      <Permissions action="users.editAdvanced">
        <PermissionsSelect
          includedItemsSelector={getIncludedPrevent}
          excludedItemsSelector={singleUser ? undefined : getExcludedPrevent}
          addIncludedAction={addIncludedPrevent}
          removeIncludedAction={removeIncludedPrevent}
          addExcludedAction={singleUser ? undefined : addExcludedPrevent}
          removeExcludedAction={singleUser ? undefined : removeExcludedPrevent}
          options={preventOptions}
          title="Prevent Integrations"
          showLabels={false}
        />
      </Permissions>
    </div>
  );
};

export default PermissionsSelections;
