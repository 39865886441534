import { UserType, userTypesMapping } from 'constants/userRoles';
import type { HomeConfig, Policy, Role } from 'types';

// roles required to do an action
export const ACTION_ROLES: Policy = {
  analytics: {
    list: ['super-admin', 'admin'],
    actions: {
      seeAnalyticsPage: ['litlingo-admin'],
      sentiment: ['super-admin'],
      downloadCsv: ['super-reviewer'],
      expandView: ['admin'],
      tableView: ['admin'],
      export: ['super-admin'],
    },
  },
  annotators: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    actions: {
      clone: ['super-admin'],
    },
  },
  assignments: {
    list: ['assignment-admin'],
    retrieve: ['assignment-admin'],
    create: ['assignment-admin'],
    update: ['assignment-admin'],
    delete: ['assignment-admin'],
  },
  auditLogs: {
    list: ['super-admin'],
  },
  global: {
    list: ['super-admin'],
    actions: {
      access: ['super-admin'],
    },
  },
  campaigns: {
    list: ['super-admin'],
    retrieve: ['admin', 'super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      clone: ['super-admin'],
    },
  },
  categories: {
    list: ['super-admin'],
    retrieve: ['admin', 'super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      clone: ['super-admin'],
    },
  },
  communications: {
    list: ['super-admin'],
    actions: {
      preview: ['admin', 'super-admin'],
      visualizeProcessingStatus: ['super-admin'],
      viewCommunicationsAsJson: ['super-admin'],
      reprocess: ['super-admin'],
      export: ['super-reviewer'],
      advanced: ['super-admin'],
      notify: ['super-reviewer'],
      seeCommunicationSlug: ['super-admin'],
      sandbox: ['super-admin'],
    },
    protectedFields: {
      audit: {
        unprotected: ['super-admin'],
      },
      recipients: {
        unprotected: ['super-reviewer'],
      },
      body: {
        unprotected: ['reviewer'],
      },
      document: {
        unprotected: ['super-admin'],
      },
      entity: {
        unprotected: ['reviewer'],
      },
    },
  },
  customers: {
    list: ['super-admin', 'system-admin', 'assignment-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    protectedFields: {
      config: {
        unprotected: ['super-admin'],
      },
    },
    actions: {
      changeCustomer: ['super-reviewer'],
      reprocessComms: ['super-admin'],
      exportUsers: ['super-reviewer'],
      showUsers: ['assignment-admin'],
      advancedOptions: ['super-admin'],
    },
  },
  events: {
    list: ['reviewer', 'super-reviewer'],
    protectedFields: {
      created_by: {
        unprotected: ['super-admin'],
      },
      recipients: {
        unprotected: ['super-admin'],
      },
      hideLabel: {
        unprotected: ['super-admin'],
      },
      fullMessage: {
        unprotected: ['super-admin'],
      },
    },
    actions: {
      viewExplore: ['super-reviewer'],
      visualizeLanguage: ['super-admin'],
      viewEventAsJson: ['super-admin'],
      viewLogs: ['reviewer', 'super-reviewer'],
      reprocess: ['super-admin'],
      createLabel: ['super-admin'],
      broadSearch: ['reviewer'],
      switchViews: ['super-admin'],
      seeFilterPills: ['super-admin'],
    },
  },
  experiments: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
  },
  models: {
    list: ['super-admin'],
    retrieve: ['admin', 'super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      clone: ['super-admin'],
    },
  },
  models_metrics: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
  },
  outcomes: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
  },
  tasks: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    update: ['super-admin'],
    delete: ['super-admin'],
  },
  reviewSets: {
    list: ['admin'],
    retrieve: ['assignment-admin'],
    create: ['assignment-admin'],
    update: ['assignment-admin'],
    delete: ['assignment-admin'],
    actions: {
      configure: ['assignment-admin'],
      redesign: ['super-admin'],
      reviewManager: ['assignment-admin', 'super-admin'],
      edit: ['super-admin'],
      appendEnvelope: ['super-admin'],
      needToUpdateWarning: ['super-admin'],
      tenancyField: ['super-admin'],
    },
  },
  rules: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      compare: ['super-admin'],
    },
  },
  tags: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    actions: {
      addAnyTag: ['reviewer'],
      seeTenancy: ['super-admin'],
    },
  },
  test_cases: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    actions: {
      createFromTestPage: ['super-admin', 'test-admin'],
      chooseEntity: ['super-admin'],
      viewMetrics: ['test-admin'],
      tags: ['super-admin'],
      askAiToGenerate: ['super-admin'],
      v2Graph: ['super-admin'],
    },
  },
  teams: {
    list: ['super-admin', 'system-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
  },
  templates: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
  },
  users: {
    list: ['reviewer'],
    create: ['super-admin', 'role-admin'],
    actions: {
      viewUsersPage: ['admin', 'role-admin', 'system-admin'],
      viewUsersPermissionsPage: ['admin', 'role-admin', 'system-admin'],
      me: ['super-admin'],
      analytics: ['super-admin', 'analytics-user'],
      advanced: ['super-admin', 'system-admin'],
      editAdvanced: ['super-admin'],
      domains: ['super-admin'],
      trueUsers: ['super-admin'],
    },
  },
  identifiers: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      clone: ['super-admin'],
    },
  },
  identities: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    delete: ['super-admin'],
    actions: {
      spoofUsers: ['super-admin'],
    },
  },
  invitations: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    delete: ['super-admin'],
  },
  communication_envelopes: {
    list: ['super-admin'],
    actions: {
      advanced: ['super-admin'],
      redesign: ['super-admin'],
      filter_label_any_of: ['super-admin'],
      filter_actions: ['super-admin'],
      filter_actions_any_of: ['super-admin'],
      filter_external: ['admin'],
      filter_same_sentence: ['super-admin'],
      filter_result_of_scan: ['super-admin', 'super-reviewer'],
      filter_annotators: ['super-admin'],
      create_review_set_modal: ['super-admin'],
      filter_operations: ['super-reviewer'],
      filter_direction: ['super-reviewer'],
      filter_search_advanced: ['super-admin'],
      filter_categories: ['reviewer'],
      filter_workflow: ['super-reviewer'],
      filter_division: ['super-reviewer'],
      filter_status: ['reviewer'],
      filter_confirmed: ['super-reviewer'],
      filter_source: ['super-reviewer'],
      filter_has_attachment_hits: ['super-admin'],
      filter_misc_actions: ['super-reviewer'],
      more_filters: ['super-reviewer'],
      add_tags: ['reviewer'],
      more_like_this: ['super-admin'],
      export_single_envelope: ['super-reviewer'],
      go_to_clone: ['super-admin'],
      skipped_flow: ['super-admin'],
      model_transparency: ['super-admin'],
    },
  },
  actions: {},
  custom_reports: {
    list: ['super-admin'],
    retrieve: ['super-admin'],
    create: ['super-admin'],
    delete: ['super-admin'],
  },
};

export const USER_HOME: HomeConfig = {
  'system-admin': { routeName: 'customer' },
  'super-admin': { routeName: 'dashboard' },
  'not-authorized': { routeName: 'not-authorized' },
  default: { routeName: 'dashboard' },
};

export const userRolesToTypes = (roles: Role[]): UserType[] => {
  const userTypes: UserType[] = [];
  Object.entries(userTypesMapping).forEach(([, value]) => {
    if (value.roles.every((r) => roles.includes(r))) {
      userTypes.push(value);
    }
  });

  return userTypes;
};

export const userTypesToRoles = (userTypes: UserType['label'][]): Role[] => {
  const userRoles: Role[] = [];

  userTypes.forEach((type) => {
    const userType = Object.values(userTypesMapping).find((uT) => uT.label === type);
    if (userType) {
      userRoles.push(...userType.roles);
    }
  });

  return [...new Set(userRoles)];
};
