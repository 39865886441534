/* eslint-disable max-lines */
import { evaluateAsyncResults, fetchTestCaseSummary } from 'actions';
import { cloneRule } from 'actions/ruleGroup';
import { groupCategories } from 'components/CategoriesTable';
import LinkLookup from 'components/LinkLookup';
import SplitButton from 'components/SplitButton';
import { MINUS_ICON_REDESIGN, PLUS_ICON_REDESIGN } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCategoriesList } from 'selectors/categories';
import { getModelCategories } from 'selectors/models';
import { getSelectedRuleGroup, getSelectedRuleRevision } from 'selectors/ruleGroups';
import { getTestCasesResultsKey } from 'selectors/testCases';
import { useSelector } from 'store';
import EditRuleModal from '../EditRuleModal';
import RuleGroupTag from './RuleGroupTag';

type RouterParams = { ruleId: string };

const RuleHeader: React.FC = () => {
  const dispatch = useDispatch();

  const { ruleId } = useParams<RouterParams>();
  const rule = useSelector(getSelectedRuleRevision);
  const ruleGroup = useSelector(getSelectedRuleGroup);
  // @ts-ignore
  const ruleGroups = [...new Set(ruleGroup?.model_groups?.filter((g) => g))] as string[];

  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);
  const ruleCategory = useSelector(getCategoriesList)[0];
  const categories = useSelector((state) =>
    getModelCategories(state, ruleCategory?.model_uuid || '')
  );
  const groupedCategories = groupCategories(categories);

  const [isEditingRule, setIsEditingRule] = useState(false);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey, ruleId]);

  const handleClinkOnEdit = (): void => {
    setIsEditingRule(true);
  };

  const toggleEditModal = (): void => setIsEditingRule(false);

  const toggleBreadcrumbs = (): void => setShowBreadcrumbs(!showBreadcrumbs);

  const handleShallowCloneRule = (): void => {
    dispatch(cloneRule({ ruleId, redirect: true }));
  };

  const handleDeeepCloneRule = (): void => {
    dispatch(cloneRule({ ruleId, redirect: true, deepCopy: true }));
  };

  const options = [
    {
      label: 'Duplicate (shallow)',
      action: handleShallowCloneRule,
    },
    {
      label: 'Duplicate (deep)',
      action: handleDeeepCloneRule,
    },
  ];

  if (!rule) return null;

  return (
    <>
      <header className="flex flex-col gap-3 px-4 pt-4 pb-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div>{ruleGroups && ruleGroups[0] && <RuleGroupTag group={ruleGroups[0]} />}</div>
            <div className="w-37 h-8">
              <SplitButton
                buttonStyle="secondary"
                primaryLabel="Edit"
                primaryAction={handleClinkOnEdit}
                options={options}
                dropdownDirection="down"
                loading={false}
              />
            </div>
          </div>
          <div>
            <div className="w-full flex flex-row justify-between items-baseline gap-2 text-body">
              <span className="font-bold break-words">{rule && rule.name}</span>
            </div>

            <div className="flex flex-col h-full gap-4">
              <div className="flex flex-row gap-1">
                <div className="flex flex-col gap-1">
                  {categories && categories[0] && (
                    <div className=" flex flex-row gap-2">
                      <button
                        type="button"
                        className="focus:outline-none"
                        onClick={toggleBreadcrumbs}
                      >
                        {showBreadcrumbs ? MINUS_ICON_REDESIGN : PLUS_ICON_REDESIGN}
                      </button>
                      <div className="flex flex-row gap-1">
                        <LinkLookup
                          type="button"
                          routeName="global-models-list"
                          className="text-litlingo-gray-6 focus:outline-none"
                        >
                          Use Cases
                        </LinkLookup>
                        <div>{`>`}</div>
                        <LinkLookup
                          type="button"
                          className=" text-litlingo-gray-6 focus:outline-none"
                          routeName="global-model-manager"
                          routeParams={{ modelId: categories[0]?.model?.uuid ?? '' }}
                        >{`${categories[0]?.model?.name}`}</LinkLookup>
                      </div>
                    </div>
                  )}

                  {showBreadcrumbs && (
                    <div className="pl-5">
                      {Object.values(groupedCategories)
                        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                        .map((rCategory) => (
                          <div key={rCategory.uuid} className="flex flex-row gap-1">
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 0V6C1 8.20914 2.79086 10 5 10H11" stroke="#5C5C5C" />
                            </svg>
                            <LinkLookup
                              routeName="global-rule-group-manager"
                              type="button"
                              className={`self-end  focus:outline-none ${
                                rule.uuid === rCategory.uuid
                                  ? 'text-litlingo-gray-6'
                                  : 'underline text-litlingo-primary-120'
                              }`}
                              routeParams={{
                                ruleId: rCategory.categories[0].rule?.rule_group_uuid ?? '',
                              }}
                            >
                              <span>{rCategory.name}</span>
                            </LinkLookup>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isEditingRule && (
          <EditRuleModal
            isModalOpen={isEditingRule}
            toggleModalOpen={toggleEditModal}
            rule={rule}
          />
        )}
      </header>
    </>
  );
};

export default RuleHeader;
