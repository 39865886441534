import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  ChangeCustomerSuccessPayload,
  checkLoginRedirectFailure,
  checkLoginRedirectRequest,
  checkLoginRedirectSuccess,
  createCustomerFailure,
  createCustomerRequest,
  createCustomerSuccess,
  createNewSandboxFailure,
  createNewSandboxRequest,
  createNewSandboxSuccess,
  editCustomer,
  exportUsersFailure,
  exportUsersFulfill,
  exportUsersRequest,
  fetchAllSandboxesFailure,
  fetchAllSandboxesRequest,
  fetchAllSandboxesSuccess,
  fetchCustomersFailure,
  fetchCustomersMeFailure,
  fetchCustomersMeFulfill,
  fetchCustomersMeRequest,
  fetchCustomersMeSuccess,
  fetchCustomersRequest,
  fetchCustomersSuccess,
  fetchIndexesCommsFailure,
  FetchIndexesCommsPayload,
  fetchIndexesCommsRequest,
  fetchIndexesCommsSuccess,
  fetchIndexingStatusFailure,
  FetchIndexingStatusPayload,
  fetchIndexingStatusRequest,
  fetchIndexingStatusSuccess,
  fetchIProcessingStatusFailure,
  fetchLogMetricsFailure,
  FetchLogMetricsPayload,
  fetchLogMetricsRequest,
  fetchLogMetricsSuccess,
  FetchProcessingStatusPayload,
  fetchProcessingStatusRequest,
  fetchProcessingStatusSuccess,
  fetchSingleCustomerFailure,
  fetchSingleCustomerRequest,
  fetchSingleCustomerSuccess,
  fetchZendeskSuccess,
  FetchZendeskSuccessPayload,
  gmailVerifyCredentialsFailure,
  gmailVerifyCredentialsRequest,
  gmailVerifyCredentialsSuccess,
  logoutUser,
  logoutUserFulfill,
  logoutUserRequest,
  o365VerifyCredentialsFailure,
  o365VerifyCredentialsRequest,
  o365VerifyCredentialsSuccess,
  redirectToLitlingoLoginFailure,
  redirectToLitlingoLoginRequest,
  saveCustomerFailure,
  saveCustomerRequest,
  saveCustomerSuccess,
  selectCustomerCloneCommFailure,
  selectCustomerCloneCommRequest,
  selectCustomerCloneCommSuccess,
  selectCustomerFailure,
  selectCustomerRequest,
  selectCustomerSuccess,
  spoofRoles,
  toggleShortcutsModal,
  VerifyCredentialsSuccessPayload,
  verifyFailure,
  verifyRequest,
  verifySuccess,
  VerifySuccessPayload,
  zendeskVerifyCredentialsFailure,
  zendeskVerifyCredentialsRequest,
  zendeskVerifyCredentialsSuccess,
} from 'actions/auth';
import type {
  Customer,
  ElasticsearchIndexes,
  ErrorObject,
  IndexingStatus,
  LogMetric,
  NormalizedResource,
  ProcessingStatus,
  User,
} from 'types';

export type AuthState = {
  loading: string[];
  error: ErrorObject | null;
  isVerifying: boolean;
  isAuthenticated: boolean;
  user: User;
  customer: Customer;
  customerList: Customer[];
  selectCustomerList: Customer[]; // Only use this for auth things please
  zendeskKey: string | null;
  zendeskVerifyCredentials: boolean | null;
  o365VerifyCredentials: boolean | null;
  gmailVerifyCredentials: boolean | null;
  displayShortcutsModal: boolean | null;
  indexes: ElasticsearchIndexes;
  logMetrics: {
    // eslint-disable-next-line camelcase
    queue_name: string;
    length: number;
    // eslint-disable-next-line camelcase
    by_priority?: Record<string, unknown> | null;
  }[];
  indexingStatus: {
    pending: number;
    scheduled: number;
    complete: number;
    failed: number;
    null: number;
  };
  processingStatus: {
    // eslint-disable-next-line camelcase
    not_ready: number;
    pending: number;
    complete: number;
    failed: number;
  };
  spoofedUserUuid?: string;
  spoofedUserEmail?: string;
  sandboxes: Customer[];
};

type AuthReducer<P = void> = (state: AuthState, action: PayloadAction<P>) => AuthState;

export const defaultState: AuthState = {
  loading: [],
  error: null,
  isVerifying: false,
  isAuthenticated: false,
  user: {} as User,
  customer: {} as Customer,
  customerList: [],
  selectCustomerList: [],
  zendeskKey: null,
  zendeskVerifyCredentials: false,
  o365VerifyCredentials: false,
  gmailVerifyCredentials: false,
  displayShortcutsModal: false,
  indexes: {} as ElasticsearchIndexes,
  logMetrics: [{} as LogMetric],
  indexingStatus: {} as IndexingStatus,
  processingStatus: {} as ProcessingStatus,
  sandboxes: [] as Customer[],
};

const handleLogoutUserRequest: AuthReducer = (state) => ({
  ...state,
  loading: [...state.loading, logoutUser.toString()],
});

const handleLogoutUserFulfill: AuthReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== logoutUser.toString()),
});

const handleVerifyRequest: AuthReducer = (state) => ({
  ...state,
  isVerifying: true,
  error: null,
  loading: [...state.loading, verifyRequest.toString()],
});

const handleVerifySuccess: AuthReducer<VerifySuccessPayload> = (state, { payload }) => {
  const { user } = payload;

  return {
    ...state,
    isAuthenticated: true,
    isVerifying: false,
    selectCustomerList: [],
    user,
    loading: state.loading.filter((s) => s !== verifyRequest.toString()),
  };
};

const handleVerifyFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  isVerifying: false,
  error: payload,
  loading: state.loading.filter((s) => s !== verifyRequest.toString()),
});

const handleFetchCustomersRequest: AuthReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCustomersRequest.toString()],
});

const handleFetchCustomersFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCustomersRequest.toString()),
});

const handleFetchCustomersSuccess: AuthReducer<Customer[]> = (state, { payload }) => ({
  ...state,
  customerList: payload,
  loading: state.loading.filter((s) => s !== fetchCustomersRequest.toString()),
});

const handleFetchSingleCustomerRequest: AuthReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleCustomerRequest.toString()],
});

const handleFetchSingleCustomerFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleCustomerRequest.toString()),
});

const handleFetchSingleCustomerSuccess: AuthReducer<Customer> = (state, { payload }) => ({
  ...state,
  customer: payload,
  loading: state.loading.filter((s) => s !== fetchSingleCustomerRequest.toString()),
});

const handleSaveCustomerRequest: AuthReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveCustomerRequest.toString()],
});

const handleSaveCustomerSuccess: AuthReducer<Customer> = (state, { payload }) => ({
  ...state,
  // @ts-ignore
  user: {
    ...state.user,
    customer: {
      ...state.user.customer,
      ...payload,
    },
  },
  customer: {
    ...state.customer,
    ...payload,
  },
  loading: state.loading.filter((s) => s !== saveCustomerRequest.toString()),
});

const handleSaveCustomerFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== saveCustomerRequest.toString()),
});

const handleCreateCustomerRequest: AuthReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createCustomerRequest.toString()],
});

const handleCreateCustomerSuccess: AuthReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createCustomerRequest.toString()),
});

const handleCreateCustomerFailure: AuthReducer<ErrorObject> = (state, action) => ({
  ...state,
  error: action.payload,
  loading: state.loading.filter((s) => s !== createCustomerRequest.toString()),
});

const handleEditCustomer: AuthReducer<Customer> = (state, { payload }) => ({
  ...state,
  customer: {
    ...state.customer,
    ...payload,
  },
});

const handleFetchZendeskSuccess: AuthReducer<FetchZendeskSuccessPayload> = (
  state,
  { payload }
) => ({
  ...state,
  zendeskKey: payload.key,
});

const handleZendeskVerifyCredentialsSuccess: AuthReducer<VerifyCredentialsSuccessPayload> = (
  state,
  { payload }
) => ({
  ...state,
  zendeskVerifyCredentials: payload.verified,
  loading: state.loading.filter((s) => s !== zendeskVerifyCredentialsRequest.toString()),
});

const handleZendeskVerifyCredentialsRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, zendeskVerifyCredentialsRequest.toString()],
});

const handleZendeskVerifyCredentialsFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== zendeskVerifyCredentialsRequest.toString()),
});

const handleGmailVerifyCredentialsSuccess: AuthReducer<VerifyCredentialsSuccessPayload> = (
  state,
  { payload }
) => ({
  ...state,
  gmailVerifyCredentials: payload.verified,
  loading: state.loading.filter((s) => s !== gmailVerifyCredentialsRequest.toString()),
});

const handleGmailVerifyCredentialsRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, gmailVerifyCredentialsRequest.toString()],
});

const handleGmailVerifyCredentialsFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== gmailVerifyCredentialsRequest.toString()),
});

const handleO365VerifyCredentialsSuccess: AuthReducer<VerifyCredentialsSuccessPayload> = (
  state,
  { payload }
) => ({
  ...state,
  o365VerifyCredentials: payload.verified,
  loading: state.loading.filter((s) => s !== o365VerifyCredentialsRequest.toString()),
});

const handleO365VerifyCredentialsRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, o365VerifyCredentialsRequest.toString()],
});

const handleO365VerifyCredentialsFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== o365VerifyCredentialsRequest.toString()),
});

const handleToggleShortcutsModal: AuthReducer<ChangeCustomerSuccessPayload> = (state) => ({
  ...state,
  displayShortcutsModal: !state.displayShortcutsModal,
});

const handleFetchIndexesCommsRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, fetchIndexesCommsRequest.toString()],
});

const handleFetchIndexesCommsFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchIndexesCommsRequest.toString()),
});

const handleFetchIndexesCommsSuccess: AuthReducer<FetchIndexesCommsPayload> = (
  state,
  { payload }
) => ({
  ...state,
  indexes: {
    ...payload.indexes,
  },
  loading: state.loading.filter((s) => s !== fetchIndexesCommsRequest.toString()),
});

const handleFetchLogMetricsRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, fetchLogMetricsRequest.toString()],
});

const handleFetchLogMetricsFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchLogMetricsRequest.toString()),
});

const handleFetchLogMetricsSuccess: AuthReducer<FetchLogMetricsPayload> = (state, { payload }) => ({
  ...state,
  logMetrics: {
    ...payload,
  },
  loading: state.loading.filter((s) => s !== fetchLogMetricsRequest.toString()),
});

const handleFetchIndexingStatusRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, fetchIndexingStatusRequest.toString()],
});

const handleFetchIndexingStatusFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchIndexingStatusRequest.toString()),
});

const handleFetchIndexingStatusSuccess: AuthReducer<FetchIndexingStatusPayload> = (
  state,
  { payload }
) => ({
  ...state,
  indexingStatus: {
    ...payload,
  },
  loading: state.loading.filter((s) => s !== fetchIndexingStatusRequest.toString()),
});

const handleFetchProcessingStatusRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, fetchProcessingStatusRequest.toString()],
});

const handleFetchProcessingStatusFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchProcessingStatusRequest.toString()),
});

const handleFetchProcessingStatusSuccess: AuthReducer<FetchProcessingStatusPayload> = (
  state,
  { payload }
) => ({
  ...state,
  processingStatus: {
    ...payload,
  },
  loading: state.loading.filter((s) => s !== fetchProcessingStatusRequest.toString()),
});

const handleFetchAllSandboxesRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, fetchAllSandboxesRequest.toString()],
});

const handleFetchAllSandboxesSuccess: AuthReducer<Customer[]> = (state, { payload }) => {
  const normalizedSandboxes: NormalizedResource<Customer> = {};
  payload.forEach((sandbox) => {
    normalizedSandboxes[sandbox.uuid] = sandbox;
  });

  return {
    ...state,
    sandboxes: payload,
    loading: state.loading.filter((s) => s !== fetchAllSandboxesRequest.toString()),
  };
};

const handleFetchAllSandboxesFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAllSandboxesRequest.toString()),
});

const handleCreateNewSandboxRequest: AuthReducer<string> = (state) => ({
  ...state,
  loading: [...state.loading, createNewSandboxRequest.toString()],
});

const handleCreateNewSandboxSuccess: AuthReducer<Customer[]> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createNewSandboxRequest.toString()),
});

const handleCreateNewSandboxFailure: AuthReducer<string> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createNewSandboxRequest.toString()),
});

const handleCheckLoginRedirectRequest: AuthReducer = (state) => ({
  ...state,
  isVerifying: true,
});

const handleCheckLoginRedirectSuccess: AuthReducer<Customer[]> = (state, { payload }) => ({
  ...state,
  selectCustomerList: payload,
  isAuthenticated: true,
  isVerifying: false,
});

const handleSelectCustomerRequest: AuthReducer = (state) => ({
  ...state,
  isVerifying: true,
});

const handleSelectCustomerSuccess: AuthReducer<User> = (state, { payload }) => ({
  ...state,
  user: payload,
  selectCustomerList: [],
  isVerifying: false,
});

const handleSelectCustomerFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  selectCustomerList: [],
  isVerifying: false,
  error: payload,
});

const handleFetchCustomersMeRequest: AuthReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchCustomersMeRequest.toString()],
});

const handleFetchCustomersMeSuccess: AuthReducer<Customer[]> = (state, { payload }) => ({
  ...state,
  selectCustomerList: payload,
  isAuthenticated: true,
});

const handleFetchCustomersMeFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  isAuthenticated: false,
});

const handleFetchCustomersMeFulfill: AuthReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchCustomersMeRequest.toString()),
});

const handleRedirectToLitlingoLoginRequest: AuthReducer = (state) => ({
  ...state,
  isVerifying: true,
});

const handleRedirectToLitlingoLoginFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  isVerifying: false,
  error: payload,
});

const handleCheckLoginRedirectFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  isVerifying: false,
  error: payload,
});

const handleExportUsersRequest: AuthReducer = (state) => ({
  ...state,
  loading: [...state.loading, exportUsersRequest.toString()],
});

const handleExportUsersFailure: AuthReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleExportUsersFulfill: AuthReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportUsersRequest.toString()),
});

const handleSpoofRoles: AuthReducer<{
  userUuid: string | undefined;
  userEmail: string | undefined;
}> = (state, { payload }) => ({
  ...state,
  spoofedUserUuid: payload.userUuid,
  spoofedUserEmail: payload.userEmail,
});

const handleSelectCustomerCloneRequest: AuthReducer = (state: AuthState) => ({
  ...state,
  loading: [...state.loading, selectCustomerCloneCommRequest.toString()],
});

const handleSelectCustomerCloneSuccess: AuthReducer = (state: AuthState) => ({
  ...state,
  loading: state.loading.filter((s) => s !== selectCustomerCloneCommRequest.toString()),
});

const handleSelectCustomerCloneFailure: AuthReducer<ErrorObject> = (
  state: AuthState,
  { payload }: PayloadAction<ErrorObject>
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== selectCustomerCloneCommRequest.toString()),
});

const handlers = {
  [selectCustomerRequest.toString()]: handleSelectCustomerRequest,
  [selectCustomerSuccess.toString()]: handleSelectCustomerSuccess,
  [selectCustomerFailure.toString()]: handleSelectCustomerFailure,
  [redirectToLitlingoLoginRequest.toString()]: handleRedirectToLitlingoLoginRequest,
  [redirectToLitlingoLoginFailure.toString()]: handleRedirectToLitlingoLoginFailure,
  [fetchCustomersMeRequest.toString()]: handleFetchCustomersMeRequest,
  [fetchCustomersMeSuccess.toString()]: handleFetchCustomersMeSuccess,
  [fetchCustomersMeFailure.toString()]: handleFetchCustomersMeFailure,
  [fetchCustomersMeFulfill.toString()]: handleFetchCustomersMeFulfill,
  [logoutUserRequest.toString()]: handleLogoutUserRequest,
  [logoutUserFulfill.toString()]: handleLogoutUserFulfill,
  [checkLoginRedirectFailure.toString()]: handleCheckLoginRedirectFailure,
  [verifyRequest.toString()]: handleVerifyRequest,
  [verifySuccess.toString()]: handleVerifySuccess,
  [verifyFailure.toString()]: handleVerifyFailure,
  [fetchCustomersRequest.toString()]: handleFetchCustomersRequest,
  [fetchCustomersSuccess.toString()]: handleFetchCustomersSuccess,
  [fetchCustomersFailure.toString()]: handleFetchCustomersFailure,
  [fetchSingleCustomerRequest.toString()]: handleFetchSingleCustomerRequest,
  [fetchSingleCustomerSuccess.toString()]: handleFetchSingleCustomerSuccess,
  [fetchSingleCustomerFailure.toString()]: handleFetchSingleCustomerFailure,
  [saveCustomerRequest.toString()]: handleSaveCustomerRequest,
  [saveCustomerSuccess.toString()]: handleSaveCustomerSuccess,
  [saveCustomerFailure.toString()]: handleSaveCustomerFailure,
  [createCustomerRequest.toString()]: handleCreateCustomerRequest,
  [createCustomerFailure.toString()]: handleCreateCustomerFailure,
  [createCustomerSuccess.toString()]: handleCreateCustomerSuccess,
  [editCustomer.toString()]: handleEditCustomer,
  [fetchZendeskSuccess.toString()]: handleFetchZendeskSuccess,
  [zendeskVerifyCredentialsRequest.toString()]: handleZendeskVerifyCredentialsRequest,
  [zendeskVerifyCredentialsSuccess.toString()]: handleZendeskVerifyCredentialsSuccess,
  [zendeskVerifyCredentialsFailure.toString()]: handleZendeskVerifyCredentialsFailure,
  [gmailVerifyCredentialsRequest.toString()]: handleGmailVerifyCredentialsRequest,
  [gmailVerifyCredentialsSuccess.toString()]: handleGmailVerifyCredentialsSuccess,
  [gmailVerifyCredentialsFailure.toString()]: handleGmailVerifyCredentialsFailure,
  [o365VerifyCredentialsRequest.toString()]: handleO365VerifyCredentialsRequest,
  [o365VerifyCredentialsSuccess.toString()]: handleO365VerifyCredentialsSuccess,
  [o365VerifyCredentialsFailure.toString()]: handleO365VerifyCredentialsFailure,
  [toggleShortcutsModal.toString()]: handleToggleShortcutsModal,
  [fetchIndexesCommsRequest.toString()]: handleFetchIndexesCommsRequest,
  [fetchIndexesCommsSuccess.toString()]: handleFetchIndexesCommsSuccess,
  [fetchIndexesCommsFailure.toString()]: handleFetchIndexesCommsFailure,
  [fetchLogMetricsRequest.toString()]: handleFetchLogMetricsRequest,
  [fetchLogMetricsSuccess.toString()]: handleFetchLogMetricsSuccess,
  [fetchLogMetricsFailure.toString()]: handleFetchLogMetricsFailure,
  [fetchIndexingStatusRequest.toString()]: handleFetchIndexingStatusRequest,
  [fetchIndexingStatusSuccess.toString()]: handleFetchIndexingStatusSuccess,
  [fetchIndexingStatusFailure.toString()]: handleFetchIndexingStatusFailure,
  [fetchProcessingStatusRequest.toString()]: handleFetchProcessingStatusRequest,
  [fetchProcessingStatusSuccess.toString()]: handleFetchProcessingStatusSuccess,
  [fetchIProcessingStatusFailure.toString()]: handleFetchProcessingStatusFailure,
  [checkLoginRedirectSuccess.toString()]: handleCheckLoginRedirectSuccess,
  [checkLoginRedirectRequest.toString()]: handleCheckLoginRedirectRequest,
  [spoofRoles.toString()]: handleSpoofRoles,
  [fetchAllSandboxesRequest.toString()]: handleFetchAllSandboxesRequest,
  [fetchAllSandboxesSuccess.toString()]: handleFetchAllSandboxesSuccess,
  [fetchAllSandboxesFailure.toString()]: handleFetchAllSandboxesFailure,
  [createNewSandboxRequest.toString()]: handleCreateNewSandboxRequest,
  [createNewSandboxSuccess.toString()]: handleCreateNewSandboxSuccess,
  [createNewSandboxFailure.toString()]: handleCreateNewSandboxFailure,
  [checkLoginRedirectSuccess.toString()]: handleCheckLoginRedirectSuccess,
  [checkLoginRedirectRequest.toString()]: handleCheckLoginRedirectRequest,
  [exportUsersRequest.toString()]: handleExportUsersRequest,
  [exportUsersFailure.toString()]: handleExportUsersFailure,
  [exportUsersFulfill.toString()]: handleExportUsersFulfill,
  [selectCustomerCloneCommRequest.toString()]: handleSelectCustomerCloneRequest,
  [selectCustomerCloneCommSuccess.toString()]: handleSelectCustomerCloneSuccess,
  [selectCustomerCloneCommFailure.toString()]: handleSelectCustomerCloneFailure,
};

const authReducer = createReducer(defaultState, handlers);

export default authReducer;
