import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import TagsList from 'components/TagsList';
import React from 'react';
import { getFetchAllTagsLoading, getTags } from 'selectors/tags';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TagsHeader from './TagsHeader';

const TagsManager: React.FC = () => {
  const loading = useSelector(getFetchAllTagsLoading);
  const tags = useSelector(getTags);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="pt-4 pb-10 flex flex-col gap-2 overflow-hidden">
          <TagsHeader />
          <main className="flex w-full overflow-hidden">
            <TagsList loading={loading} tags={tags} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default TagsManager;
