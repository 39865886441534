/* eslint-disable jsx-a11y/control-has-associated-label */
import { fetchUsersForFilter, selectReviewSet, setURLParams } from 'actions';
import DropdownFilter from 'components/Filters/DropdownFilter/DropdownFilter';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useState } from 'react';

import Permissions from 'components/Permissions';
import HeadingToExploreModal from 'components/ReviewSets/HeadingToExploreModal';
import SelectRedesign from 'components/Select/SelectRedesign';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import { getSavedSearchesList, getSavedSearchesLoading } from 'selectors/savedSearches';
import { getUsersList, getUsersLoading } from 'selectors/users';
import { useSelector } from 'store';
import { useHistory } from 'utils/urls';
import ReviewSetTable from './ReviewSetTable';

export const reviewManagementNavigationOptions = [
  {
    label: 'Review Streams',
    value: 'review-streams',
    route: 'review-set-manager',
  },
  {
    label: 'Saved Searches',
    value: 'saved-searches',
    route: 'saved-searches',
  },
  {
    label: 'Samples',
    value: 'samples',
    route: 'samples',
  },
];

const ReviewSetsList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { reviewSetId } = useParams<{ reviewSetId: string }>();

  const [isHeadingToExploreModalOpen, setIsHeadingToExploreModalOpen] = useState(false);

  const loading = useSelector(getSavedSearchesLoading);
  const reviewSets = useSelector(getSavedSearchesList);
  const customerDomain = useSelector(getCustomerDomain);
  const user = useSelector(getUser);
  const isFirstTimeCreatingReviewSet = window.localStorage.getItem('isFirstTimeCreatingReviewSet');
  const params = useSelector(getNavParamsByResource(resourceQueryParamName.savedSearch));

  useEffect(() => {
    if (!reviewSetId) {
      if (!reviewSets[0] || loading) {
        dispatch(selectReviewSet({ reviewSet: null }));
        return;
      }

      history.pushLookup({
        customerDomain,
        routeName: 'review-set-manager-selected',
        routeParams: { reviewSetId: reviewSets[0].uuid },
      });
      dispatch(selectReviewSet({ reviewSet: reviewSets[0] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewSets, loading]);

  const handleCreateReviewStream = (): void => {
    if (isFirstTimeCreatingReviewSet === 'false') {
      history.pushLookup({
        routeName: 'envelope-list',
        customerDomain,
      });
      return;
    }

    setIsHeadingToExploreModalOpen(true);
    window.localStorage.setItem('isFirstTimeCreatingReviewSet', 'true');
  };

  const toggleHeadingtoExploreModalOpen = (): void => {
    window.localStorage.removeItem('isFirstTimeCreatingReviewSet');
    setIsHeadingToExploreModalOpen(!isHeadingToExploreModalOpen);
  };

  const handleTypeFilterChange = (option: string): void => {
    if (option === 'review-sets') {
      dispatch(
        setURLParams({
          saved_searches__is_assignable: 'true',
          saved_searches__samples: '',
        })
      );
    }
    if (option === 'saved-searches') {
      dispatch(
        setURLParams({
          saved_searches__is_assignable: 'false',
          saved_searches__samples: '',
        })
      );
    }
    if (option === 'quick-samples') {
      dispatch(
        setURLParams({
          saved_searches__is_assignable: '',
          saved_searches__samples: 'true',
        })
      );
    }
    if (option === '') {
      dispatch(
        setURLParams({
          saved_searches__is_assignable: 'true',
          saved_searches__samples: '',
        })
      );
    }
  };

  const handleViewFilterChange = (option: string): void => {
    if (option === 'modeler') {
      dispatch(
        setURLParams({
          saved_searches__tenant_name: 'modeler',
        })
      );
    }
    if (option === 'human') {
      dispatch(
        setURLParams({
          saved_searches__tenant_name: 'human',
        })
      );
    }
  };

  const renderTypeFilterOptions = (): JSX.Element => {
    const { is_assignable: assignable, samples } = params;
    let value = '';

    if (assignable === 'true') {
      value = 'review-sets';
    }
    if (assignable === 'false') {
      value = 'saved-searches';
    }
    if (samples === 'true') {
      value = 'quick-samples';
    }

    const quickSamplesOption = user.email?.split('@')[1]?.includes('litlingo')
      ? [
          {
            label: 'Quick Samples',
            value: 'quick-samples',
          },
        ]
      : [];

    const options = [
      {
        label: 'Review Streams',
        value: 'review-sets',
      },
      {
        label: 'Saved Searches',
        value: 'saved-searches',
      },
      ...quickSamplesOption,
    ];

    return (
      <div className="w-33">
        <SelectRedesign
          className="bg-white"
          optionClassname="bg-white"
          placeholder="Type"
          options={options}
          onChange={handleTypeFilterChange}
          value={value}
        />
      </div>
    );
  };

  const renderViewFilterOptions = (): JSX.Element => {
    const { tenant_name: tenantName } = params;
    let value = '';

    if (tenantName === 'modeler') {
      value = 'modeler';
    }
    if (tenantName === 'human') {
      value = 'human';
    }

    const tenantOptions = [
      {
        label: 'Internal',
        value: 'modeler',
      },
      {
        label: 'External',
        value: 'human',
      },
    ];
    return (
      <Permissions action="reviewSets.tenancyField">
        <div className="w-33">
          <SelectRedesign
            className="bg-white"
            optionClassname="bg-white"
            placeholder="View"
            options={tenantOptions}
            onChange={handleViewFilterChange}
            value={value}
          />
        </div>
      </Permissions>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-6 pl-6 pr-5 h-full overflow-hidden">
        <div className="flex flex-row justify-between">
          <div className="text-heading-2">Review Management</div>

          <button
            type="button"
            className=" flex flex-row justify-center button button--secondary h-8 w-48 "
            onClick={handleCreateReviewStream}
          >
            <span className="font-bold">Create Review Stream</span>
          </button>
        </div>

        <div className="flex flex-col items-start gap-4 h-full w-full overflow-hidden">
          <div className="flex flex-row gap-10 justify-between w-full">
            <div className="flex flex-row gap-2">
              <SearchInput
                resource={resourceQueryParamName.savedSearch}
                field="broad_search"
                className="h-8 max-w-68"
                placeholder="Search by review stream"
              />
              {renderTypeFilterOptions()}
              {renderViewFilterOptions()}
            </div>

            <div className="w-40">
              <DropdownFilter
                resource={resourceQueryParamName.savedSearch}
                fetchAction={fetchUsersForFilter}
                fetchParams={{ roles: ['reviewer', 'super-reviewer'] }}
                filterKey="user_uuids"
                itemField="uuid"
                getResourceList={getUsersList}
                getLoading={getUsersLoading}
                title="Reviewers"
                nameKey="name"
                notFoundText="No reviewers found"
              />
            </div>
          </div>
          <ReviewSetTable />
        </div>
      </div>
      {isHeadingToExploreModalOpen && (
        <HeadingToExploreModal
          isModalOpen={isHeadingToExploreModalOpen}
          toggleModalOpen={toggleHeadingtoExploreModalOpen}
        />
      )}
    </>
  );
};

export default ReviewSetsList;
