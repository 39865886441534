import React from 'react';

type ComponentProps = {
  message: string;
  colCount?: string;
};

// If the table have table-layout: fixed, you must provide the colCount
// prop, which is the total number of <th> in the <thead>
// This component MUST be inside the <tbody> element
const EmptyTableState: React.FC<ComponentProps> = ({ message, colCount }) => (
  <tr data-testid="empty-table-state">
    {/* @ts-ignore */}
    <td colSpan={colCount || '100%'}>
      <div className="flex items-center justify-center my-6">
        <span className="text truncate">{message}</span>
      </div>
    </td>
  </tr>
);

export default EmptyTableState;
