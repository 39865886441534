/* eslint-disable max-lines */

import { Customer } from '@litlingo/client';
import { clearTree, requestTreeFiltersToogle, selectCustomerConfig } from 'actions';
import { setHighlightCustomerScope, setShowUtilization } from 'actions/ruleGroup';
import { CLOSE_ICON_16 } from 'constants/commonIcons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesList } from 'selectors/categories';
import {
  canBeScoped,
  getCompareMode,
  getSelectedCustomerRuleConfig,
  getSelectedNode,
  getSelectedNodeScopesForUser,
} from 'selectors/config';
import { getConfigRuleAsArray, getSelectedCustomerGroup } from 'selectors/ruleGroup';
import {
  getCustomerBranchHits,
  getHighlightCustomerScope,
  getSelectedRuleRevision,
} from 'selectors/ruleGroups';
import { useSelector } from 'store';
import OutcomesSection from '../OutcomesSection';

type CustomerItemProps = {
  customer: Customer;
  handleAddScopeCustomer: (customer: string) => void;
  handleRemoveScopeCustomer: (customer: string) => void;
};

const CustomerItem: React.FC<CustomerItemProps> = ({
  customer,
  handleAddScopeCustomer,
  handleRemoveScopeCustomer,
}) => {
  const dispatch = useDispatch();

  const scopedCustomers = useSelector(getSelectedNodeScopesForUser);
  const disabled = !useSelector((state) => canBeScoped(state, customer.uuid));
  const selectedCustomer = useSelector(getSelectedCustomerRuleConfig);
  const selectedNodeIndex = useSelector(getSelectedNode);
  const highligtCustomerScope = useSelector(getHighlightCustomerScope);
  const ruleRevision = useSelector(getSelectedRuleRevision);
  const arrayTree = useSelector(getConfigRuleAsArray);
  const customerGroup = useSelector(getSelectedCustomerGroup);
  const compareMode = useSelector(getCompareMode);

  const categories = useSelector(getCategoriesList);
  const customerCategories = categories.filter((c) => c.customer_uuid === customer.uuid);

  const customerBranchHits = useSelector((state) =>
    getCustomerBranchHits(state, ruleRevision?.rule_group_uuid || '', customer.uuid)
  );

  const isStaging = categories.some(
    (cat) => cat.customer_uuid === customer.uuid && cat.rule_head?.name === 'staging'
  );

  const isProd = categories.some(
    (cat) => cat.customer_uuid === customer.uuid && cat.rule_head?.name === 'production'
  );

  const enabled =
    (customerGroup === 'staging' && isStaging) || (customerGroup === 'production' && isProd);

  const handleSelectUtilization = (): void => {
    if (selectedCustomer === customer.uuid) {
      dispatch(selectCustomerConfig(''));
      dispatch(setShowUtilization(false));
      return;
    }
    dispatch(selectCustomerConfig(customer.uuid));
  };

  const handleToggleSelectCustomer = (): void => {
    if (selectedCustomer === customer.uuid) {
      dispatch(selectCustomerConfig(''));
      dispatch(setHighlightCustomerScope(null));
      dispatch(setShowUtilization(true));
    } else {
      dispatch(selectCustomerConfig(customer.uuid));
      dispatch(setHighlightCustomerScope(null));
      dispatch(setShowUtilization(false));
    }
  };

  const handleSearchBranchCustomer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    const node = arrayTree[selectedNodeIndex as number];
    if (node.nodeGroups) {
      const ruleBranchUUID = `${ruleRevision?.rule_group_uuid}_${node.id}`;
      dispatch(clearTree());
      dispatch(requestTreeFiltersToogle({ value: true }));
      const url = new URLSearchParams();
      url.append('envelopes__rule_branch_uuid', ruleBranchUUID);
      url.append('envelopes__filters_search', `~rule_branch_uuid{${ruleBranchUUID}}`);
      window.open(`/${customer?.domain}/communication-envelopes?${url.toString()}`, '_blank');
    }
  };

  return (
    <div>
      {(selectedNodeIndex && selectedNodeIndex !== -1) || selectedNodeIndex === 0 ? (
        <div className="w-ful">
          {scopedCustomers.includes(customer.uuid) ? (
            <div className="relative text-body">
              <button
                type="button"
                className={`w-full flex flex-row items-center gap-2 px-4 py-2.5 border-b border-litlingo-gray-1 focus:outline-none ${
                  selectedCustomer === customer.uuid
                    ? 'bg-litlingo-success-light bg-opacity-50'
                    : ''
                }`}
                onClick={handleSelectUtilization}
              >
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="flex flex-row items-center gap-2 ">
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${customer.uuid}`}
                      checked={!scopedCustomers.includes(customer.uuid)}
                      className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer"
                      onClick={(e): void => {
                        e.stopPropagation();
                      }}
                      onChange={(): void => {
                        handleAddScopeCustomer(customer.uuid);
                      }}
                      disabled={!enabled}
                    />
                    <span>{customer.name}</span>
                  </div>
                  <button
                    type="button"
                    className={`underline focus:outline-none ${
                      selectedCustomer === customer.uuid ? 'text-litlingo-primary-120' : ''
                    }`}
                    onClick={(e): void => handleSearchBranchCustomer(e)}
                    disabled={!enabled}
                  >
                    {customerBranchHits ?? 0}
                  </button>
                </div>
              </button>
              {!enabled && (
                <div className="absolute top-0 h-full w-full bg-litlingo-gray-0.5 bg-opacity-50" />
              )}
            </div>
          ) : (
            <div
              className={`relative flex flex-row items-center gap-2 px-4 py-2 border-b border-litlingo-gray-1 ${
                disabled
                  ? 'bg-litlingo-gray-0.5 bg-opacity-50 text-litlingo-gray-4'
                  : 'bg-litlingo-white text-litlingo-gray-6 hover:bg-litlingo-gray-1 hover:bg-opacity-100'
              }`}
            >
              <div className="w-full flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-2">
                  <button
                    type="button"
                    className={`focus:outline-none ${
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={(): void => handleRemoveScopeCustomer(customer.uuid)}
                    disabled={disabled}
                  >
                    {CLOSE_ICON_16}
                  </button>
                  <span className="italic">{customer.name}</span>
                </div>
                <button
                  type="button"
                  className="underline focus:outline-none"
                  onClick={(e): void => handleSearchBranchCustomer(e)}
                >
                  {customerBranchHits ?? 0}
                </button>
              </div>
              {!enabled && (
                <div className="absolute top-0 h-full w-full bg-litlingo-gray-0.5 bg-opacity-50" />
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="relative text-body">
            <button
              type="button"
              key={customer.uuid}
              className={`w-full flex flex-row justify-between items-center pr-4 py-2.5 border-b border-litlingo-gray-1 focus:outline-none ${
                selectedCustomer === customer.uuid
                  ? 'pl-10 font-bold'
                  : 'pl-10 hover:bg-litlingo-gray-1 hover:bg-opacity-50 transition ease-in-out duration-150'
              } ${
                selectedCustomer === customer.uuid || highligtCustomerScope === customer.uuid
                  ? 'bg-litlingo-success-light bg-opacity-50'
                  : ''
              }`}
              disabled={!enabled}
              onClick={handleToggleSelectCustomer}
            >
              <div>{customer.name}</div>
              <div className="flex flex-row items-center gap-4 text-litlingo-gray-6">
                {customerCategories.some((c) => c.outcome?.type === 'modal') ? (
                  'P'
                ) : (
                  <div className="w-2 border-t border-litlingo-gray-6" />
                )}
              </div>
            </button>
            {selectedCustomer === customer.uuid && (
              <OutcomesSection customer={customer} customerGroup={customerGroup} />
            )}
            {!enabled && (
              <div className="absolute top-0 h-full w-full bg-litlingo-gray-0.5 bg-opacity-50" />
            )}
          </div>
          {compareMode && selectedCustomer === customer.uuid && isProd && isStaging && (
            <div>
              <div className="pl-10 w-full flex pr-4 py-2.5 border-b border-t border-litlingo-gray-1 text-body bg-litlingo-alert-light bg-opacity-50">
                <span className="font-bold">{customer.name}_Prod</span>
              </div>
              {selectedCustomer === customer.uuid && (
                <OutcomesSection
                  customer={customer}
                  customerGroup={customerGroup === 'production' ? 'staging' : 'production'}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerItem;
