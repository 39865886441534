/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import React from 'react';

type ComponentProps = {
  selectedEntities: UUID[];
  toggleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EntityListActionRow: React.FC<ComponentProps> = ({ selectedEntities, toggleModalOpen }) => {
  const disableButtons = selectedEntities.length !== 2;

  return (
    <tr className="bg-litlingo-gray-2">
      <th className="table-wrapper__new-th table-wrapper__bulk-area table-wrapper__th--w-5 bg-litlingo-gray-2">
        {' '}
      </th>
      <th colSpan={4} className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2">
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
          <div className="flex flex-row justify-between items-start gap-2 h-auto overflow-visible">
            <button
              type="button"
              disabled={disableButtons}
              onClick={(): void => toggleModalOpen(true)}
              className="button button--primary h-8 w-24 flex justify-center"
            >
              Merge
            </button>
            {/* <button
              type="button"
              disabled
              onClick={(): void => toggleModalOpen(true)}
              className="button button--secondary h-8 flex justify-center"
            >
              <span className="font-bold">Ungroup</span>
            </button>
            <button
              type="button"
              disabled
              onClick={(): void => toggleModalOpen(true)}
              className="button button--secondary h-8 flex justify-center"
            >
              <span className="font-bold">Not relevant</span>
            </button> */}
          </div>
        </div>
      </th>
    </tr>
  );
};

export const MemoizedEntityListActionsRow = React.memo(EntityListActionRow);

export default EntityListActionRow;
