import {
  fetchAllModelsRequest,
  fetchModelCategoriesDiffRequest,
  fetchModelCategoriesRequest,
  fetchSingleModelRequest,
  promoteAllRequest,
  upsertModelRequest,
} from 'actions/models';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type {
  Category,
  MajorChange,
  MinorChange,
  MModel,
  NormalizedResource,
  RuleChange,
  Selector,
  UUID,
} from 'types';

export const getFetchAllModelsLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(fetchAllModelsRequest.toString());

export const getFetchSingleModelLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(fetchSingleModelRequest.toString());

export const getUpsertModelLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(upsertModelRequest.toString());

export const getFetchModelCategoriesLoading =
  (id: UUID): Selector<boolean> =>
  (state): boolean =>
    state.models.loading.includes(`${fetchModelCategoriesRequest.toString()}-${id}`);

export const getModel =
  (id: UUID): Selector<MModel> =>
  (state): MModel =>
    state.models.models[id];

export const getAllModels: Selector<NormalizedResource<MModel>> = (state) => state.models.models;

export const getModelCategories: Selector<Category[], [UUID]> = (state, id) => {
  const model = getModel(id)(state);
  if (!model || !model.categories || !model.categories.length) {
    return [];
  }

  return [...new Set(model.categories)]; // skip duplicates
};

export const getModelsList: Selector<MModel[]> = (state) => Object.values(state.models.models);

export const getActiveModel: Selector<UUID> = (state) => state.models.activeModel;

export const getModelsTotalCount: Selector<number> = (state) => state.models.count;

export const getPromoteAllLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(promoteAllRequest.toString());

export const getStateFilter: Selector<string> = (state) => state.models.stateFilter;

export const getExpandedCategories: Selector<string[]> = (state) => state.models.expandedCategories;

export const getModelDiffLoading: Selector<boolean> = (state) =>
  state.models.loading.includes(fetchModelCategoriesDiffRequest.toString());

export type ModelCategoriesChanges = {
  minor: Record<string, { ruleName: string; changes: MinorChange[] }>;
  major: Record<string, { ruleName: string; changes: MajorChange[] }>;
};

export const getModelCategoriesChanges: Selector<ModelCategoriesChanges, [UUID]> = createSelector(
  (state: GlobalState): Record<string, RuleChange[]> => state.models.ruleChanges,
  getModelCategories,
  (ruleChanges, categories) => {
    const changesSummary: ModelCategoriesChanges = {
      minor: {},
      major: {},
    };

    Object.entries(ruleChanges).forEach(([key, changes]) => {
      const rule = categories.find((c) => c.rule?.rule_group_uuid === key)?.rule;
      const ruleName = rule?.name || '';

      const majorChanges: MajorChange[] = [];
      const minorChanges: MinorChange[] = [];

      changes.forEach((change) => {
        if (change.kind === 'add') {
          if (change.path.includes('annotation_configs')) {
            majorChanges.push({ type: 'added_identifier' });
          } else if (change.path.includes('annotation_link_configs')) {
            majorChanges.push({ type: 'added_relationship' });
          } else if (typeof change.new !== 'string' && change.new.operator === 'ANNOTATION_MATCH') {
            majorChanges.push({ type: 'added_identifier' });
          } else {
            majorChanges.push({ type: 'added_branch' });
          }
        }

        if (change.kind === 'delete') {
          if (change.path.includes('annotation_link_configs')) {
            majorChanges.push({ type: 'removed_relationship' });
          } else if (change.path.includes('annotation_configs')) {
            majorChanges.push({ type: 'removed_identifier' });
          } else if (typeof change.old !== 'string' && change.old.operator === 'ANNOTATION_MATCH') {
            majorChanges.push({ type: 'removed_identifier' });
          } else {
            majorChanges.push({ type: 'removed_branch' });
          }
        }

        if (change.kind === 'change') {
          if (change.path.includes('annotation_link_configs')) {
            majorChanges.push({ type: 'changed_relationship' });
          } else if (change.path.includes('operator')) {
            majorChanges.push({ type: 'changed_logic' });
          }
        }
      });

      if (majorChanges.length === 0) {
        minorChanges.push({ type: 'changed_identifier', name: 'na' });
      }

      if (majorChanges.length > 0) {
        changesSummary.major[key] = { ruleName, changes: majorChanges };
      }
      if (minorChanges.length > 0) {
        changesSummary.minor[key] = { ruleName, changes: minorChanges };
      }
    });

    return changesSummary;
  }
);
