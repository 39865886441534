import { continueAssignment, fetchOwnAssignments } from 'actions';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignmentsLoading,
  getContinueAssignmentLoading,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import { getUser } from 'selectors/auth';
import logEvent from 'utils/analytics';

const ViewEvents: React.FC = () => {
  const dispatch = useDispatch();

  const singleAssigment = useSelector(getUsersSingleAssigment);
  const assignmentLoading = useSelector(getAssignmentsLoading);
  const continueAssignmentLoading = useSelector(getContinueAssignmentLoading);

  const user = useSelector(getUser);
  const isReviewer = user.roles.includes('reviewer');

  useEffect(() => {
    if (isReviewer) {
      dispatch(fetchOwnAssignments({ is_open: true }));
    }
  }, [dispatch, isReviewer]);

  if (assignmentLoading) {
    return (
      <div className="flex items-center w-full">
        <WidgetLoading size="8" />
      </div>
    );
  }

  if (singleAssigment == null || singleAssigment?.pending <= 0) {
    return (
      <Permissions action="events.list">
        <div className="flex items-center h-full w-full bg-litlingo-primary rounded-md box-shadow">
          <LinkLookup
            routeName="review-set"
            className="w-full h-full"
            onClick={(): Promise<void> => logEvent('Dashboard-start-new-assignment')}
          >
            <button type="button" className="w-full h-full focus:outline-none">
              <div className="text text--center text-lg text--white self-center">
                View Assignments
              </div>
            </button>
          </LinkLookup>
        </div>
      </Permissions>
    );
  }

  const handleFinishAssignment = (): void => {
    logEvent('Dashboard-finish-assignment');
    dispatch(continueAssignment({ uuid: singleAssigment.uuid }));
  };

  return (
    <Permissions action="events.list">
      <div className="flex items-center h-full w-full bg-litlingo-primary rounded-md box-shadow p-5">
        <button
          type="button"
          className="w-full focus:outline-none"
          onClick={handleFinishAssignment}
        >
          <div className="flex h-full items-center justify-between">
            <div className="text text--left text-l text--bold text--white">
              {`${singleAssigment?.pending} left to review`}
            </div>
            <div className="flex flex-row items-center">
              <div className="text text--center text-l text--white self-center">Finish</div>
              {continueAssignmentLoading && <LoadingIndicator size="5" />}
            </div>
          </div>
        </button>
      </div>
    </Permissions>
  );
};

export default withWidget({
  // minWidth: 5,
  // minHeight: 3,
  bgClassColor: 'transparent',
  containerClassName: 'rounded-md box-shadow p-0',
})(ViewEvents);
