import CategoriesByRuleTable from 'components/CategoriesTable';
import CreateCategoryModal from 'components/Category/CreateCategoryModal';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getFetchModelCategoriesLoading, getModelCategories } from 'selectors/models';
import { useSelector } from 'store';
import type { MModel, UUID } from 'types';

type RouteParams = {
  modelId: UUID;
};

type ComponentProps = {
  item: MModel;
};

const RenderCategories: React.FC<ComponentProps> = ({ item }) => {
  const { modelId } = useParams<RouteParams>();

  const categories = useSelector((state) => getModelCategories(state, modelId));
  const loading = useSelector(getFetchModelCategoriesLoading(modelId));

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  const toggleCreateCategoryModal = (): void => {
    setIsCreatingCategory(!isCreatingCategory);
  };

  const skeletonLoading = (): JSX.Element => (
    <div className="px-12 py-5">
      <Skeleton count={5} />
    </div>
  );

  const renderContent = (): JSX.Element => {
    if (categories?.length === 0 && !loading) {
      return (
        <div className="flex flex-col justify-center items-center gap-4 py-20">
          <span className="text-body">
            Use cases make up the conditions the model will monitor and match against
          </span>
          <button
            onClick={(e): void => {
              e.stopPropagation();
              setIsCreatingCategory(true);
            }}
            type="button"
            data-testid="create-rule-form"
            className="button button--primary h-8"
          >
            Create Model
          </button>
        </div>
      );
    }

    return (
      <div className="border rounded">
        {categories.length === 0 && loading ? (
          skeletonLoading()
        ) : (
          <CategoriesByRuleTable categories={categories} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="">{renderContent()}</div>

      {isCreatingCategory && (
        <CreateCategoryModal
          modelId={item.uuid}
          toggleModal={toggleCreateCategoryModal}
          redirect="global-rule-group-manager"
        />
      )}
    </>
  );
};

export default RenderCategories;
