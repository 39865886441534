import { clearURLParams, setURLParams } from 'actions/nav';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getPrompts } from 'selectors/entities';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
};

const PromptsFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filter = useSelector(getNavParamsByResource(resource)).prompt_uuid as string;
  const selectedPrompts = filter ? [{ label: '', value: filter }] : [];

  const prompts = useSelector(getPrompts);

  const optionsArray = prompts
    .filter((p) => p.kind === 'entity')
    ?.map((prompt) => ({
      // @ts-ignore
      label: prompt?.name,
      value: prompt.uuid,
    }));

  const handleChange = (option: MultiSelectOption): void => {
    if (filter === option.value) {
      dispatch(clearURLParams());
    } else {
      dispatch(
        setURLParams({
          [`${resource}__prompt_uuid`]: option.value,
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <div className="w-40">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Prompts"
        onChange={handleChange}
        options={optionsArray}
        selectedItems={selectedPrompts}
        dataTestid="active"
      />
    </div>
  );
};

export default PromptsFilter;
