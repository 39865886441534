import {
  removeExcludedInsight,
  removeExcludedPrevent,
  removeExcludedRoles,
  removeExcludedTeams,
  removeIncludedInsight,
  removeIncludedPrevent,
  removeIncludedRoles,
  removeIncludedTeams,
} from 'actions/userPermissionsPage';
import { MultiSelectOption } from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import Labels from 'components/UsersPermissions/UserPermissionsForm/Labels';
import useAllTeams from 'hooks/teams/useAllTeams';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import {
  getExcludedInsight,
  getExcludedPrevent,
  getExcludedRoles,
  getExcludedTeams,
  getIncludedInsight,
  getIncludedPrevent,
  getIncludedRoles,
  getIncludedTeams,
} from 'selectors/userPermissionsPage';
import 'styles/labelsStyle.css';

const PermissionsLabels: React.FC = () => {
  const dispatch = useDispatch();

  const { data } = useAllTeams();
  const user = useSelector(getUser);
  const isSuperAdmin = user.roles.includes('super-admin');

  const includedTeams = useSelector(getIncludedTeams);
  const excludedTeams = useSelector(getExcludedTeams);

  const includedRoles = useSelector(getIncludedRoles);
  const excludedRoles = useSelector(getExcludedRoles);

  const includedInsight = useSelector(getIncludedInsight);
  const excludedInsight = useSelector(getExcludedInsight);

  const includedPrevent = useSelector(getIncludedPrevent);
  const excludedPrevent = useSelector(getExcludedPrevent);

  const handleDeleteTeam = (option: MultiSelectOption): void => {
    const team = data?.records.find((t) => t.uuid === option.value);

    if (team) {
      dispatch(removeIncludedTeams([team]));
      dispatch(removeExcludedTeams([team]));
    }
  };

  const handleDeleteRole = (option: MultiSelectOption): void => {
    dispatch(removeIncludedRoles([option.value]));
    dispatch(removeExcludedRoles([option.value]));
  };

  const handleDeleteInsight = (option: MultiSelectOption): void => {
    dispatch(removeIncludedInsight([option.value]));
    dispatch(removeExcludedInsight([option.value]));
  };

  const handleDeletePrevent = (option: MultiSelectOption): void => {
    dispatch(removeIncludedPrevent([option.value]));
    dispatch(removeExcludedPrevent([option.value]));
  };

  return (
    <div className="flex flex-col gap-2">
      {(includedRoles.length > 0 || excludedRoles.length > 0) && (
        <div className="flex flex-col gap-1">
          <span className="font-bold">Role</span>
          <div className="pl-3">
            <Labels
              includedLabels={includedRoles.map((r) => ({ value: r, label: r }))}
              excludedLabels={excludedRoles.map((r) => ({ value: r, label: r })) || []}
              handleDelete={handleDeleteRole}
            />
          </div>
        </div>
      )}
      {(includedTeams.length > 0 || excludedTeams.length > 0) && (
        <div className="flex flex-col gap-1">
          <span className="font-bold">Division</span>
          <div className="pl-3">
            <Labels
              includedLabels={includedTeams.map((t) => ({ value: t.uuid, label: t.name }))}
              excludedLabels={excludedTeams.map((t) => ({ value: t.uuid, label: t.name })) || []}
              handleDelete={handleDeleteTeam}
            />
          </div>
        </div>
      )}
      {(includedInsight.length > 0 || excludedInsight.length > 0) && (
        <div className="flex flex-col gap-1">
          <span className="font-bold">Insight Integrations</span>
          <div className="pl-3">
            <Labels
              includedLabels={includedInsight.map((i) => ({ value: i, label: i }))}
              excludedLabels={excludedInsight.map((i) => ({ value: i, label: i })) || []}
              handleDelete={handleDeleteInsight}
              disabled={!isSuperAdmin}
            />
          </div>
        </div>
      )}
      {(includedPrevent.length > 0 || excludedPrevent.length > 0) && (
        <div className="flex flex-col gap-1">
          <span className="font-bold">Prevent Integrations</span>
          <div className="pl-3">
            <Labels
              includedLabels={includedPrevent.map((p) => ({ value: p, label: p }))}
              excludedLabels={excludedPrevent.map((p) => ({ value: p, label: p })) || []}
              handleDelete={handleDeletePrevent}
              disabled={!isSuperAdmin}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PermissionsLabels;
