import { setDropPosition } from 'actions/identifier';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import type { MRuleConfig, RuleRowDataType } from 'types';
import RowItem from './RowItem';
import { ItemTypes, operatorName } from './RuleManagerUtils';

type ComponentProps = {
  Component: typeof RowItem;
  data: RuleRowDataType;
  arrayTree: MRuleConfig[];
  handleMove: (fromId: string, targetIndex: number) => Promise<void>;
  idToMove: string | null;
  showTransition: boolean;
  handleCollapse: (collapseId: string) => void;
  handleUncollapse: (uncollapseId: string) => void;
  collapsedFolder: string[];
  collapsedItems: string[];
  disabled?: boolean;
};

const MakeDraggable: React.FC<ComponentProps> = ({
  Component,
  data,
  arrayTree,
  handleMove,
  idToMove,
  showTransition,
  handleCollapse,
  handleUncollapse,
  collapsedFolder,
  collapsedItems,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const { index, name, id, level } = data;
  let type: keyof typeof ItemTypes = 'ANNOTATOR';
  let accepts: string[] = [];

  if (operatorName[name as keyof typeof operatorName]) {
    type = 'OPERATOR';
    accepts = [ItemTypes.OPERATOR, ItemTypes.ANNOTATOR];
  }

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accepts,
    drop: () => ({ name, index, id, level, handleUncollapse, collapsedFolder }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes[type], name, index },
    canDrag: !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const fromIndex = item.index;
        const toIndex = dropResult.index;
        if (fromIndex !== toIndex) {
          const fromId = arrayTree[fromIndex].id;
          handleMove(fromId, toIndex);
        }

        if (collapsedFolder.includes(dropResult.id)) {
          handleUncollapse(dropResult.id);
        }
      }
      dispatch(setDropPosition(-1));
    },
  });

  return (
    <Component
      drag={drag}
      drop={drop}
      preview={preview}
      isDragging={isDragging}
      active={isOver && canDrop}
      data={data}
      collapsedItems={collapsedItems}
      collapsedFolder={collapsedFolder}
      idToMove={idToMove}
      handleCollapse={handleCollapse}
      handleUncollapse={handleUncollapse}
      showTransition={showTransition}
    />
  );
};

export default MakeDraggable;
