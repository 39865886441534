import { setURLParams } from 'actions';
import { SEARCH_ICON } from 'constants/filterIcons';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import logEvent from 'utils/analytics';

type ComponentProps = {
  field?: string;
  placeholder?: string;
  resource?: string;
  className?: string;
  logEventMessage?: string;
  handleSearch?: (value: string) => void;
  debounceDelay?: number;
};

const SearchInput: React.FC<ComponentProps> = (props) => {
  const {
    field,
    placeholder,
    resource,
    className,
    logEventMessage,
    handleSearch,
    debounceDelay = 500,
  } = props;

  const dispatch = useDispatch();
  const value = useSelector(getNavParamsByResource(resource || ''))[field || 'search'] || '';
  const [body, setBody] = useState<string>(value as string);

  useEffect(() => {
    setBody(value as string);
  }, [value]);

  const handleSearchChange = (eventValue: string): void => {
    if (logEventMessage) logEvent(`${logEventMessage}-${eventValue}`);

    if (handleSearch) {
      handleSearch(eventValue);
      return;
    }

    dispatch(
      setURLParams({
        [`${resource}__offset`]: '0',
        [`${resource}__${field || 'search'}`]: eventValue,
      })
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce((eventValue) => {
      handleSearchChange(eventValue);
    }, debounceDelay),
    []
  );

  return (
    <div className={`relative w-104 ${className}`}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
      </div>
      <input
        id="search"
        name="search"
        className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-4"
        placeholder={placeholder || 'Search'}
        onChange={(event): void => {
          debouncedChange(event.target.value);
          setBody(event.target.value);
        }}
        value={body}
        autoComplete="off"
        data-testid="search-input"
      />
    </div>
  );
};

export default SearchInput;
