import { UtilizationType } from 'selectors/config';

const itemRowColors = {
  Default: 'bg-litlingo-gray-1',
  Red: 'bg-red-0',
  Green: 'bg-green-0',
  Blue: 'bg-blue-0',
  Yellow: 'bg-yellow-0',
  Purple: 'bg-purple-0',
};

export const bgColorsUtilization: Record<UtilizationType, string> = {
  none: 'bg-litlingo-gray-1',
  one: 'bg-litlingo-gray-1',
  'less-than-half': 'bg-litlingo-gray-1',
  'more-than-half': 'bg-litlingo-gray-1',
  all: 'bg-white',
};

export const textUtilization: Record<UtilizationType, string> = {
  none: '0',
  one: '1*',
  'less-than-half': '≤50%',
  'more-than-half': '≥50%',
  all: 'All',
};

export default itemRowColors;
