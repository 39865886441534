/* eslint-disable max-lines */
import { clearURLParams, exportUsers } from 'actions';
import TrueUsersFilter from 'components/Filters/TrueUsersFilter';
import UserDivisionFilter from 'components/Filters/UserDivisionsFilter';
import UserInactiveFilter from 'components/Filters/UserInactiveFilter';
import UserIntegrationFilter from 'components/Filters/UserIntegrationFilter';
import UserRoleFilter from 'components/Filters/UserRolesFilter';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddUserModal from './AddUserModal';

const UsersListHeader: React.FC = () => {
  const dispatch = useDispatch();

  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);

  const handleExportUsers = (): void => {
    dispatch(exportUsers);
  };

  const handleClearFilters = (): void => {
    dispatch(clearURLParams());
  };

  return (
    <header className="flex flex-col gap-2">
      <div className="flex flex-row justify-end gap-2">
        <button
          onClick={handleExportUsers}
          type="button"
          className="h-8 button button--secondary focus:outline-none"
          key="export"
        >
          <span className="font-bold whitespace-no-wrap">Download CSV</span>
        </button>
        <Permissions action="customers.list">
          <button
            type="button"
            className="w-30 h-8 flex flex-row justify-center button button--primary focus:outline-none whitespace-no-wrap"
            key="add"
            onClick={(): void => {
              setIsCreateNewUserOpen(true);
            }}
          >
            Add New
          </button>
        </Permissions>
      </div>
      <div className="w-full flex flex-row justify-between">
        <h2 className="title self-end w-22 flex-shrink-0">Users</h2>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2 flex-wrap">
          <SearchInput
            resource={resourceQueryParamName.user}
            field="broad_search"
            className="h-8 w-80 "
            placeholder="Search users by name or email"
          />
          <Permissions action="users.trueUsers">
            <TrueUsersFilter resource={resourceQueryParamName.user} />
          </Permissions>
          <UserRoleFilter resource={resourceQueryParamName.user} />
          <div className="w-35">
            <UserDivisionFilter resource={resourceQueryParamName.user} />
          </div>
          <UserIntegrationFilter resource={resourceQueryParamName.user} />
          <UserInactiveFilter resource={resourceQueryParamName.user} />
          <button
            type="button"
            className="text-body self-end underline focus:outline-none"
            onClick={handleClearFilters}
          >
            Clear All
          </button>
        </div>
      </div>

      {isCreateNewUserOpen && <AddUserModal setIsCreateNewUserOpen={setIsCreateNewUserOpen} />}
    </header>
  );
};

export default UsersListHeader;
