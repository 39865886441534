/* eslint-disable camelcase */
/* eslint-disable max-lines */
import {
  clearTree,
  requestTreeFiltersToogle,
  selectItem,
  showErrorAlert,
  validateAndMove,
} from 'actions';
import MakeDraggable from 'components/RuleManagerTable/MakeDraggable';
import RowItem from 'components/RuleManagerTable/RowItem';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getActiveTriggeredNodes, getTriggeredNodes } from 'selectors/communications';
import { getSelectedNode } from 'selectors/config';
import {
  getConfigRuleAsArray,
  getRuleAnnotatorPosition,
  getSelectedAnnotators,
} from 'selectors/rule';
import { getShowRuleHighlights } from 'selectors/rules';
import type { MRuleConfig } from 'types';
import isSameSentenceApplicable from 'utils/configValidation';
import RuleManagerAction from './RuleManagerAction';
import { operatorName } from './RuleManagerUtils';

type ComponentProps = {
  idToMove: string | null;
  setIdToMove: React.Dispatch<React.SetStateAction<string | null>>;
  toggleShowModal: () => void;
  collapseLevel: number;
  setLevelIsModified: React.Dispatch<React.SetStateAction<boolean>>;
  setCollapseLevel: React.Dispatch<React.SetStateAction<number>>;
  ruleId: string;
};

const RuleManagerTable: React.FC<ComponentProps> = ({
  idToMove,
  setIdToMove,
  toggleShowModal,
  collapseLevel,
  setLevelIsModified,
  setCollapseLevel,
  ruleId,
}) => {
  const dispatch = useDispatch();
  const arrayTree = useSelector(getConfigRuleAsArray);
  const prevUncollapsedAnnotators = useRef<string[]>([]);

  const selectedNode = useSelector(getSelectedNode);
  const [showTransition, setShowTransition] = useState(false);
  const [collapsedFolder, setCollapsedFolder] = useState<string[]>([]);

  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  const [isCollapsedAll, setIsCollapsedAll] = useState<boolean>();
  const [firstCollapse, setFirstCollapse] = useState(false);
  const [isDefaultView, setIsDefaultView] = useState(false);

  const selectedAnnotators = useSelector(getSelectedAnnotators);
  const triggeredNodes = useSelector(getTriggeredNodes);
  const showHighlights = useSelector(getShowRuleHighlights);
  const triggeredNodesToUncollapse = useSelector(getActiveTriggeredNodes);
  const annotatorPosition = useSelector(getRuleAnnotatorPosition);
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(selectItem({ index: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (collapseLevel === -1) {
      return;
    }

    const collapseItems: string[] = [];
    const collapseFolders: string[] = [];

    arrayTree.forEach((node) => {
      const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

      if (node.level > collapseLevel) {
        collapseItems.push(node.id);

        if (isFolder) {
          collapseFolders.push(node.id);
        }
      } else if (node.level === collapseLevel) {
        if (isFolder) {
          collapseFolders.push(node.id);
        }
      }
    });

    setCollapsedItems(collapseItems);
    setCollapsedFolder(collapseFolders);
    setIsDefaultView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseLevel]);

  useEffect(() => {
    const isLevelModified = arrayTree.some(
      (node) =>
        (node.level > collapseLevel &&
          !collapsedFolder.includes(node.id) &&
          !collapsedItems.includes(node.id)) ||
        (node.level < collapseLevel &&
          (collapsedFolder.includes(node.id) || collapsedItems.includes(node.id)))
    );
    if (isLevelModified) {
      setLevelIsModified(true);
      setCollapseLevel(-1);
    } else {
      setLevelIsModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedItems.length, collapsedFolder.length]);

  useEffect(() => {
    if (annotatorPosition) {
      const element = document.getElementById(annotatorPosition);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [annotatorPosition, collapsedItems.length, collapsedFolder.length, collapseLevel]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id } = event.target;
    const nodeIndex = parseInt(id.split('-')[1], 10);
    const clickOnSameNode = nodeIndex === selectedNode;
    setShowTransition(false);
    if (clickOnSameNode) {
      dispatch(selectItem({ index: null }));
    } else {
      dispatch(selectItem({ index: nodeIndex }));
      setIsCollapsedAll(collapsedFolder.includes(arrayTree[nodeIndex].id));
    }
  };

  const handleCollapse = (id: string, isRoot = true): void => {
    const node = arrayTree.find((n) => n.id === id);
    const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

    if (isRoot) {
      if (!collapsedFolder.includes(id)) {
        setCollapsedFolder((prev) => [...prev, id]);
      }
    } else if (isFolder) {
      if (!collapsedFolder.includes(id)) {
        setCollapsedFolder((prev) => [...prev, id]);
      }
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id), id]);
    } else {
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id), id]);
    }

    const groups = node?.nodeGroups;

    if (groups) {
      groups.forEach((child) => {
        handleCollapse(child, false);
      });
    }
  };

  const handleUncollapse = (
    id: string,
    isCollapsed?: boolean,
    isRoot = true,
    shouldUncollapse = true,
    defaultToUncollapse = 5
  ): void => {
    const node = arrayTree.find((n) => n.id === id);
    const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

    if (isRoot) {
      setCollapsedFolder((prev) => [...prev.filter((item) => item !== id)]);
    } else {
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id)]);
    }

    const groups = node?.nodeGroups;

    if (isRoot) {
      if (groups) {
        groups.forEach((child) => {
          handleUncollapse(
            child,
            true,
            false,
            isCollapsed ? true : groups.length < defaultToUncollapse
          );
        });
      }
    } else if (groups && shouldUncollapse) {
      if (isFolder) {
        setCollapsedFolder((prev) => [...prev.filter((item) => item !== id)]);
      }
      groups.forEach((child) => {
        handleUncollapse(
          child,
          true,
          false,
          isCollapsed ? true : groups.length < defaultToUncollapse
        );
      });
    }
  };

  useEffect(() => {
    if (triggeredNodes.length && showHighlights && isDefaultView) {
      triggeredNodesToUncollapse.forEach((id) => handleUncollapse(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultView]);

  const handleChangeAnnotators = (nodeUUIDS: string[]): void => {
    let elements: string[] = [];
    const folders: string[] = [];
    nodeUUIDS.forEach((c) => {
      const current = arrayTree.find((n) => n.id === c);
      if (current) {
        elements.push(current.id);
        elements = [...elements, ...(current.nodeGroups ?? [])];
        folders.push(current.id);
      }
    });
    setCollapsedItems((prev) => prev.filter((item) => !elements.includes(item)));
    setCollapsedFolder((prev) => prev.filter((item) => !folders.includes(item)));
    prevUncollapsedAnnotators.current = [...prevUncollapsedAnnotators.current, ...elements];
  };

  useEffect(() => {
    prevUncollapsedAnnotators.current = [];
    selectedAnnotators.forEach((annotatorId) => {
      const nodes = arrayTree.filter((n) => n.annotatorId === annotatorId);
      nodes.forEach((node) => {
        if (node) {
          const nodeRoot = arrayTree.find((n) => n.level === 0)?.id;
          const parentNodes = node.parent.filter((np) => np !== nodeRoot);
          handleChangeAnnotators(parentNodes);
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotators.length]);

  const handleMove = async (fromId: string, targetIndex: number): Promise<void> => {
    const newParentId = arrayTree && arrayTree[targetIndex].id;
    const res = await dispatch(validateAndMove({ idToMove: fromId, newParentId }));
    // @ts-ignore
    if (res) {
      setIdToMove(null);
      setShowTransition(true);
    } else {
      setShowTransition(false);
      dispatch(showErrorAlert('Invalid move!'));
    }
  };

  const handlePrintComments = (): void => {
    let commentString = '';
    arrayTree.forEach((i) => {
      const space = '  ';
      if (i.description) {
        commentString += `${space.repeat(i.level)}${i.description}\n`;
      }
    });
    // eslint-disable-next-line no-console
    console.log(commentString);
  };

  const handleSearchByRuleBranchUUID = (): void => {
    if (selectedNode === null) return;
    const node = arrayTree[selectedNode as number];

    if (node.nodeGroups) {
      const ruleBranchUUID = `${ruleId}_${node.id}`;
      dispatch(clearTree());
      dispatch(requestTreeFiltersToogle({ value: true }));
      const url = new URLSearchParams();
      url.append('envelopes__rule_branch_uuid', ruleBranchUUID);
      url.append('envelopes__filters_search', `~rule_branch_uuid{${ruleBranchUUID}}`);
      window.open(`/${user.customer?.domain}/communication-envelopes?${url.toString()}`, '_blank');
    }
  };

  const renderRowActionItem = (rowInd: number, node: MRuleConfig): JSX.Element => {
    const { name, id, parent } = node;
    const selectedItem = arrayTree[selectedNode as number]
      ? arrayTree[selectedNode as number]
      : false;
    const emptyButton = (
      <button type="button" className="pr-10 mr-3">
        {'\u00A0'}
      </button>
    );
    if (idToMove === id) return emptyButton;
    let actionItem = (
      <input
        id={`node-${rowInd}`}
        name="check"
        type="checkbox"
        checked={selectedNode === rowInd}
        onChange={(event): void => handleSelect(event)}
        data-testid={`checkbox-${rowInd}`}
        className="form-checkbox litlingo-checkbox mr-4 h-4 w-4 transition duration-150 ease-in-out"
      />
    );
    // if in move mode show PUT button
    if (idToMove) {
      // only show put button if it's an and/or/same sentence
      // && the item to move isn't a parent descendent of current node
      // && if the selected item is a valid item to be moved to same sentence
      if (
        selectedItem !== false &&
        // @ts-ignore
        operatorName[name] &&
        !parent.includes(selectedItem.id) &&
        isSameSentenceApplicable(selectedItem, node)
      ) {
        actionItem = (
          <button
            type="button"
            className="button button--secondary font-bold px-2 h-6 mr-2 rounded"
            onClick={(): Promise<void> => handleMove(idToMove, rowInd)}
          >
            PUT
          </button>
        );
      } else {
        actionItem = emptyButton;
      }
    }
    return actionItem;
  };

  const renderRows = arrayTree.map((node, rowInd) => {
    const {
      name,
      description,
      id,
      level,
      relationship,
      modifiers,
      annotatorId,
      deleted,
      negated,
      mode_of_speech,
      parent,
      color,
    } = node;
    const actionItem = renderRowActionItem(rowInd, node);
    const data = {
      id,
      index: rowInd,
      actionItem,
      level,
      name,
      description,
      relationship,
      modifiers,
      deleted,
      annotatorId,
      negated,
      mode_of_speech,
      parent,
      color,
    };

    return (
      <MakeDraggable
        key={id}
        Component={RowItem}
        data={data}
        arrayTree={arrayTree}
        handleMove={handleMove}
        idToMove={idToMove}
        showTransition={showTransition}
        handleCollapse={handleCollapse}
        handleUncollapse={handleUncollapse}
        collapsedFolder={collapsedFolder}
        collapsedItems={collapsedItems}
      />
    );
  });

  if (arrayTree[0] && !firstCollapse) {
    setFirstCollapse(true);
    handleCollapse(arrayTree[0].id);
    handleUncollapse(arrayTree[0].id);
  }

  return (
    <div className="flex flex-col gap-2">
      {arrayTree && selectedNode != null && arrayTree[selectedNode] && (
        <RuleManagerAction
          setIdToMove={setIdToMove}
          selectedId={arrayTree[selectedNode].id}
          selectedNode={selectedNode}
          idToMove={idToMove}
          arrayTree={arrayTree}
          toggleShowModal={toggleShowModal}
          handleCollapse={handleCollapse}
          handleUncollapse={handleUncollapse}
          isCollapsedAll={isCollapsedAll}
          setIsCollapsedAll={setIsCollapsedAll}
          handleNavigateRuleBranchUUID={handleSearchByRuleBranchUUID}
        />
      )}
      <div
        data-testid="rule-manager-table"
        className={`min-w-full w-full px-6 ${
          arrayTree && selectedNode != null && arrayTree[selectedNode] ? '' : 'pt-4'
        }`}
      >
        <div>{arrayTree && renderRows}</div>
      </div>
      <div className="px-6 pb-2 flex flex-row justify-end">
        <button
          type="button"
          className="button button--secondary font-bold h-8 whitespace-no-wrap"
          onClick={handlePrintComments}
        >
          Print Comments
        </button>
      </div>
    </div>
  );
};

export default RuleManagerTable;
