import { fetchEntities, fetchPrompts, fetchSinglePrompt } from 'actions';
import EntityList from 'components/EntityList';
import Prompt from 'components/Prompt';
import PromptList from 'components/PromptList';
import { RouteSpec, SuperRouteSpec } from 'types';

const entitiesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'entities',
    path: '/:customerDomain/entities',
    component: EntityList,
    private: true,
    data: {
      actions: [fetchEntities, fetchPrompts],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        entities: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            name: {},
            order_by: {},
            order_desc: {},
            prompt_uuid: {},
            team_uuids: { list: true },
          },
        },
      },
    },
  },
  {
    name: 'prompt-detail',
    path: '/:customerDomain/prompts/:promptId',
    component: Prompt,
    private: true,
    data: {
      idFromPath: true,
      actions: [fetchSinglePrompt],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
      },
    },
  },
  {
    name: 'prompts',
    path: '/:customerDomain/prompts',
    component: PromptList,
    private: true,
    data: {
      actions: [fetchPrompts],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        prompts: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            order_by: {},
            order_desc: {},
          },
        },
      },
    },
  },
];

export default entitiesRoutes;
