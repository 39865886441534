import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageMatcherTypes } from 'selectors/identifier';
import type { LanguageMatcher, LanguageMatchersTypes, MLanguageMatcher } from 'types';
import AddLanguageMatcherInput from './AddLanguageMatcherInput';

type ChooseCombinationTypeProps = {
  activeLanguageMatcher: MLanguageMatcher;
  excludedFields?: string[];
};

const ChooseCombinationType: React.FC<ChooseCombinationTypeProps> = (props) => {
  const combinations = useSelector(getLanguageMatcherTypes);
  const { activeLanguageMatcher: languageMatcher, excludedFields = [] } = props;

  return (
    <div className="grid gap-y-4 gap-x-4 grid-cols-2">
      {languageMatcher.type &&
        combinations[languageMatcher.type as keyof typeof combinations] &&
        Object.keys(combinations[languageMatcher.type as keyof typeof combinations])
          .filter((key) => !excludedFields.includes(key))
          .map((key) => (
            <AddLanguageMatcherInput
              key={key}
              form={key as keyof LanguageMatcher}
              languageMatcherId={languageMatcher?.uuid}
              type={languageMatcher.type as keyof LanguageMatchersTypes}
            />
          ))}
    </div>
  );
};

export default ChooseCombinationType;
