import { addAnnotationMatcherToRule } from 'actions/ruleGroup';
import LinkLookup from 'components/LinkLookup';
import { previewImage } from 'components/RuleManagerTable/RuleManagerUtils';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import { PLUS_ICON } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';
import {
  DragElementWrapper,
  DragPreviewImage,
  DragPreviewOptions,
  DragSourceOptions,
} from 'react-dnd';
import { useDispatch } from 'react-redux';
import { getCompareMode } from 'selectors/config';
import { useSelector } from 'store';
import type { MRuleRevision, NormalizedIdentifier } from 'types';
import TermsSerchMatch from '../TermsSerchMatch';

type ComponentProps = {
  identifier: NormalizedIdentifier;
  rule: MRuleRevision;
  drag: DragElementWrapper<DragSourceOptions>;
  preview: DragElementWrapper<DragPreviewOptions>;
  searchValue: string;
  idx: number;
};

const RenderIdentifier: React.FC<ComponentProps> = ({
  identifier,
  rule,
  drag,
  preview,
  searchValue,
  idx,
}) => {
  const dispatch = useDispatch();
  const { name: identifierName, uuid } = identifier;

  const [image, setImage] = useState('');
  const [showRules, setShowRules] = useState(false);

  const compareMode = useSelector(getCompareMode);

  useEffect(() => {
    setImage(previewImage(identifier.name).src);
  }, [identifier]);

  const handleAddIdentifier = (): void => {
    // @ts-expect-error starship
    dispatch(addAnnotationMatcherToRule({ rule, annotator: identifier }));
  };

  return (
    <>
      <DragPreviewImage connect={preview} src={image} />
      <div
        ref={drag}
        className={`flex flex-row w-full py-2 ${
          idx !== 0 ? 'border-t border-litlingo-gray-2' : ''
        }`}
        data-testid="sidebar-identifier"
      >
        <div className="w-full self-center">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-start gap-2">
              <button
                data-testid="add-identifier-button"
                onClick={(): void => handleAddIdentifier()}
                type="button"
                className="pt-0.5 w-3 inline-flex text-litlingo-primary focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                disabled={compareMode}
              >
                {PLUS_ICON()}
              </button>
              <div className="text-body">
                <span className="text-lg">
                  <b>{identifierName}</b>
                  {identifier.heads?.[0].name === 'staging' ? ' (Staging)' : ''}
                </span>
              </div>
            </div>
            <LinkLookup
              routeName="global-identifier-manager"
              routeParams={{ identifierId: identifier.identifier_uuid }}
              type="button"
              className="ml-4  w-3 inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            >
              {EXPAND_ICON}
            </LinkLookup>
          </div>
          <div className=" text-litlingo-gray-5 text-xss">
            <div className="pl-5">
              <TermsSerchMatch identifierId={uuid} searchValue={searchValue} />
              <div className="mt-1">{`Uses: ${identifier.rule_identifiers?.length ?? 0}`}</div>
            </div>
            {showRules && (
              <ul className="list-disc pl-10">
                {identifier.rule_identifiers?.map((r) => (
                  <li key={r.uuid}>{`${r.name}`}</li>
                ))}
              </ul>
            )}
            {!!identifier.rule_identifiers?.length && (
              <div className="flex flex-row justify-end ">
                <button
                  type="button"
                  className=" underline text-litlingo-primary focus:outline-none"
                  onClick={(): void => setShowRules(!showRules)}
                >
                  {showRules ? 'Show Less' : 'Show More'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderIdentifier;
