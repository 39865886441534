import { setURLParams } from 'actions/nav';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import useTeams from 'hooks/teams/useTeams';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
};

const DivisionsFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filter = (useSelector(getNavParamsByResource(resource)).team_uuids || []) as string[];
  const selectedTeams = filter ? filter.map((f) => ({ label: '', value: f })) : [];

  const { data } = useTeams({ redirectOnError: false });
  const options = data?.records || [];

  const optionsArray = options.map((team) => ({
    label: team?.name,
    value: team.uuid,
  }));

  const handleChange = (option: MultiSelectOption): void => {
    if (filter.includes(option.value)) {
      dispatch(
        setURLParams({
          [`${resource}__team_uuids`]: filter.filter((v) => v !== option.value),
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__team_uuids`]: [...filter, option.value],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <div className="w-40">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Divisions"
        onChange={handleChange}
        options={optionsArray}
        selectedItems={selectedTeams}
        dataTestid="active"
      />
    </div>
  );
};

export default DivisionsFilter;
