import { addTagsToTagGroup, removeTagsFromTagGroup } from 'actions';
import LinkLookup from 'components/LinkLookup';
import CreateTagGroupModal from 'components/TagGroups/CreateTagGroupModal';
import CreateTagModal from 'components/TagsManager/CreateTagModal';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerTagGroupsIds } from 'selectors/auth';
import { getSelectedTagGroup } from 'selectors/tagGroup';
import { Tag } from 'types';
import TagsSelect from './TagsSelect';

const TagGroupHeader: React.VFC = () => {
  const dispatch = useDispatch();
  const tagGroup = useSelector(getSelectedTagGroup);
  const customerTagGroupsIds = useSelector(getCustomerTagGroupsIds);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);

  const toggleModalOpen = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleTagModalOpen = (): void => {
    setIsTagModalOpen(!isTagModalOpen);
  };

  const handleSelectTag = (tag: Tag, checked: boolean): void => {
    if (checked) {
      dispatch(
        addTagsToTagGroup({
          uuid: tagGroup?.uuid || '',
          tags: [tag.uuid],
        })
      );
    } else {
      dispatch(
        removeTagsFromTagGroup({
          uuid: tagGroup?.uuid || '',
          tags: [tag.uuid],
        })
      );
    }
  };

  if (!tagGroup) return null;

  return (
    <header className="px-6 flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 justify-between">
          <div className="flex flex-row gap-2">
            <LinkLookup
              routeName="tags"
              className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
            >
              All Tags
            </LinkLookup>
            <div
              role="button"
              tabIndex={0}
              className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
            >
              Tag Groups
            </div>
          </div>
          <div className="h-8 flex flex-row gap-2">
            <button
              type="button"
              className="button button--secondary font-bold w-37 justify-center"
              onClick={toggleModalOpen}
            >
              Edit
            </button>
            <button
              type="button"
              className="button button--primary w-37 justify-center"
              onClick={toggleTagModalOpen}
            >
              New Tag
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <LinkLookup routeName="tag-groups">
            <p className="text-litlingo-info underline">{'<'} Back to All Tag Groups</p>
          </LinkLookup>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2">
          <h2 className="text-heading-2">{tagGroup?.name}</h2>
          {/* @ts-ignore */}
          <span>{`${tagGroup.tenant_name === 'modeler' ? '(Internal)' : '(External)'} ${
            customerTagGroupsIds.includes(tagGroup.uuid) ? 'On coding panel' : ''
          }`}</span>
        </div>
        <div className="flex flex-row justify-between items-end">
          <TagsSelect
            selectTag={handleSelectTag}
            selectedTags={tagGroup.tag_values}
            className="min-w-58"
          />
          <span>
            {tagGroup.tag_values.length} {pluralize('tag', tagGroup.tag_values.length)}
          </span>
        </div>
      </div>
      {isModalOpen && (
        <CreateTagGroupModal
          tagGroup={tagGroup}
          isModalOpen={isModalOpen}
          toggleModalOpen={toggleModalOpen}
        />
      )}
      {isTagModalOpen && (
        <CreateTagModal
          tag={null}
          tagGroup={tagGroup}
          isModalOpen={isTagModalOpen}
          toggleModalOpen={toggleTagModalOpen}
        />
      )}
    </header>
  );
};

export default TagGroupHeader;
